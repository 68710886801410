import React, { useEffect, useState } from 'react';
import { SelectCustomer, SelectCustomerOption } from '../../forms/SelectCustomer';
import { SelectBuildingSite, SelectBuildingSiteOption } from '../../forms/SelectBuildingSite';
import { useForm } from 'react-hook-form';
import './GeneralQuotation.scss';
import axios from 'axios';
import * as yup from 'yup';
import Config from '../../../Config';
import { yupResolver } from '@hookform/resolvers/yup';
import { Body } from '../../../stories/dune/atoms/Body';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { ParagraphInput } from '../../../stories/dune/atoms/ParagraphInput';
import { Button } from '../../../stories/dune/atoms/Button';
import { Title } from '../../../stories/dune/atoms/Title';
import moment from 'moment';
import { Input } from '../../../stories/dune/atoms/Input';
import { ProductItems } from '../../forms/ProductItems';
import { useNavigate } from 'react-router-dom';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/use-toast';
import { BuildingSite, Contact, Customer } from '@dune-manager/backend-core/dist/models';
import { productItemsActions, ProductItemRow } from '../../../store/productItems-slice';
import { duplicationActions } from '../../../store/duplication-slice';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../store';
import { StaticDataState } from '../../../store/staticData-slice';
import { VatRate } from '../../../models/VatRate';
import {
  GetQuotation,
  GetQuotationProductProduct,
  QuotationProductSave,
  QuotationProgressStatus,
  QuotationSave,
  QuotationStatus,
} from '../../../models/Quotation';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { ErrorLine } from '../../../stories/dune/atoms/ErrorLine';
import stringUtils from '../../../utils/stringUtils';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import { GetAdditionalDoc } from '../../../models/AdditionalDoc';
import fileDownload from 'js-file-download';
import { SelectContact, SelectContactOption } from '../../forms/SelectContact';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import { ProductTaxes, TransportMode, TransportProductType } from '../../../models/Product';
import { SelectCarrier, SelectCarrierOption } from '../../forms/SelectCarrier';
import { CustomerTypeProductPrice } from '../../../models/CustomerType';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { t } from 'i18next';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { fetchCustomerTypeProductPrices } from '../../../services/customerTypeProductPrice';
import { changeQuotationStatus, convertQuotationToOrder, generateQuotationPdf } from '../../../services/quotation';
import { fetchOrdersByQuotationId } from '../../../services/order';

interface GeneralQuotationProps {
  id?: string;
  quotationData: GetQuotation | undefined;

  onCancel: () => void;
  onConfirm: (data: any) => void;
  onReload?: () => void;

  mode: string;

  quotationMode?: 'customer' | 'buildingSite';
  quotationType: 'Quotation' | 'Contract';
}

const GeneralQuotation = React.memo((props: GeneralQuotationProps) => {
  // #region intializing constants

  // #region generic
  const dispatch = useDispatch();
  const staticData = useSelector((state: { staticData: StaticDataState }) => state.staticData);

  const history = useNavigate();

  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  const [isLoading, setIsLoading] = useState(false);

  const [formMode, setFormMode] = useState<'' | 'customer' | 'buildingSite'>('');
  const [formType, setFormType] = useState<'' | 'Quotation' | 'Contract'>('');

  const isEditable =
    props.mode === 'add' ||
    [
      QuotationStatus.CREATED,
      QuotationStatus.UPDATED,
      QuotationStatus.GENERATED,
      QuotationStatus.SENT,
      QuotationStatus.IN_ERROR,
    ].findIndex((x) => x === props.quotationData?.status) !== -1;

  // #endregion generic

  // #region specific

  const [saveQuotation, setSaveQuotation] = useState<QuotationSave>();

  const [customerId, setCustomerId] = useState('');
  const [customerOption, setCustomerOption] = useState<SelectCustomerOption | null>(
    props.quotationData?.customerId
      ? {
          value: props.quotationData.customerId ?? '',
          label: props.quotationData.customer.label ?? '',
          data: props.quotationData.customer,
        }
      : null,
  );
  const [buildingSiteId, setBuildingSiteId] = useState<string>();
  const [buildingSiteOption, setBuildingSiteOption] = useState<SelectBuildingSiteOption | null>(null);

  const [customerContactId, setCustomerContactId] = useState<string>();
  const [customerContactOption, setCustomerContactOption] = useState<SelectContactOption | null>(null);
  const [buildingSiteContactId, setBuildingSiteContactId] = useState<string>();
  const [buildingSiteContactOption, setBuildingSiteContactOption] = useState<SelectContactOption | null>(null);

  const [carrierOption, setCarrierOption] = useState<SelectCarrierOption | null>(null);

  const [comment, setComment] = useState<string>();
  const [subject, setSubject] = useState<string>();

  const [dateStart, setDateStart] = useState<Date>(new Date());
  const [dateEnd, setDateEnd] = useState<Date>();
  const [expiredAt, setExpiredAt] = useState<Date>(moment(new Date()).add(1, 'months').toDate());

  const [customerOrderNumber, setCustomerOrderNumber] = useState<string>();

  const [customerTypeProductPrices, setCustomerTypeProductPrices] = useState<CustomerTypeProductPrice[]>([]);

  const [showFillCustomerOrderNumber, setShowFillCustomerOrderNumber] = useState(false);
  const [showConfirmRefuseQuotation, setShowConfirmRefuseQuotation] = useState(false);
  const [showGeneratingQuotation, setShowGeneratingQuotation] = useState(false);

  const [productError, setProductError] = useState<string>();
  const [validationWarning, setValidationWarning] = useState<string>();
  const [warningAccepted, setWarningAccepted] = useState<boolean>(false);

  const [onConfirmOverride, setOnConfirmOverride] = useState<(() => void) | undefined>(undefined);

  const schema = yup.object().shape({
    client: yup.object().shape({
      value: yup.string().nullable().required('Le client est obligatoire'),
      label: yup.string().nullable().required('Le client est obligatoire'),
    }),
    dateStart: yup.date().default(new Date()).required('La date de début est obligatoire'),
    contactCustomer: yup.object().optional().nullable(),
    contactBuildingSite: yup.object().optional().nullable(),
  });

  interface IFormInputs {
    status: {
      value: string;
      label: string;
    };
    client: {
      value: string | null;
      label: string | null;
    };
    chantier: {
      value: string | null;
      label: string | null;
    };
    products: {
      value: string;
      label: string;
    };
    tranportSites: {
      value: string;
      label: string;
    };
    carrier: {
      value: string;
      label: string;
    };
    contact: {
      value: string;
      label: string;
    };
    dateStart: Date;
    dateEnd: Date | null;
    expiredAt: Date | null;
    quotationComment: string;
    quotationSubject: string;
    contactCustomer: string;
    contactBuildingSite: string;
  }

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  // #endregion specific

  // #endregion intializing constants

  // #region event handling

  useEffect(() => {
    setIsLoading(true);
    dispatch(productItemsActions.reset());
    if (props.quotationMode && formMode !== props.quotationMode) updateFormMode(props.quotationMode);
    if (props.quotationType && formType !== props.quotationType) setFormType(props.quotationType);

    if (props.quotationData) {
      if (props.quotationType !== (props.quotationData.quotationType ?? 'Quotation')) {
        addToast(t('common.wrongDataTypeError'), ToastTypes.error);
        history('/' + props.quotationType + 's');
      }

      // on initialise SaveQuotation pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveQuotation({
        quotationType: props.quotationData.quotationType ?? props.quotationType,
        status: props.quotationData.status as QuotationStatus,
        customerId: props.quotationData.customerId,
        dateStart: props.quotationData.dateStart ?? new Date(),
        dateEnd: props.quotationData.dateEnd,
        buildingSiteId: props.quotationData.buildingSiteId,
        externalReference: props.quotationData.externalReference,
        quotationComment: props.quotationData.quotationComment,
        quotationSubject: props.quotationData.quotationSubject,
        carriersId: props.quotationData.carriers ? props.quotationData.carriers.map((x) => x.id ?? '') : undefined,
        dataCustom: props.quotationData.dataCustom,
        customerContactId: props.quotationData.customerContact?.id,
        buildingSiteContactId: props.quotationData.buildingSiteContact?.id,
        products: props.quotationData.products.map((x: any) => {
          return {
            productId: x.id,
            vatRateId: x.QuotationProduct.vatRateId,
            quotedQuantity: x.QuotationProduct.quotedQuantity,
            genericPrice: x.QuotationProduct.genericPrice,
            mainProduct: x.QuotationProduct.mainProduct,
            externalReference: x.QuotationProduct.externalReference,
            comment: x.QuotationProduct.comment,
            quotedPrice: x.QuotationProduct.quotedPrice,
            discount: x.QuotationProduct.discount,
            transportMode: x.QuotationProduct.transportMode,
            transportPrices: x.QuotationProduct.quotationProductTransportPrices
              ? x.QuotationProduct.quotationProductTransportPrices.map((y: any) => {
                  return {
                    vatRateId: y.vatRateId,
                    price: y.price,
                    buildingSiteId: y.buildingSiteId,
                  };
                })
              : [],
          };
        }),
      });

      const oCustomerOption = {
        value: props.quotationData.customer.id ?? '',
        label: props.quotationData.customer.label ?? '',
        data: props.quotationData.customer as Customer,
      };
      setCustomerOption(oCustomerOption);
      setValue('client', { value: oCustomerOption.value, label: oCustomerOption.label });
      loadCustomerTypeProductPrices(oCustomerOption);

      if (props.quotationData.buildingSite) {
        updateFormMode('buildingSite');
        setBuildingSiteOption({
          value: props.quotationData.buildingSite.id ?? '',
          label: props.quotationData.buildingSite.label ?? '',
          searchParentRef: props.quotationData.customer.id,
          data: props.quotationData.buildingSite as BuildingSite,
        });
      }

      if (props.quotationData.buildingSiteContact) {
        setBuildingSiteContactOption({
          value: props.quotationData.buildingSiteContact.id ?? '',
          label:
            props.quotationData.buildingSiteContact.firstname + ' ' + props.quotationData.buildingSiteContact.lastname,
          searchParentRef: props.quotationData.buildingSite.id,
          data: props.quotationData.buildingSiteContact as Contact,
        });
      }

      if (props.quotationData.customerContact) {
        setCustomerContactOption({
          value: props.quotationData.customerContact.id ?? '',
          label: props.quotationData.customerContact.firstname + ' ' + props.quotationData.customerContact.lastname,
          searchParentRef: props.quotationData.customer.id,
          data: props.quotationData.customerContact as Contact,
        });
      }

      if (props.quotationData.carriers && props.quotationData.carriers.length > 0) {
        setCarrierOption({
          value: props.quotationData.carriers[0].id ?? '',
          label: props.quotationData.carriers[0].label ?? '',
          data: props.quotationData.carriers[0],
        });
      }

      setComment(props.quotationData.quotationComment);
      setSubject(props.quotationData.quotationSubject);

      setDateStart(props.quotationData.dateStart ?? new Date());
      setDateEnd(props.quotationData.dateEnd);
      setExpiredAt(props.quotationData.expiredAt ?? expiredAt);

      const products: ProductItemRow[] = [];

      const vatRates: VatRate[] = staticData.vatRates;

      props.quotationData.products?.map((x: any) => {
        const isTransportService = TransportProductType === x.productTypeId || x.productType === 'Service Transport';

        products.push({
          id: x.QuotationProduct.id,
          productId: x.id,
          label: x.label,
          desc: x.externalReference,
          isService: isTransportService,
          isTransport: isTransportService,
          quantityIsSum: false,
          externalReference: x.QuotationProduct.externalReference,
          unit: x.unit,
          weightByUnit: x.weightByUnit,
          orderedQuantity: x.QuotationProduct.quotedQuantity,
          deliveredQuantity: undefined,
          baseUnitPrice: x.QuotationProduct.genericPrice, // en modif, on garde le genericPrice stocké lors de la création
          itemUnitPrice: x.QuotationProduct.quotedPrice,
          discountPercent: x.QuotationProduct.discount,
          vatRate: vatRates.length > 0 ? vatRates.find((y) => y.id === x.QuotationProduct.vatRateId)?.vatRate : 0.2,
          vatRateId: x.QuotationProduct.vatRateId,
          repValue:
            props.quotationData?.status &&
            QuotationProgressStatus.IN_PROGRESS.indexOf(props.quotationData?.status as QuotationStatus) > -1
              ? props.quotationData.customer?.isExemptFromRepTax || props.quotationData.buildingSite?.isExemptFromRepTax
                ? 0
                : x.repValue
              : x.QuotationProduct.repValue ?? 0,
          tgapValue:
            props.quotationData?.status &&
            QuotationProgressStatus.IN_PROGRESS.indexOf(props.quotationData?.status as QuotationStatus) > -1
              ? x.isSubjectToTgap
                ? ProductTaxes.TGAP
                : 0
              : x.QuotationProduct.tgapValue,
          mainProduct: false,
          enableTransportPrice:
            x.QuotationProduct.quotationProductTransportPrices &&
            x.QuotationProduct.quotationProductTransportPrices.length > 0,
          showTransportDetails: false,
          transportMode: x.QuotationProduct.transportMode,
        });
      });

      dispatch(productItemsActions.setProducts(products));
    }
    setIsLoading(false);
  }, [props.quotationData]);

  useEffect(() => {
    if (onConfirmOverride) {
      onSubmit();
    }
  }, [onConfirmOverride]);

  useEffect(() => {
    if (warningAccepted) {
      onSubmit();
    }
  }, [warningAccepted]);

  const onSubmit = () => {
    let baseUrl = orgid + '/quotation/add';
    if (props.mode == 'update') baseUrl = orgid + '/quotation/edit/' + props.id;

    const productItems = store.getState().productItems;

    // #region validation

    let bOk = true;

    // products
    if (productItems.products.findIndex((x) => x.itemUnitPrice === undefined) !== -1) {
      bOk = false;
      setProductError(t('common.allProductsMustBePriced'));
    } else {
      setProductError(undefined);
    }
    if (productItems.products.findIndex((x) => x.itemUnitPrice === 0) !== -1) {
      if (!warningAccepted) {
        let warningText = '';
        productItems.products
          .filter((x) => x.itemUnitPrice === 0)
          ?.map((x) => {
            warningText += t('common.priceZeroWarning').replace('¤', x.label) + '\n';
          });
        warningText += t('common.continueAnyway') + ' ?';
        bOk = false;
        setValidationWarning(warningText);
      }
    } else {
      setProductError(undefined);
    }

    // #endregion validation

    // #region saving

    if (bOk) {
      // construct Objet product from tripProducts to send
      const sentProducts: QuotationProductSave[] = [];

      productItems.products?.map((product: ProductItemRow) => {
        sentProducts.push({
          productId: product.productId,
          vatRateId: (product.vatRateId ?? 0).toString(),
          quotedQuantity: product.orderedQuantity ?? 0,
          genericPrice: product.baseUnitPrice ?? 0,
          mainProduct: false,
          discount: product.discountPercent ?? 0,
          quotedPrice: product.itemUnitPrice ?? 0,
          repValue: product.repValue ?? 0,
          tgapValue: product.tgapValue ?? 0,
          comment:
            saveQuotation &&
            saveQuotation.products &&
            saveQuotation.products.filter((x) => x.productId === product.productId).length > 0
              ? saveQuotation.products.filter((x) => x.productId === product.productId)[0].comment
              : undefined,
          externalReference:
            saveQuotation &&
            saveQuotation.products &&
            saveQuotation.products.filter((x) => x.productId === product.productId).length > 0
              ? saveQuotation.products.filter((x) => x.productId === product.productId)[0].externalReference
              : undefined,
          transportMode: product.transportMode,
          transportPrices: [],
        });
      });

      let saveStatus = saveQuotation?.status ?? QuotationStatus.CREATED;
      if (saveStatus === QuotationStatus.GENERATED || saveStatus === QuotationStatus.SENT)
        saveStatus = QuotationStatus.UPDATED;

      const dataToSend: QuotationSave = {
        ...saveQuotation,
        quotationType: props.quotationType,
        status: saveStatus,
        products: sentProducts,
        customerId: customerId,
        buildingSiteId: buildingSiteId,
        customerContactId: customerContactId,
        buildingSiteContactId: buildingSiteContactId,
        dateStart: dateStart,
        dateEnd: dateEnd,
        quotationComment: comment,
        quotationSubject: subject,
        expiredAt: expiredAt,
      };

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          addToast(t('common.quotation' + (props.mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
          if (!onConfirmOverride) props.onConfirm(res.data.content.uuid);
          else onConfirmOverride();
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }

    // #endregion saving
  };

  async function loadCustomerTypeProductPrices(newOption: SelectCustomerOption | null = null, bRefresh = false) {
    if (!newOption) return;

    const customerTypeId = newOption.data?.customerTypeId ?? newOption.data?.customerType?.id;

    if (!customerTypeId) {
      setCustomerTypeProductPrices([]);
      dispatch(productItemsActions.setCustomerTypeProductPrices({ prices: [], bRefresh: bRefresh }));
      return;
    }

    if (!token || !orgid) {
      return;
    }

    try {
      const prices = await fetchCustomerTypeProductPrices({
        accessToken: token,
        orgid: orgid,
        customerTypeId,
      });

      setCustomerTypeProductPrices(prices);
      dispatch(productItemsActions.setCustomerTypeProductPrices({ prices, bRefresh }));
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  }

  async function convertToOrder(customerOrderNumber: string) {
    try {
      if (!token || !orgid) {
        return;
      }

      const { orderId } = await convertQuotationToOrder({
        accessToken: token,
        orgid,
        quotationId: props.id || '',
        customerOrderNumber,
      });

      addToast(t('common.orderCreated'), ToastTypes.success);
      history(`/orders/${orderId}/update`);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  }

  async function updateToRefused() {
    try {
      if (!token || !orgid) {
        return;
      }

      const { uuid: quotationUuid } = await changeQuotationStatus({
        accessToken: token,
        orgid,
        quotationId: props.id || '',
        status: QuotationStatus.REFUSED,
      });
      addToast(t('common.quotationRefused'), ToastTypes.success);
      props.onConfirm(quotationUuid);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  }

  async function updateToValidated() {
    try {
      if (!token || !orgid) {
        return;
      }

      await changeQuotationStatus({
        accessToken: token,
        orgid,
        quotationId: props.id || '',
        status: QuotationStatus.VALIDATED,
      });
      addToast(t('common.quotationValidated'), ToastTypes.success);
      props.onReload?.();
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  }

  async function generatePDF() {
    try {
      if (!token || !orgid) {
        return;
      }

      await generateQuotationPdf({
        accessToken: token,
        orgid,
        quotationId: props.id || '',
      });
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  }

  async function generateQuotation() {
    await generatePDF();
    setShowGeneratingQuotation(true);
  }

  function handleGenerateQuotation() {
    setOnConfirmOverride(() => generateQuotation); // triggers useEffect, which calls onSubmit, which calls generateQuotation as onConfirmOverride to generate the PDF
  }

  async function handleDownloadQuotation() {
    let bGenerate = false;
    if (
      props.quotationData?.additionalDocs &&
      props.quotationData?.additionalDocs.filter((x) => x.additionalDocType.label === 'quotation')
    ) {
      const oDoc: GetAdditionalDoc = props.quotationData?.additionalDocs.filter(
        (x) => x.additionalDocType.label === 'quotation',
      )[0];

      const dateDoc = new Date(oDoc.createdAt);
      const dateUpdate = new Date(props.quotationData.lastModificationAt);

      // On vérifie que la date de création du fichier est proche de celle de mise à jour
      if (dateDoc >= new Date(dateUpdate.getTime() - 1 * 60000)) {
        const data = await axios({
          url: oDoc.url, // your url
          method: 'GET',
          responseType: 'blob', // important
        });

        fileDownload(data.data, oDoc.label);
        return data;
      } else {
        bGenerate = true;
      }
    } else {
      bGenerate = true;
    }

    if (bGenerate) {
      // Si on ne trouve pas de fichier ou qu'il est trop ancien, on demande de re-générer.
      handleGenerateQuotation();
    }
  }
  function handleValidateQuotation() {
    if (formType === 'Contract') setShowFillCustomerOrderNumber(true);
    else updateToValidated();
  }
  function handleRefuseQuotation() {
    setShowConfirmRefuseQuotation(true);
  }
  function handleDuplicateAsQuotation() {
    if (props.quotationData) {
      const dataToDuplicate = props.quotationData;
      dataToDuplicate.status = QuotationStatus.CREATED;
      dataToDuplicate.quotationComment =
        '\n' +
        t('common.duplicatedFrom') +
        ' ' +
        t('common.' + formType.toLowerCase()).toLowerCase() +
        ' n°' +
        props.quotationData?.quotationNumber;
      dataToDuplicate.quotationType = 'Quotation';
      dispatch(duplicationActions.addQuotationToDuplicate(dataToDuplicate));
      history('/quotations/add/' + (formMode === 'buildingSite' ? 'buildingSite' : 'customer'));
    }
  }
  function handleDuplicateAsContract() {
    if (props.quotationData) {
      const dataToDuplicate = props.quotationData;
      dataToDuplicate.status = QuotationStatus.CREATED;
      dataToDuplicate.quotationComment =
        '\n' +
        t('common.duplicatedFrom') +
        ' ' +
        t('common.' + formType.toLowerCase()).toLowerCase() +
        ' n°' +
        props.quotationData?.quotationNumber;
      dataToDuplicate.quotationType = 'Contract';
      if (formMode !== 'buildingSite') {
        const oProds = dataToDuplicate.products.map((x: GetQuotationProductProduct) => {
          return {
            ...x,
            QuotationProduct: {
              ...x.QuotationProduct,
              transportMode: TransportMode.EXWORKS,
            },
          };
        }) as [GetQuotationProductProduct];
        dataToDuplicate.products = oProds;
      }

      dispatch(duplicationActions.addQuotationToDuplicate(dataToDuplicate));
      history(
        '/contracts/add/' +
          (formMode === 'buildingSite'
            ? 'buildingSite/' + props.quotationData.buildingSiteId
            : 'customer/' + props.quotationData.customerId),
      );
    }
  }
  function handleDuplicateAsOrder() {
    if (props.quotationData) {
      const dataToDuplicate = props.quotationData;
      dispatch(duplicationActions.duplicateQuotationAsOrder(dataToDuplicate));
      history(
        '/orders/add/' + (formMode === 'buildingSite' ? 'buildingSite/' + buildingSiteId : 'customer/' + customerId),
      );
    }
  }

  async function handleGoToOrder() {
    try {
      if (!token || !orgid) {
        return;
      }
      const orders = await fetchOrdersByQuotationId({
        accessToken: token,
        orgid,
        quotationId: props.quotationData?.id || '',
      });

      const orderId = orders[0].id;
      history(`/orders/${orderId}/update`);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  }

  function updateFormMode(newState?: string) {
    setFormMode((prevState) => {
      if (newState === undefined) newState = prevState === 'buildingSite' ? 'customer' : 'buildingSite';
      return newState === 'buildingSite' ? 'buildingSite' : 'customer';
    });
  }

  function updateBuildingSite(newValue: any) {
    setBuildingSiteId(newValue?.value);
    if (!newValue?.value) setBuildingSiteContactOption(null);
    if (newValue?.data && customerId !== newValue.data.customerId && newValue.data.customer) {
      setCustomerId(newValue.data.customerId);
      setCustomerOption({
        value: newValue.data.customerId,
        label: newValue.data.customer.label,
        data: newValue.data.customer,
      });
      setSaveQuotation((prevValue) => {
        if (prevValue) return { ...prevValue, buildingSiteId: newValue.value, customerId: newValue.customerId };
      });
    } else {
      setSaveQuotation((prevValue) => {
        if (prevValue) return { ...prevValue, buildingSiteId: newValue?.value };
      });
    }
  }

  let selectPopup = <></>;
  if (showFillCustomerOrderNumber) {
    selectPopup = (
      <Input
        error={''}
        label={t('common.fillCustomerOrderRefForOrder')}
        type='text'
        value={customerOrderNumber ?? ''}
        placeholder=''
        isPopup={true}
        onClosePopup={(selectedOption: any) => {
          if (selectedOption !== null) {
            convertToOrder(selectedOption);
          }
          setShowFillCustomerOrderNumber(false);
        }}
        onChange={function (newValue: string | number): void {
          setCustomerOrderNumber(newValue.toString());
        }}
        popupConfirmLabel={t('common.validate')}
        popupConfirmIcon='valid'
      />
    );
  }
  if (showConfirmRefuseQuotation) {
    selectPopup = (
      <PopupContainer
        message={t('common.confirmRefuseQuotation')}
        onCancel={function (): void {
          setShowConfirmRefuseQuotation(false);
        }}
        onConfirm={function (): void {
          setShowConfirmRefuseQuotation(false);
          updateToRefused();
        }}
        confirmLabel={t('common.refuse')}
        confirmIcon='close'
        cancelLabel={t('common.return')}
      />
    );
  }
  if (showGeneratingQuotation && props.quotationData) {
    selectPopup = (
      <PopupContainer
        message={t('common.generatingPDF')}
        onCancel={function (): void {
          setShowGeneratingQuotation(false);
        }}
        onConfirm={function (): void {
          if (props.onReload) props.onReload();
        }}
        confirmLabel={t('common.reload')}
        confirmIcon='refresh'
        cancelLabel={t('common.return')}
      />
    );
  }
  if (validationWarning) {
    selectPopup = (
      <PopupContainer
        message={validationWarning}
        onCancel={function (): void {
          setValidationWarning(undefined);
        }}
        onConfirm={function (): void {
          setValidationWarning(undefined);
          setWarningAccepted(true);
        }}
        confirmLabel={t('common.save')}
        confirmIcon='save'
        cancelLabel={t('common.return')}
      />
    );
  }

  // #region productTransportPrices

  // #endregion event handling

  return (
    <>
      <Body>
        <div className='detail-content'>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <form className='form'>
              <div className='form-section'>
                <FormRow>
                  <SelectCustomer
                    register={register}
                    registerName='client'
                    setValue={setValue}
                    error={errors.client?.value?.message ?? errors.client?.label?.message ?? ''}
                    isSelectable={isEditable && props.mode !== 'update'}
                    selectedOptionChanged={(newValue) => {
                      setCustomerId(newValue?.value);
                      setSaveQuotation((prevValue) => {
                        if (prevValue) return { ...prevValue, customerId: newValue?.value };
                      });
                      if (props.mode !== 'update') {
                        loadCustomerTypeProductPrices(newValue, true);
                      }
                    }}
                    forceSelectedOption={customerOption}
                  />

                  {formMode === 'buildingSite' && (
                    <SelectBuildingSite
                      titleOverride={t('common.jobSite')}
                      hoverDescription={t('common.buildingSite')}
                      register={register}
                      registerName='chantier'
                      setValue={setValue}
                      error={errors.chantier?.value?.message ?? errors.chantier?.label?.message ?? ''}
                      isSelectable={isEditable}
                      selectedOptionChanged={updateBuildingSite}
                      searchParent={customerId}
                      forceSelectedOption={buildingSiteOption}
                    />
                  )}
                </FormRow>
              </div>

              <div className='form-section'>
                <FormRow>
                  <SelectContact
                    entityType={ContactEntity.CUSTOMER}
                    searchParent={customerId}
                    register={register}
                    registerName='contactCustomer'
                    setValue={setValue}
                    error={errors.contactCustomer?.message ?? ''}
                    isSelectable={isEditable}
                    selectedOptionChanged={(newValue: any) => {
                      setCustomerContactId(newValue?.value);
                      setSaveQuotation((prevValue) => {
                        if (prevValue) return { ...prevValue, customerContactId: newValue?.value };
                      });
                    }}
                    dependsOnSearchParent={false}
                    forceSelectedOption={customerContactOption}
                  />

                  {buildingSiteId && (
                    <SelectContact
                      titleOverride={t('common.contactBuildingSite')}
                      entityType={ContactEntity.BUILDING_SITE}
                      searchParent={buildingSiteId}
                      register={register}
                      registerName='contactBuildingSite'
                      setValue={setValue}
                      error={errors.contactBuildingSite?.message ?? ''}
                      isSelectable={isEditable}
                      selectedOptionChanged={(newValue: any) => {
                        setBuildingSiteContactId(newValue?.value);
                        setSaveQuotation((prevValue) => {
                          if (prevValue) return { ...prevValue, buildingSiteContactId: newValue?.value };
                        });
                      }}
                      dependsOnSearchParent={false}
                      forceSelectedOption={buildingSiteContactOption}
                    />
                  )}
                </FormRow>
              </div>
              <div className='form-section'>
                <FormRow>
                  <div className='panelInput'>
                    <Input
                      error={errors.quotationSubject?.message ?? ''}
                      label={t('common.subject')}
                      hoverDescription={t('common.quotationSubjectDesc')}
                      type='text'
                      {...register('quotationSubject')}
                      value={subject}
                      placeholder='Offre de prix'
                      onChange={(newValue: string | number) => setSubject(newValue as string)}
                      disabled={!isEditable}
                    />
                  </div>
                </FormRow>
                {formType !== 'Contract' && (
                  <FormRow>
                    <div className='panelInput'>
                      <Input
                        error={errors.expiredAt?.message ?? ''}
                        label={t('common.quotationExpirationDate')}
                        hoverDescription={t('common.quotationExpirationDateDesc')}
                        type='date'
                        {...register('expiredAt')}
                        value={moment(expiredAt)?.toISOString()?.substring(0, 10)}
                        placeholder='DD/MM/YY HH:mm'
                        onChange={(newValue: string | number) => {
                          setExpiredAt(new Date(newValue as string));
                        }}
                        disabled={!isEditable}
                      />
                    </div>
                  </FormRow>
                )}
              </div>
              <div className='form-section'>
                <FormRow>
                  <Title label={t('common.products')} type='title1' />
                </FormRow>
                {productError && <ErrorLine label={productError} />}
                <ProductItems
                  label={t('common.products')}
                  canAdd={isEditable}
                  canRemove={isEditable}
                  isLoading={isLoading}
                  showTransportMode={true}
                  canEditTransportMode={isEditable}
                  showOrderedQuantity={formMode !== 'buildingSite' ? false : true}
                  canEditOrderedQuantity={isEditable}
                  showUnitPrice={true}
                  canEditUnitPrice={isEditable}
                  showDiscountValue={true}
                  canEditDiscountValue={true}
                  showProductPrice={formMode !== 'buildingSite' ? false : true}
                  showRowExVatPrice={formMode !== 'buildingSite' ? false : true}
                  showRowInclVatPrice={true}
                  showVatRate={true}
                  canEditVatRate={isEditable}
                  showTotal={formMode !== 'buildingSite' ? false : true}
                  showTotalDetails={formMode !== 'buildingSite' ? false : true}
                />
              </div>
              <div className='form-section'>
                <FormRow>
                  <SelectCarrier
                    titleSize='normal'
                    register={register}
                    registerName='carrier'
                    setValue={setValue}
                    error={errors.carrier?.value?.message ?? errors.carrier?.label?.message ?? ''}
                    isSelectable={true}
                    selectedOptionChanged={(e: SelectCarrierOption) => {
                      if (e && e.value) {
                        setValue('carrier', {
                          value: e?.value,
                          label: e?.label,
                        });

                        setSaveQuotation((prevValue) => {
                          if (prevValue) return { ...prevValue, carriersId: [e.value] };
                        });
                      } else {
                        setValue('carrier', {
                          value: '',
                          label: '',
                        });

                        setSaveQuotation((prevValue) => {
                          if (prevValue) return { ...prevValue, carriersId: undefined };
                        });
                      }
                    }}
                    forceSelectedOption={carrierOption}
                  />
                </FormRow>
              </div>
              <div
                className='form-section'
                title={formType === 'Quotation' ? t('common.priceGuaranteePeriodDesc') : t('common.contractPeriodDesc')}
              >
                <Title
                  label={formType === 'Quotation' ? t('common.priceGuaranteePeriod') : t('common.contractPeriod')}
                  type='title1'
                />
                <FormRow>
                  <div className='panelInput'>
                    <Input
                      error={errors.dateStart?.message ?? ''}
                      label={t('common.start')}
                      type='date'
                      {...register('dateStart')}
                      value={moment(dateStart)?.toISOString()?.substring(0, 10)}
                      placeholder='DD/MM/YY HH:mm'
                      onChange={(newValue: string | number) => setDateStart(new Date(newValue as string))}
                    />
                  </div>
                  <div className='panelInput'>
                    <Input
                      error={errors.dateEnd?.message ?? ''}
                      label={t('common.end')}
                      type='date'
                      {...register('dateEnd')}
                      value={dateEnd !== undefined ? moment(dateEnd)?.toISOString()?.substring(0, 10) : ''}
                      placeholder='DD/MM/YY HH:mm'
                      onChange={(newValue: string | number) => setDateEnd(new Date(newValue as string))}
                    />
                  </div>
                </FormRow>
              </div>
              <div className='form-section'>
                <FormRow>
                  <ParagraphInput
                    onChange={(newValue: string) => setComment(newValue)}
                    error={errors.quotationComment?.message ?? ''}
                    value={comment ?? ''}
                    label={t('common.comment')}
                  />
                </FormRow>
              </div>
              {!isLoading && (
                <div style={{ marginTop: '75px' }}>
                  <FixBar>
                    <Button label={t('common.return')} style='white' onClick={props.onCancel} />
                    {isEditable && (
                      <>
                        {props.mode == 'update' && (
                          <>
                            {props.quotationData?.status !== QuotationStatus.GENERATED &&
                              props.quotationData?.status !== QuotationStatus.SENT && (
                                <Button
                                  iconLeft='download'
                                  label={t('common.generatePDF')}
                                  style='primary-02'
                                  onClick={handleGenerateQuotation}
                                />
                              )}
                            {(props.quotationData?.status === QuotationStatus.GENERATED ||
                              props.quotationData?.status === QuotationStatus.SENT) && (
                              <Button
                                iconLeft='download'
                                label={t('common.export')}
                                style='primary-02'
                                onClick={handleDownloadQuotation}
                              />
                            )}
                          </>
                        )}
                        <Button
                          iconLeft='save'
                          label={t('common.save')}
                          style='primary'
                          onClick={handleSubmit(onSubmit)}
                        />
                        {props.mode === 'update' && (
                          <>
                            {formType === 'Quotation' && (
                              <Button
                                iconLeft='close'
                                label={t('common.refuse')}
                                style='primary-04'
                                onClick={handleRefuseQuotation}
                              />
                            )}
                            <Button
                              iconLeft='valid'
                              label={t('common.validate')}
                              style='primary-03'
                              onClick={handleValidateQuotation}
                            />
                          </>
                        )}
                      </>
                    )}
                    {props.mode == 'update' && (
                      <>
                        {props.quotationData?.status === QuotationStatus.VALIDATED && (
                          <>
                            {formType === 'Contract' && (
                              <Button
                                iconLeft='eye'
                                label={t('common.seeOrder')}
                                style='primary-03'
                                onClick={handleGoToOrder}
                              />
                            )}
                          </>
                        )}
                        <Button
                          iconLeft='add'
                          label={t('common.' + (formType === 'Quotation' ? 'duplicate' : 'generate') + 'Quotation')}
                          style='primary-02'
                          onClick={handleDuplicateAsQuotation}
                        />
                        <Button
                          iconLeft='add'
                          label={t('common.' + (formType === 'Quotation' ? 'generate' : 'duplicate') + 'Contract')}
                          style='primary-02'
                          onClick={handleDuplicateAsContract}
                        />
                        <Button
                          iconLeft='add'
                          label={t('common.generateOrder')}
                          style='primary-02'
                          onClick={handleDuplicateAsOrder}
                        />
                      </>
                    )}
                  </FixBar>
                </div>
              )}
            </form>
          )}
        </div>
        {selectPopup}
      </Body>
    </>
  );
});

GeneralQuotation.displayName = 'GeneralQuotation';
export default GeneralQuotation;
