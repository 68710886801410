import { AxiosError } from 'axios';
import { t } from 'i18next';
import { NavigateOptions, To } from 'react-router-dom';
import { ToastTypes } from '../models/ToastTypes';

const ERROR_CODES = ['ERR4010001', 'ERR4031001', 'ERR4010000'];

const generateToastMessage = (error: AxiosError): string => {
  if (error.response?.data?.code) {
    const errorCode = error.response.data.code;
    return `${errorCode}: ${t('errors.' + errorCode)}`;
  }

  const status = error?.response?.status;
  return `${t('common.genericErrorMessage')}${status ? ` (${status})` : ''}`;
};

export const handleApiError = ({
  error,
  addToast,
  history,
}: {
  error: AxiosError;
  addToast: (message: string, type: ToastTypes) => void;
  history: (to: To, options?: NavigateOptions) => void;
}): void => {
  if (ERROR_CODES?.includes(error.response?.data.code)) {
    history('/');
  }

  addToast(generateToastMessage(error), ToastTypes.error);
};
