import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import { CarrierSave, GetCarrier } from '../../../models/Carrier';
import { Title } from '../../../stories/dune/atoms/Title';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { SelectCountry, SelectCountryOption } from '../../forms/SelectCountry';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/use-toast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import Config from '../../../Config';
import countryList from '../../Countries';
import Switch from 'react-switch';
import { ContactSave } from '../../../models/Contact';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import isEmail from 'validator/lib/isEmail';
import stringUtils from '../../../utils/stringUtils';

interface GeneralCarrierProps {
  id?: string;
  carrierData: GetCarrier | undefined;

  onCancel: () => void;
  onConfirm: (data: any) => void;

  mode: string;
}

interface IFormInputs {
  label: string;
  externalReference?: string;
  country?: {
    value: string;
    label: string;
  };
  postalCode?: string;
  locality?: string;
  streetAddress: string;
  tvaNumber?: string;
  sendTripToSxd?: boolean;
  allBuildingSites?: boolean;
  supplier?: boolean;
  firstname?: string;
  lastname?: string;
  telephone?: string;
  email?: string;
}

const GeneralCarrier = React.memo((props: GeneralCarrierProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { addToast } = useToast();

  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [saveCarrier, setSaveCarrier] = useState<CarrierSave>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [label, setLabel] = useState<string>();
  const [externalReference, setExternalReference] = useState<string>();
  const [tvaNumber, setTvaNumber] = useState<string>();

  const [streetAddress, setStreetAddress] = useState<string>();
  const [locality, setLocality] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [countryOption, setCountryOption] = useState<SelectCountryOption | null>(
    props.carrierData?.address?.country
      ? {
          value: props.carrierData.address.country,
          label: countryList[props.carrierData.address.country],
          data: { value: props.carrierData.address.country, label: countryList[props.carrierData.address.country] },
        }
      : {
          value: 'FR',
          label: countryList['FR'],
          data: { value: 'FR', label: countryList['FR'] },
        },
  );

  const [firstname, setFirstname] = useState<string>();
  const [lastname, setLastname] = useState<string>();
  const [telephone, setTelephone] = useState<string>();
  const [email, setEmail] = useState<string>();

  const [carrierDataCustom, setCarrierDataCutom] = useState<DataCustomItem>({});

  const schema = yup.object().shape({
    label: yup.string().required('Le nom est obligatoire'),
    streetAddress: yup.string().required("L'adresse est obligatoire"),
    firstname: yup.string().when(['lastname', 'telephone', 'email'], ([lastname, telephone, email], schema) => {
      if (lastname?.length > 0 || telephone?.length > 0 || email?.length > 0) {
        return schema.required('Le prénom doit également être rempli');
      }
      return schema.notRequired();
    }),
    email: yup.string().test('email-validator', 'Doit être une adresse email valide', (value) => {
      return (value ?? '').length === 0 || isEmail(value ?? '');
    }),
  }); // on utilise un validateur de 'validator.js' pour correspondre au back

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (props.carrierData) {
      // on initialise SaveCarrier pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveCarrier({
        ...props.carrierData,
      });

      setIsActive(props.carrierData?.isActive ?? true);

      setLabel(props.carrierData?.label);
      setValue('label', props.carrierData?.label ?? '');
      setExternalReference(props.carrierData?.externalReference);
      setTvaNumber(props.carrierData?.tvaNumber);

      setStreetAddress(props.carrierData?.address?.streetAddress);
      setValue('streetAddress', props.carrierData?.address?.streetAddress ?? '');
      setLocality(props.carrierData?.address?.locality);
      setPostalCode(props.carrierData?.address?.postalCode);
      if (props.carrierData?.address?.country)
        setCountryOption({
          value: props.carrierData?.address?.country,
          label: countryList[props.carrierData?.address?.country],
        });
    }
  }, [props.carrierData]);

  function onSubmit() {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/carrier/add';
    if (props.mode == 'update') baseUrl = orgid + '/carrier/edit/' + props.id;

    if (saveCarrier) {
      const dataToSend: CarrierSave = saveCarrier;

      dataToSend.label = label;
      dataToSend.externalReference = externalReference;
      dataToSend.tvaNumber = tvaNumber;
      dataToSend.locality = locality;
      dataToSend.postalCode = postalCode;
      dataToSend.streetAddress = streetAddress;
      dataToSend.isActive = isActive;

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (props.mode === 'add') {
            // si add, on ajoute le contact
            addContact(res.data.content.uuid);
          }
          setIsAddingLoading(false);
          addToast(t('common.carrier' + (props.mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
          props.onConfirm(res.data.content.uuid);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    } else {
      setIsAddingLoading(false);
    }
  }

  function addContact(entityId: string) {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/contact/add';

    if (firstname || lastname || telephone || email) {
      const dataToSend: ContactSave = {
        firstname: firstname ?? email ?? lastname ?? telephone ?? '',
        lastname: lastname,
        entityId: entityId,
        contactEntity: ContactEntity.CARRIER,
        telephone: telephone,
        email: email,
      };

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          addToast(t('common.contactAdded'), ToastTypes.success);
          return res.data.content.uuid;
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  }

  return !props.carrierData && props.mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.generalInformations')} type='title1' />

            {props.mode === 'update' && (
              <FormRow align='right' alignVert='center'>
                <h3 className='base2'>{t('common.active')}</h3>
                <Switch
                  onChange={function () {
                    setIsActive((prevValue) => !prevValue);
                  }}
                  checked={isActive ?? false}
                  onColor={'#2a85ff'}
                />
              </FormRow>
            )}
          </FormRow>
          <FormRow>
            <Input
              {...register('label')}
              error={errors.label?.message ?? ''}
              label={t('common.companyName')}
              type='text'
              value={label ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('label', e.toString());
                setLabel(e.toString());
              }}
            />
            <Input
              {...register('externalReference')}
              error={errors.externalReference?.message ?? ''}
              label={t('common.yourReference')}
              type='text'
              value={externalReference ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setExternalReference(e.toString());
              }}
            />
            <Input
              {...register('tvaNumber')}
              error={errors.tvaNumber?.message ?? ''}
              label={t('common.tvaNumber')}
              type='text'
              value={tvaNumber ?? ''}
              placeholder='FRXX999999999'
              disabled={false}
              onChange={function (e): void {
                setTvaNumber(e.toString());
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('streetAddress')}
              error={errors.streetAddress?.message ?? ''}
              label={t('common.address')}
              type='text'
              value={streetAddress ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('streetAddress', e.toString());
                setStreetAddress(e.toString());
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('locality')}
              error={errors.locality?.message ?? ''}
              label={t('common.locality')}
              type='text'
              value={locality ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setLocality(e.toString());
              }}
            />
            <Input
              {...register('postalCode')}
              error={errors.postalCode?.message ?? ''}
              label={t('common.postalCode')}
              type='text'
              value={postalCode ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setPostalCode(e.toString());
              }}
            />
            <SelectCountry
              register={register}
              registerName='country'
              setValue={setValue}
              error={errors.country?.value?.message ?? errors.country?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectCountryOption) => {
                setSaveCarrier((prevValue) => {
                  return { ...prevValue, country: e?.value };
                });
              }}
              forceSelectedOption={countryOption}
              titleSize='normal'
            />
          </FormRow>
        </div>
        {props.mode === 'add' && (
          <div className='form-section'>
            <FormRow>
              <Title label={t('common.contact')} type='title1' />
            </FormRow>
            <FormRow>
              <Input
                {...register('lastname')}
                error={errors?.lastname?.message ?? ''}
                label={t('common.lastname')}
                type='text'
                value={lastname ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('lastname', e.toString());
                  setLastname(e.toString());
                }}
              />
              <Input
                {...register('firstname')}
                error={errors?.firstname?.message ?? ''}
                label={t('common.firstname')}
                type='text'
                value={firstname ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('firstname', e.toString());
                  setFirstname(e.toString());
                }}
              />
            </FormRow>
            <FormRow>
              <Input
                {...register('telephone')}
                error={errors?.telephone?.message ?? ''}
                label={t('common.telephone')}
                type='text'
                value={telephone ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('telephone', e.toString());
                  setTelephone(e.toString());
                }}
              />
              <Input
                {...register('email')}
                error={errors?.email?.message ?? ''}
                label={t('common.email')}
                type='email'
                value={email ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('email', e.toString());
                  setEmail(e.toString());
                }}
              />
            </FormRow>
          </div>
        )}
        <div className='form-section'>
          <BuildDataCustom
            dataCustomType={'Carrier'}
            dataCustom={carrierDataCustom}
            dataCustomUpdated={(data: DataCustomItem) => {
              setSaveCarrier((prevValue) => {
                return { ...prevValue, dataCustom: data };
              });
            }}
          />
        </div>
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={props.onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralCarrier.displayName = 'GeneralCarrier';
export default GeneralCarrier;
