import './icon.scss';

import { ReactComponent as Notification } from '../../assets/icons/notifications.svg';
import { ReactComponent as Back } from '../../assets/icons/back.svg';
import { ReactComponent as Key } from '../../assets/icons/key.svg';
import { ReactComponent as Data } from '../../assets/icons/data.svg';
import { ReactComponent as Site } from '../../assets/icons/site.svg';
import { ReactComponent as Trip } from '../../assets/icons/trip.svg';
import { ReactComponent as Autorisation } from '../../assets/icons/autorisation.svg';
import { ReactComponent as More } from '../../assets/icons/more.svg';
import { ReactComponent as Add } from '../../assets/icons/add.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as LogOut } from '../../assets/icons/logout.svg';
import { ReactComponent as Less } from '../../assets/icons/less.svg';
import { ReactComponent as Expand } from '../../assets/icons/expand.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as Save } from '../../assets/icons/save.svg';
import { ReactComponent as Send } from '../../assets/icons/send.svg';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { ReactComponent as Upload } from '../../assets/icons/upload.svg';
import { ReactComponent as Filter } from '../../assets/icons/filter.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as Valid } from '../../assets/icons/valid.svg';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';
import { ReactComponent as Refresh } from '../../assets/icons/refresh.svg';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { ReactComponent as Calc } from '../../assets/icons/calc.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as Question } from '../../assets/icons/question.svg';
import { ReactComponent as Warning } from '../../assets/icons/warning.svg';
import { ReactComponent as OpenLink } from '../../assets/icons/openLink.svg';

export type IconType =
  | ''
  | 'notification'
  | 'back'
  | 'data'
  | 'site'
  | 'trip'
  | 'autorisation'
  | 'key'
  | 'more'
  | 'add'
  | 'delete'
  | 'close'
  | 'edit'
  | 'logout'
  | 'less'
  | 'expand'
  | 'eye'
  | 'save'
  | 'send'
  | 'download'
  | 'upload'
  | 'filter'
  | 'search'
  | 'valid'
  | 'cancel'
  | 'refresh'
  | 'loader'
  | 'calc'
  | 'info'
  | 'question'
  | 'warning'
  | 'openLink';

interface IconProps {
  icon: IconType;
  style?: 'primary' | 'primary-02' | 'primary-03' | 'secondary' | 'tertiary' | 'quaternary' | 'white';
}

const icons: { [key: string]: any } = {
  notification: <Notification />,
  back: <Back />,
  data: <Data />,
  site: <Site />,
  trip: <Trip />,
  autorisation: <Autorisation />,
  key: <Key />,
  more: <More />,
  add: <Add />,
  delete: <Delete />,
  close: <Close />,
  edit: <Edit />,
  logout: <LogOut />,
  less: <Less />,
  expand: <Expand />,
  eye: <Eye />,
  save: <Save />,
  send: <Send />,
  download: <Download />,
  upload: <Upload />,
  filter: <Filter />,
  search: <Search />,
  valid: <Valid />,
  cancel: <Cancel />,
  refresh: <Refresh />,
  loader: <Loader />,
  calc: <Calc />,
  info: <Info />,
  question: <Question />,
  warning: <Warning />,
  openLink: <OpenLink />,
};

export const Icon = ({ icon = '', style = undefined }: IconProps) => {
  return (
    <div className={['dune-icon', `dune-icon--${style}`].join(' ')} data-tag='allowRowEvents'>
      {icons[icon]}
    </div>
  );
};
