import React, { useEffect, useState } from 'react';
import './Login.scss';
import '../../index.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import Lang from '../Lang';
import { ReactComponent as Logo } from '../../assets/svg/dune-logo.svg';
import SelectWorkspace from '../SelectWorkspace';
import Roles from '../../utils/Roles';
import { Button } from '../../stories/dune/atoms/Button';
import { FormRow } from '../../stories/dune/atoms/FormRow';
import PopupContainer from '../../stories/dune/organisms/PopupContainer';
import { Input } from '../../stories/dune/atoms/Input';
import { ErrorLine } from '../../stories/dune/atoms/ErrorLine';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { loadStaticData } from '../../store/staticData-slice';
import { fetchUserData, sendPasswordResetRequest, signin } from '../../services/auth';
import validator from 'validator';

const loginSchema = yup.object().shape({
  username: yup.string().email('Adresse email non valide').required('Le mail est obligatoire'),
  password: yup.string().required('Le mot de passe est obligatoire'),
});

export type Credentials = yup.InferType<typeof loginSchema>;

function Login() {
  const { t } = useTranslation();

  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resultResetPassword, setResultResetPassword] = useState('');
  const [hasError, setHasError] = useState(false);

  const [isSelectingOrga, setIsSelectingOrga] = useState(false);
  const [isOpenForgotPassword, setIsOpenForgotPassword] = useState(false);

  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>(); // The `useDispatch` hook with AppDispatch type to call async thunk actions

  useEffect(() => {
    localStorage.clear();
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Credentials>({
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const chooseWorkspace = () => {
    history('/trips');
  };

  const onSubmit = async (credentials: Credentials) => {
    const credentialsBase64 = {
      username: credentials.username,
      password: Buffer.from(credentials.password).toString('base64'),
    };

    try {
      const { accessToken } = await signin(credentialsBase64);
      localStorage.setItem('token', accessToken);
      localStorage.setItem('email', credentialsBase64.username);
      getScopes();
    } catch (error) {
      const is401Error = axios.isAxiosError(error) && error.response && error.response.status === 401;
      is401Error ? setErrorMessage(t('login.IncorrectCredentials')) : setErrorMessage(t('login.ErrorMessage'));
    }
  };

  const getScopes = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      setErrorMessage(t('login.ErrorMessage'));
      return;
    }

    try {
      const { workspaces, uuid } = await fetchUserData(token);
      const savedWorkspaces = Roles.saveWorkspaces(workspaces);
      Roles.saveUser(uuid);

      if (savedWorkspaces.length > 1) {
        setIsSelectingOrga(true);
        return;
      }

      Roles.setLocalStorage(workspaces[0]);
      dispatch(loadStaticData()); // Load static data once authenticated (called at refresh by APP as well)
      history('/trips');
    } catch (error) {
      const is401Error = axios.isAxiosError(error) && error.response && error.response.status === 401;
      is401Error ? setErrorMessage(t('login.IncorrectCredentials')) : setErrorMessage(t('login.ErrorMessage'));
    }
  };

  const onKeyPressEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await handleSubmit(onSubmit)();
    }
  };

  const onSendEmail = async () => {
    const isEmail = validator.isEmail(resetPasswordEmail);
    if (!isEmail) {
      setHasError(true);
      setResultResetPassword(t('login.ErrorMessage'));
      return;
    }

    try {
      await sendPasswordResetRequest(resetPasswordEmail);
      setResultResetPassword(t('login.sendEmail'));
      setHasError(false);
    } catch (error) {
      setHasError(true);

      if (axios.isAxiosError(error) && !error.response) {
        setResultResetPassword(t('login.ErrorMessage'));
        return;
      }

      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        switch (status) {
          case 400:
            setResultResetPassword(
              data.code === 'ERR4000003' ? t('login.resetPassword.results.error') : t('login.ErrorMessage'),
            );
            break;
          case 401:
            setResultResetPassword(t('login.IncorrectCredentials'));
            break;
          default:
            setResultResetPassword(t('login.ErrorMessage'));
        }
      }
    }
  };

  return (
    <div className='login'>
      <div className='leftImage'></div>
      <div className='right'>
        <div style={{ textAlign: 'center' }}>
          <Logo></Logo>
        </div>

        <h1 className='title-center'>{t('login.welcomeMessage')}</h1>
        <div className='content'>
          {!isSelectingOrga ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h5>{t('login.email')}</h5>

              <input type='text' {...register('username', {})} name='username' placeholder='Dune.manager@gmail.com' />
              <p className='validator-error'>{errors.username?.message}</p>

              <h5>{t('login.password')}</h5>

              <input
                type='password'
                {...register('password', {})}
                name='password'
                placeholder='***********'
                onKeyPress={onKeyPressEvent}
              />

              <p className='validator-error'>{errors.password?.message}</p>

              <p className='validator-error'>{errorMessage}</p>

              <div onClick={() => setIsOpenForgotPassword(true)} className='link'>
                {t('login.forgetPassword')}
              </div>
              <div style={{ marginTop: '20px' }} />
              <FormRow>
                <Button
                  size='widelarge'
                  label={t('login.connexion')}
                  style='primary'
                  onClick={handleSubmit(onSubmit)}
                />
              </FormRow>
            </form>
          ) : (
            <div>
              <p className='chooseWorkspaceExplanation'>
                Vous êtes associés à plusieurs workspaces Dune. Choisissez un workspace pour vous connecter :
              </p>
              <SelectWorkspace />

              <FormRow>
                <Button size='widelarge' label={t('login.access')} style='primary' onClick={() => chooseWorkspace()} />
              </FormRow>
            </div>
          )}
        </div>
      </div>
      {isOpenForgotPassword && (
        <PopupContainer
          isLarge={false}
          title={t('login.forgetPasswordTitle')}
          confirmIcon=''
          confirmLabel={t('common.validate')}
          onCancel={() => setIsOpenForgotPassword(false)}
          onConfirm={() => {
            onSendEmail();
            setIsOpenForgotPassword(false);
          }}
        >
          <div className='form-section'>
            <Input
              label={t('login.email')}
              type='text'
              value={resetPasswordEmail}
              placeholder='Dune.manager@gmail.com'
              disabled={false}
              onChange={(e) => setResetPasswordEmail(e.toString())}
              error={''}
            />
            {hasError && <ErrorLine label={resultResetPassword} />}
          </div>
        </PopupContainer>
      )}

      <div className='login langSelector'>
        <Lang></Lang>
      </div>
    </div>
  );
}

export default Login;
