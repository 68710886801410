import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { SelectCustomer, SelectCustomerOption } from '../../forms/SelectCustomer';
import { Input } from '../../../stories/dune/atoms/Input';
import { BuildingSiteSave, GetBuildingSite } from '../../../models/BuildingSite';
import { Title } from '../../../stories/dune/atoms/Title';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { SelectCountry, SelectCountryOption } from '../../forms/SelectCountry';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/use-toast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import Config from '../../../Config';
import countryList from '../../Countries';
import Switch from 'react-switch';
import { ContactSave } from '../../../models/Contact';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import isEmail from 'validator/lib/isEmail';
import stringUtils from '../../../utils/stringUtils';
import { Customer } from '@dune-manager/backend-core/dist/models';
import { SelectLocality, SelectLocalityOption } from '../../forms/SelectLocality';
import { GlobalParameter } from '../../../models/GlobalParameter';
import { ManageDocument } from '../../forms/ManageDocument';

interface GeneralBuildingSiteProps {
  id?: string;
  buildingSiteData: GetBuildingSite | undefined;

  onCancel: () => void;
  onConfirm: (data: any) => void;

  mode: string;

  dataParent?: string;
}

const GeneralBuildingSite = React.memo((props: GeneralBuildingSiteProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { addToast } = useToast();

  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [saveBuildingSite, setSaveBuildingSite] = useState<BuildingSiteSave>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [customerOption, setCustomerOption] = useState<SelectCustomerOption | null>(
    props.buildingSiteData?.customer
      ? {
          value: props.buildingSiteData.customerId,
          label: props.buildingSiteData.customer.label || '', // Update the label to be a string or an empty string if undefined
          data: props.buildingSiteData.customer,
        }
      : null,
  );
  const [label, setLabel] = useState<string>();
  const [externalReference, setExternalReference] = useState<string>();
  const [tvaNumber, setTvaNumber] = useState<string>();

  const [streetAddress, setStreetAddress] = useState<string>();
  const [locality, setLocality] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [localityOption, setLocalityOption] = useState<SelectLocalityOption | null>(
    props.buildingSiteData?.address?.locality
      ? {
          value: 'TODO-INSEE',
          label: props.buildingSiteData?.address?.locality ?? '',
          data: {
            code: 'TODO-INSEE',
            nom: props.buildingSiteData?.address?.locality ?? '',
            codesPostaux: [props.buildingSiteData?.address?.postalCode ?? ''],
          },
        }
      : null,
  );
  const [countryOption, setCountryOption] = useState<SelectCountryOption | null>(
    props.buildingSiteData?.address?.country
      ? {
          value: props.buildingSiteData.address.country,
          label: countryList[props.buildingSiteData.address.country],
          data: {
            value: props.buildingSiteData.address.country,
            label: countryList[props.buildingSiteData.address.country],
          },
        }
      : {
          value: 'FR',
          label: countryList['FR'],
          data: { value: 'FR', label: countryList['FR'] },
        },
  );

  const [customerIsExemptFromRepTax, setCustomerIsExemptFromRepTax] = useState<boolean>(false);
  const [isExemptFromRepTax, setIsExemptFromRepTax] = useState<boolean>(false);
  const [isSubjectToRepTax, setIsSubjectToRepTax] = useState<boolean>(false);

  const [firstname, setFirstname] = useState<string>();
  const [lastname, setLastname] = useState<string>();
  const [telephone, setTelephone] = useState<string>();
  const [email, setEmail] = useState<string>();

  const [buildingSiteDataCustom, setBuildingSiteDataCutom] = useState<DataCustomItem>({});

  const [globalParameters, setGlobalParameters] = useState<GlobalParameter[]>([]);

  interface IFormInputs {
    label: string;
    client: {
      value: string;
      label: string;
    };
    externalReference?: string;
    country?: {
      value: string;
      label: string;
    };
    postalCode?: string;
    locality?: {
      value: string;
      label: string;
    };
    streetAddress: string;
    tvaNumber?: string;
    sendTripToSxd?: boolean;
    allBuildingSites?: boolean;
    supplier?: boolean;
    firstname?: string;
    lastname?: string;
    telephone?: string;
    email?: string;
  }

  const schema = yup.object().shape({
    label: yup.string().when([], (fields, schema) => {
      const shouldNotRequireLabel =
        globalParameters.findIndex((x) => x.label === 'operatorMustFillLabelBuildingSite' && x.value === 'false') !==
        -1;

      return shouldNotRequireLabel ? schema.notRequired() : schema.required('Le nom est obligatoire');
    }),
    client: yup.object().shape({
      value: yup.string().required('Le client est obligatoire'),
      label: yup.string().required('Le client est obligatoire'),
    }),
    streetAddress: yup.string().when([], (fields, schema) => {
      const shouldNotRequireStreetAddress =
        globalParameters.findIndex((x) => x.label === 'operatorMustFillAddressBuildingSite' && x.value === 'false') !==
        -1;

      return shouldNotRequireStreetAddress ? schema.notRequired() : schema.required("L'adresse est obligatoire");
    }),
    locality: yup.object().when([], (fields, schema) => {
      const shouldRequireLocality =
        globalParameters.findIndex((x) => x.label === 'operatorMustFillLocalityBuildingSite' && x.value === 'true') !==
        -1;

      return shouldRequireLocality
        ? schema.shape({
            value: yup.string().nullable().required('La ville est obligatoire'),
            label: yup.string().nullable().required('La ville est obligatoire'),
          })
        : schema.nullable().notRequired();
    }),
    firstname: yup.string().when(['lastname', 'telephone', 'email'], ([lastname, telephone, email], schema) => {
      const shouldRequireFirstname = lastname?.length > 0 || telephone?.length > 0 || email?.length > 0;

      return shouldRequireFirstname ? schema.required('Le prénom doit également être rempli') : schema.notRequired();
    }),
    email: yup.string().test('email-validator', 'Doit être une adresse email valide', (value) => {
      return (value ?? '').length === 0 || isEmail(value ?? '');
    }),
  }); // on utilise un validateur de 'validator.js' pour correspondre au back

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (props.buildingSiteData) {
      // on initialise SaveBuildingSite pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveBuildingSite({ ...props.buildingSiteData });

      setIsActive(props.buildingSiteData?.isActive ?? true);

      if (props.buildingSiteData.customer) {
        setValue('client', {
          value: props.buildingSiteData.customer.id ?? '',
          label: props.buildingSiteData.customer.label ?? '',
        });
        setCustomerOption({
          value: props.buildingSiteData.customer.id ?? '',
          label: props.buildingSiteData.customer.label ?? '',
          data: props.buildingSiteData.customer as Customer,
        });
      }

      setLabel(props.buildingSiteData?.label);
      setValue('label', props.buildingSiteData?.label ?? '');
      setExternalReference(props.buildingSiteData?.externalReference);
      setTvaNumber(props.buildingSiteData?.tvaNumber);

      setStreetAddress(props.buildingSiteData?.address?.streetAddress);
      setValue('streetAddress', props.buildingSiteData?.address?.streetAddress ?? '');
      setLocality(props.buildingSiteData?.address?.locality);
      setPostalCode(props.buildingSiteData?.address?.postalCode);
      if (props.buildingSiteData?.address?.locality)
        setLocalityOption({
          value: props.buildingSiteData?.address?.inseeCode ?? '',
          label: props.buildingSiteData?.address?.locality ?? '',
          data: {
            code: props.buildingSiteData?.address?.inseeCode ?? '',
            nom: props.buildingSiteData?.address?.locality ?? '',
            codesPostaux: [props.buildingSiteData?.address?.postalCode ?? ''],
          },
        });
      if (props.buildingSiteData?.address?.country)
        setCountryOption({
          value: props.buildingSiteData?.address?.country,
          label: countryList[props.buildingSiteData?.address?.country],
        });

      setCustomerIsExemptFromRepTax(props.buildingSiteData?.customer?.isExemptFromRepTax ?? false);
      setIsExemptFromRepTax(props.buildingSiteData?.isExemptFromRepTax ?? false);
      setIsSubjectToRepTax(props.buildingSiteData?.isSubjectToRepTax ?? false);

      setBuildingSiteDataCutom(props.buildingSiteData?.dataCustom as any);
    }
  }, [props.buildingSiteData]);

  useEffect(() => {
    if (props.dataParent) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/customer/detail/';
      const url = orgid + baseUrl + props.dataParent;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setValue('client', {
            value: res.data.content.id,
            label: res.data.content.label,
          });
          setCustomerOption({
            value: res.data.content.id,
            label: res.data.content.label,
            data: res.data.content as Customer,
          });
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  }, [props.dataParent]);

  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setGlobalParameters(globalParametersSite);
  }, []);

  function onSubmit() {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/building-site/add';
    if (props.mode == 'update') baseUrl = orgid + '/building-site/edit/' + props.id;

    if (saveBuildingSite) {
      const dataToSend: BuildingSiteSave = saveBuildingSite;

      dataToSend.label = label ?? dataToSend.locality ?? streetAddress ?? '';
      dataToSend.externalReference = externalReference;
      dataToSend.tvaNumber = tvaNumber;
      dataToSend.postalCode = postalCode;
      dataToSend.streetAddress = streetAddress;
      dataToSend.isExemptFromRepTax = isExemptFromRepTax;
      dataToSend.isSubjectToRepTax = isSubjectToRepTax;
      dataToSend.isActive = isActive;

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (props.mode === 'add') {
            // si add, on ajoute le contact
            addContact(res.data.content.uuid);
          }
          setIsAddingLoading(false);
          addToast(t('common.buildingSite' + (props.mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
          props.onConfirm(res.data.content.uuid);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    } else {
      setIsAddingLoading(false);
    }
  }

  function addContact(entityId: string) {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/contact/add';

    if (firstname || lastname || telephone || email) {
      const dataToSend: ContactSave = {
        firstname: firstname ?? email ?? lastname ?? telephone ?? '',
        lastname: lastname,
        entityId: entityId,
        contactEntity: ContactEntity.BUILDING_SITE,
        telephone: telephone,
        email: email,
      };

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          addToast(t('common.contactAdded'), ToastTypes.success);
          return res.data.content.uuid;
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  }

  return !props.buildingSiteData && props.mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.generalInformations')} type='title1' />

            {props.mode === 'update' && (
              <FormRow align='right' alignVert='center'>
                <h3 className='base2'>{t('common.active')}</h3>
                <Switch
                  onChange={function () {
                    setIsActive((prevValue) => !prevValue);
                  }}
                  checked={isActive ?? false}
                  onColor={'#2a85ff'}
                />
              </FormRow>
            )}
          </FormRow>
          <FormRow>
            <Input
              {...register('label')}
              error={errors.label?.message ?? ''}
              label={t('common.label')}
              type='text'
              value={label ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('label', e.toString());
                setLabel(e.toString());
              }}
            />
            <Input
              {...register('externalReference')}
              error={errors.externalReference?.message ?? ''}
              label={t('common.yourReference')}
              type='text'
              value={externalReference ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setExternalReference(e.toString());
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('tvaNumber')}
              error={errors.tvaNumber?.message ?? ''}
              label={t('common.tvaNumber')}
              type='text'
              value={tvaNumber ?? ''}
              placeholder='FRXX999999999'
              disabled={false}
              onChange={function (e): void {
                setTvaNumber(e.toString());
              }}
            />
            <SelectCustomer
              titleSize='normal'
              register={register}
              registerName='client'
              setValue={setValue}
              error={errors.client?.value?.message ?? errors.client?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectCustomerOption) => {
                if (e && e.value) {
                  setValue('client', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveBuildingSite((prevValue) => {
                    return { ...prevValue, customerId: e?.value };
                  });

                  setCustomerIsExemptFromRepTax(e.data.isExemptFromRepTax ?? false);
                } else {
                  setValue('client', {
                    value: '',
                    label: '',
                  });

                  setSaveBuildingSite((prevValue) => {
                    return { ...prevValue, customerId: undefined };
                  });

                  setCustomerIsExemptFromRepTax(false);
                }
              }}
              forceSelectedOption={customerOption}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('streetAddress')}
              error={errors.streetAddress?.message ?? ''}
              label={t('common.address')}
              type='text'
              value={streetAddress ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('streetAddress', e.toString());
                setStreetAddress(e.toString());
              }}
            />
          </FormRow>
          <FormRow>
            <SelectLocality
              register={register}
              registerName='locality'
              setValue={setValue}
              error={errors.locality?.value?.message ?? errors.locality?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectCountryOption) => {
                setPostalCode(e?.data?.codesPostaux[0] === 'INCONNU' ? postalCode : e?.data?.codesPostaux[0]);
                setSaveBuildingSite((prevValue) => {
                  return { ...prevValue, locality: e?.data?.nom, inseeCode: e?.data?.code };
                });
              }}
              forceSelectedOption={localityOption}
              titleSize='normal'
            />
            <Input
              {...register('postalCode')}
              error={errors.postalCode?.message ?? ''}
              label={t('common.postalCode')}
              type='text'
              value={postalCode ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setPostalCode(e.toString());
              }}
            />
            <SelectCountry
              register={register}
              registerName='country'
              setValue={setValue}
              error={errors.country?.value?.message ?? errors.country?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectCountryOption) => {
                setSaveBuildingSite((prevValue) => {
                  return { ...prevValue, country: e?.value };
                });
              }}
              forceSelectedOption={countryOption}
              titleSize='normal'
            />
          </FormRow>
        </div>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.repTax')} type='title1' />
          </FormRow>
          <FormRow>
            {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
            <div
              className='panelInput'
              title={
                customerIsExemptFromRepTax
                  ? t('common.customerIsExemptFromRepTaxDesc')
                  : t('common.customerIsSubjectToRepTaxDesc')
              }
            >
              <Switch
                className='base2'
                type='text'
                checked={customerIsExemptFromRepTax}
                onColor={'#2a85ff'}
                onChange={() => {
                  return undefined;
                }}
                disabled={true}
              />
              <h3 className='base2' style={{ margin: '0px 10px' }}>
                {t('common.customerIsExemptFromRepTax')}
              </h3>
            </div>
            {!customerIsExemptFromRepTax && (
              <div className='panelInput' title={t('common.buildingSiteIsExemptFromRepTaxDesc')}>
                <Switch
                  className='base2'
                  type='text'
                  checked={isExemptFromRepTax}
                  onChange={(checked: boolean) => setIsExemptFromRepTax(checked)}
                  onColor={'#2a85ff'}
                />
                <h3 className='base2' style={{ margin: '0px 10px' }}>
                  {t('common.buildingSiteIsExemptFromRepTax')}
                </h3>
              </div>
            )}
            {customerIsExemptFromRepTax && (
              <div className='panelInput' title={t('common.buildingSiteIsExemptFromRepTaxDesc')}>
                <Switch
                  className='base2'
                  type='text'
                  checked={!isSubjectToRepTax}
                  onChange={(checked: boolean) => setIsSubjectToRepTax(!checked)}
                  onColor={'#2a85ff'}
                />
                <h3 className='base2' style={{ margin: '0px 10px' }}>
                  {t('common.buildingSiteIsExemptFromRepTax')}
                </h3>
              </div>
            )}
          </FormRow>
          {isExemptFromRepTax && (
            <FormRow alignVert='center'>
              {props.mode !== 'add' && (
                // hide document management when adding because we don't have the entity id yet
                <ManageDocument
                  label={t('common.exemptionDocument')}
                  hoverDescription={t('common.exemptionDocumentDesc')}
                  docType={'RepBuildingSiteExemption'}
                  entityType='building_site'
                  entityId={props.id ?? ''}
                  onAddDocument={() => {
                    return undefined;
                  }}
                />
              )}
              <div
                title={t('common.clickHereForRepExemptionTemplateDesc')}
                style={{
                  margin: '10px 20px',
                }}
              >
                <a
                  href={'http://download.synaxe.com/tmp/modele%2Dattestation%2Dexoneration%2Dchantier%2Epdf'}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('common.clickHereForRepExemptionTemplate')}
                </a>
              </div>
            </FormRow>
          )}
        </div>
        {props.mode === 'add' && (
          <div className='form-section'>
            <FormRow>
              <Title label={t('common.contact')} type='title1' />
            </FormRow>
            <FormRow>
              <Input
                {...register('lastname')}
                error={errors?.lastname?.message ?? ''}
                label={t('common.lastname')}
                type='text'
                value={lastname ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('lastname', e.toString());
                  setLastname(e.toString());
                }}
              />
              <Input
                {...register('firstname')}
                error={errors?.firstname?.message ?? ''}
                label={t('common.firstname')}
                type='text'
                value={firstname ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('firstname', e.toString());
                  setFirstname(e.toString());
                }}
              />
            </FormRow>
            <FormRow>
              <Input
                {...register('telephone')}
                error={errors?.telephone?.message ?? ''}
                label={t('common.telephone')}
                type='text'
                value={telephone ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('telephone', e.toString());
                  setTelephone(e.toString());
                }}
              />
              <Input
                {...register('email')}
                error={errors?.email?.message ?? ''}
                label={t('common.email')}
                type='email'
                value={email ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('email', e.toString());
                  setEmail(e.toString());
                }}
              />
            </FormRow>
          </div>
        )}
        <div className='form-section'>
          <BuildDataCustom
            dataCustomType={'BuildingSite'}
            dataCustom={buildingSiteDataCustom}
            dataCustomUpdated={(data: DataCustomItem) => {
              setSaveBuildingSite((prevValue) => {
                return { ...prevValue, dataCustom: data };
              });
            }}
          />
        </div>
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={props.onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralBuildingSite.displayName = 'GeneralBuildingSite';
export default GeneralBuildingSite;
