import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import HistContract from './HistContract';
import { GetQuotation as GetContract } from '../../../models/Quotation';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import { useDispatch, useSelector } from 'react-redux';
import { DuplicationState, duplicationActions } from '../../../store/duplication-slice';
import GeneralContract from './GeneralContract';
import Customer from '../../../models/Customer';
import BuildingSite from '../../../models/BuildingSite';
import moment from 'moment';

interface DetailContractProps {
  id?: string;

  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailContract = React.memo((props: DetailContractProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  const dispatch = useDispatch();
  const contractDuplicateData = useSelector((state: { duplication: DuplicationState }) => state.duplication.contract);

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
    quotationMode: 'customer' | 'buildingSite';
    parentId?: string;
  };

  const { id, mode, quotationMode: quotationMode, parentId: parentId } = useParams<Params>();

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getContract, setGetContract] = useState<GetContract>();
  const [parentCustomer, setParentCustomer] = useState<Customer>();
  const [parentBuildingSite, setParentBuildingSite] = useState<BuildingSite>();

  function getCurrentContract(id: string | undefined) {
    if (id && getMode() === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/quotation/detail/';
      const url = Config.getApiExtranetUrl(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre GetContract
            setGetContract(res.data.content);
            setParentCustomer(res.data.content.customer);
            setParentBuildingSite(res.data.content.buildingSite);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else if (contractDuplicateData && getMode() === 'add') {
      setGetContract(contractDuplicateData);
      if (contractDuplicateData.buildingSite) {
        setParentBuildingSite(contractDuplicateData.buildingSite);
        setParentCustomer(contractDuplicateData.customer);
      } else if (contractDuplicateData.customer) setParentCustomer(contractDuplicateData.customer);
      dispatch(duplicationActions.clearDuplicatedData('Contract'));
      setIsLoading(false);
    } else {
      if (parentId) {
        const token = localStorage.getItem('token');
        const orgid = localStorage.getItem('orgid');
        const baseUrl = orgid + '/' + (quotationMode === 'buildingSite' ? 'building-site' : 'customer') + '/detail/';
        const url = Config.getApiExtranetUrl(baseUrl + parentId);
        setIsLoading(true);
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if ((res.data.content ?? null) !== null) {
              // on enregistre le parent
              if (quotationMode === 'buildingSite') {
                setParentBuildingSite(res.data.content);
                setParentCustomer(res.data.content.customer);
              } else {
                setParentCustomer(res.data.content);
              }
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.response) {
              if (
                error.response.data.code == 'ERR4010001' ||
                error.response.data.code == 'ERR4031001' ||
                error.response.data.code == 'ERR4010000'
              ) {
                history('/');
              }
            }
            if ((id ?? null) !== null) {
              addToast(
                (error?.response?.data?.code
                  ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                  : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
                ToastTypes.error,
              );
            }
          });
      }
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCurrentContract(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <div className='main-container'>
      <Menu selectedPage='contracts'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
      <div className='right detail-container'>
        <Head
          title={
            getMode() === 'add'
              ? t('common.newContract')
              : !getContract
              ? ''
              : t('common.contract') +
                ' ' +
                getContract.quotationNumber +
                (getContract.externalReference ? '-' + getContract.externalReference : '')
          }
          breadcrumbs1={
            parentCustomer || getContract?.customer?.id ? (
              <Link
                to={`/customers/${parentCustomer?.id ?? getContract?.customer?.id ?? ''}/update/contract`}
                className='breadcrumb-link'
              >
                {parentCustomer?.label ?? getContract?.customer?.label ?? ''}
              </Link>
            ) : undefined
          }
          breadcrumbs2={
            parentBuildingSite || getContract?.buildingSite?.id ? (
              <Link
                to={`/buildingSites/${parentBuildingSite?.id ?? getContract?.buildingSite?.id ?? ''}/update/contract`}
                className='breadcrumb-link'
              >
                {parentBuildingSite?.label ?? getContract?.buildingSite?.label ?? ''}
              </Link>
            ) : undefined
          }
          titleType='h1'
          back={true}
          Back={function (): void {
            if (parentCustomer) {
              history('/customers/' + parentCustomer?.id + '/update/contract');
            } else history('/contracts');
          }}
        />

        {!isLoading && (
          <>
            {getMode() === 'add' && (
              <GeneralContract
                mode={getMode()}
                contractMode={quotationMode}
                contractData={getContract}
                contractCustomer={parentCustomer}
                contractBuildingSite={parentBuildingSite}
                onCancel={onCanceled}
                onConfirm={onConfirmed}
              />
            )}

            {getMode() !== 'add' && (
              <>
                {currentTab === 'general' && (
                  <GeneralContract
                    id={id}
                    mode={getMode()}
                    contractMode={quotationMode}
                    contractData={getContract}
                    contractCustomer={parentCustomer}
                    contractBuildingSite={parentBuildingSite}
                    onCancel={onCanceled}
                    onConfirm={onConfirmed}
                    onReload={() => {
                      getCurrentContract(id);
                    }}
                    isEditable={
                      (getContract?.dateEnd ?? undefined) === undefined ||
                      moment(getContract?.dateEnd) > moment(new Date())
                    }
                  />
                )}
                {currentTab === 'history' && (
                  <div className='detail-content'>
                    <HistContract contractData={getContract} />
                  </div>
                )}
                {currentTab === 'documents' && getContract && (
                  <ListAdditionalDocs entityType='quotation' entityId={getContract.id} canAddNew={true} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      if (getMode() === 'add' && !parentBuildingSite) history('/contracts/' + data + '/update');
      else if (parentCustomer) {
        history('/customers/' + parentCustomer?.id + '/update/contract');
      } else if (parentBuildingSite) {
        history('/buildingSites/' + parentBuildingSite?.id + '/update/contract');
      } else {
        history('/contracts');
      }
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/contracts');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer hideBottomBar={true} isLarge={true}>
          {content}
        </PopupContainer>
      ) : (
        content
      )}
    </>
  );
});

DetailContract.displayName = 'DetailContract';
export default DetailContract;
