import React from 'react';
import './paragraphInput.scss';

interface ParagraphInputProps {
  size?: 'small' | 'medium' | 'large';

  placeholder?: string;

  label?: string;

  onChange: (newValue: string) => void;

  error: string | undefined;

  value: string;
}

export const ParagraphInput = ({ label, placeholder, value, error, onChange }: ParagraphInputProps) => {
  return (
    <div className='dune-content-paragraphInput'>
      {label ? <span className='dune-title base2'>{label}</span> : ''}
      <textarea
        placeholder={placeholder ?? undefined}
        value={value ?? undefined}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(event?.target.value)}
        className='dune-paragraphInput base2'
      />
      {error ? <span className='dune-input-error'>{error}</span> : ''}
    </div>
  );
};
