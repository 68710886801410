import './formRow.scss';
import React, { ReactNode } from 'react';

export const FormRow = React.memo(
  (props: {
    align?: 'center' | 'right' | 'left' | undefined;
    alignVert?: 'center' | undefined;
    column?: boolean | undefined;
    children?: ReactNode | undefined;
  }) => {
    return (
      <div
        className={[
          'form-row',
          `form-row-${props.align}`,
          `form-row-align-${props.alignVert}`,
          props.column ? 'form-row-column' : undefined,
        ].join(' ')}
      >
        {props.children}
      </div>
    );
  },
);

FormRow.displayName = 'FormRow';
