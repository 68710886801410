import React, { ReactNode } from 'react';
import './title.scss';

interface TitleProps {
  type?: 'h1' | 'h3' | 'h4' | 'h5' | 'title1' | 'title2' | 'label';

  align?: 'left' | 'center' | 'right';

  width?: 'full' | 'auto';

  label: string | ReactNode;

  children?: ReactNode | undefined;

  breadcrumbs1?: ReactNode;
  breadcrumbs2?: ReactNode;
  breadcrumbs3?: ReactNode;
}

export const Title = ({ type = 'h1', align = 'center', width = 'full', label, ...props }: TitleProps) => {
  return (
    <span className={'dune-title dune-title-' + type + ' dune-title-' + align + ' dune-title-' + width}>
      <>
        {props.breadcrumbs1 && props.breadcrumbs1}
        {props.breadcrumbs1 && ' / '}
        {props.breadcrumbs2 && props.breadcrumbs2}
        {props.breadcrumbs2 && ' / '}
        {props.breadcrumbs3 && props.breadcrumbs3}
        {props.breadcrumbs3 && ' / '}
        {label}
        {props.children}
      </>
    </span>
  );
};
