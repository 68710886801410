import './Quotations.scss';
import React from 'react';
import ListQuotations from '../../lists/Quotations/ListQuotations';
import { Head } from '../../../stories/dune/molecules/Head';
import { useTranslation } from 'react-i18next';

import Menu from '../../Menu';
import { useNavigate } from 'react-router-dom';

const Quotations = React.memo(() => {
  const { t } = useTranslation();

  const history = useNavigate();

  return (
    <div className='main-container'>
      <Menu selectedPage='quotations'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
      <div className='right detail-container'>
        <Head
          title={t('common.quotations')}
          back={false}
          Back={function (): void {
            history('/quotations');
          }}
        />
        <ListQuotations quotationType='Quotation' />
      </div>
    </div>
  );
});

Quotations.displayName = 'Quotations';
export default Quotations;
