import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './DetailVoyages.scss';
import moment from 'moment';
import Menu from './Menu';
import { ReactComponent as ArrowLeft } from '../assets/svg/arrow_left.svg';
import * as yup from 'yup';
import Header from './Header';
import Config from '../Config';
import { ToastTypes } from '../models/ToastTypes';
import useToast from '../hooks/use-toast';
import { Link } from 'react-scroll';
import Select, { createFilter } from 'react-select';
import Switch from 'react-switch';
import { ReactComponent as SvgPlus } from '../assets/svg/plus.svg';
import { ReactComponent as SvgCDown } from '../assets/svg/chevron_down.svg';
import { ReactComponent as SvgDownload } from '../assets/svg/download.svg';
import { ReactComponent as SvgDelete } from '../assets/svg/trash.svg';
import { Vehicle, BuildingSite, Carrier, Customer, Order, AdditionalDoc } from '@dune-manager/backend-core/dist/models';
import TripType, { GenericTripType } from '@dune-manager/backend-core/dist/models/trip-type';
import Popup from 'reactjs-popup';
import { popupContentStyle, popupOverlayStyle } from '../styles/popupContentStyle';
import { ZoneType } from '@dune-manager/backend-core/dist/models/zone';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import BottomBar from './BottomBar';
import { TripPriorityTranslate, TripStatusTranslate, TripStatusTranslateView } from '../models';
import fileDownload from 'js-file-download';
import { AdditionalDocEntity } from '@dune-manager/backend-core/dist/models/additional-doc';
import { customStylesSelectReactPopup } from '../styles/custom-styles-select-react';
import Trip, { TripTripProduct } from '../models/Trip';
import { useTranslation } from 'react-i18next';
import { Language } from '../i18n';
import countryList from './Countries';
import { ReactComponent as SvgCollapse } from '../assets/svg/chevron-up.svg';
import { ReactComponent as SvgOpen } from '../assets/svg/chevron-down.svg';
import AddVehicle, { ItemAddVehicle } from './AddVehicle';
import eventBus from '../utils/EventBus';
import { validate as uuidValidator } from 'uuid';
import WarningPopup from './WarningPopup';
import { SelectVehicle, SelectVehicleOption } from './forms/SelectVehicle';
import { SelectBuildingSite } from './forms/SelectBuildingSite';
import { Input } from '../stories/dune/atoms/Input';
import PopupContainer from '../stories/dune/organisms/PopupContainer';
import { TripStatus } from '@dune-manager/backend-core/dist/models/trip';
import { TooltipIcon } from '../stories/dune/molecules/TooltipIcon';
import { FormRow } from '../stories/dune/atoms/FormRow';
import { Title } from '../stories/dune/atoms/Title';
import { ProductItems } from './forms/ProductItems';
import { ProductItemRow, productItemsActions } from '../store/productItems-slice';
import { useDispatch, useSelector } from 'react-redux';
import store from '../store';
import { StaticDataState } from '../store/staticData-slice';
import { DocTypes } from '../models/AdditionalDocType';
import { GlobalParameter } from '../models/GlobalParameter';
import { ProductWithOrderProduct } from '../models/Order';
import { ProductType } from '../models/ProductType';

const DetailVoyages = React.memo(() => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem('language') ?? Language.FR;

  const { addToast, addFixedToast } = useToast();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    tripDate: yup.date().default(new Date()).required('La date de début est obligatoire'),
    tripType: yup.object().shape({
      value: yup.string().required('Le type de voyage est obligatoire'),
      label: yup.string().required('Le type de voyage est obligatoire'),
    }),

    status: yup.object().shape({
      value: yup.string().required('Le status du voyage est obligatoire'),
      label: yup.string().required('Le status de voyage est obligatoire'),
    }),

    maxWeightOut: yup
      .number()
      .min(0, 'Le poids maximum ne peut pas être négatif')
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    weightin: yup
      .number()
      .min(0, "Le poids d'entrée ne peut pas etre negatif")
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    weightOut: yup
      .number()
      .min(0, 'Le poids de sortie ne peut pas etre negatif')
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),

    client: yup.object().shape({
      value: yup.string().nullable().required('Le client est obligatoire'),
      label: yup.string().nullable().required('Le client est obligatoire'),
    }),

    priority: yup.object().shape({
      value: yup.string().required('La priorité est obligatoire'),
      label: yup.string().required('La priorité est obligatoire'),
    }),
  });

  interface IFormInputs {
    tripType: {
      value: string;
      label: string;
    };
    tripDate: Date;
    orderId: string;
    Comande: {
      value: string;
      label: string;
    };
    status: {
      value: string;
      label: string;
    };
    enteredAt: Date | null;
    exitedAt: Date | null;
    customerOrderNumber: string;
    externalReference: string;
    tripComment: string;
    tripDeliveryComment: string;
    Carrier: {
      value: string;
      label: string;
    };
    tvaNumber: string;
    Vehicle: {
      value: string;
      label: string;
    };
    trailerPlate: string;
    ptacMax: number;
    tareWeight: number;
    maxWeightOut: number | null;
    weightIn: number | null;
    weightOut: number | null;
    zoneA: {
      value: string;
      label: string;
    };
    terminalIn: string;
    terminalOut: string;
    currentZone: string;
    zoneD: {
      value: string;
      label: string;
    };
    client: {
      value: string | null;
      label: string | null;
    };
    chantier: {
      value: string;
      label: string;
    };

    // DataCustom
    tare: number;
    numero: string;
    numeroPlomb: string;
    neededInvoice: boolean;
    neededAnalysis: boolean;

    mustReturnBeforeLeaving: boolean;
    priority: {
      value: string;
      label: string;
    };
    carrierStreetAddress: string | undefined;
    carrierPostalCode: string | undefined;
    carrierLocality: string | undefined;
    carrierCountry: string | undefined;
  }

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
  };

  const { id, mode } = useParams<Params>();

  // **** DataCustom ******
  const [paramTripDataCustom, setparamTripDataCustom] = useState('');
  const [globalParameters, setGlobalParameters] = useState('');
  const [sendAlertIfOrderTripCountOverEstimation, setSendAlertIfOrderTripCountOverEstimation] = useState(false);
  // ***********************

  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('token');
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [isLoadingCarriers, setIsLoadingCarriers] = useState(false);
  const [isLoadingVehicles, setIsLoadingVehicles] = useState(false);
  const [isLoadingZonesA, setIsLoadingZonesA] = useState(false);
  const [isLoadingZonesD, setIsLoadingZonesD] = useState(false);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [isLoadingChantiers, setIsLoadingChantiers] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpenConfirmDeleteFile, setIsOpenConfirmDeleteFile] = useState(false);
  const [currentFileToDelete, setCurrentFileToDelete] = useState<AdditionalDoc>();
  const [products, setProducts] = useState<string[]>([]);
  const [tripDataCutom, setTripDataCutom] = useState<TripDataCustom>({});

  // TRIP
  const [vy, setVy] = useState<Trip>();
  const [tripDate, settripDate] = useState<Date>(new Date());

  const [tripNumber, setTripNumber] = useState('');
  const [optionsOrder, setOptionsOrder] = useState<any[]>([]);
  const [selectedOptionsOrder, setSelectedOptionsOrder] = useState<any>();
  const [optionsTripType, setOptionsTripType] = useState<any[]>([]);
  const [selectedOptionTripType, setSelectedOptionTripType] = useState<any>();
  const [optionstripStatus, setOptionsTripStatus] = useState<any>();
  const [selectedOptiontripStatus, setSelectedOptionTripStatus] = useState<any>();
  const [customerOrderNumber, setCustomerOrderNumber] = useState('');
  const [externalReference, setexternalReference] = useState('');
  const [tripComment, setTripComment] = useState('');
  const [tripDeliveryComment, settripDeliveryComment] = useState('');
  const [maxWeightOut, setmaxWeightOut] = useState<number>();
  const [weightIn, setweightIn] = useState<number>();
  const [dsdIn, setDsdIn] = useState<string>();
  const [dsdOut, setDsdOut] = useState<string>();
  const [weightOut, setweightOut] = useState<number>();
  const [mustReturnBeforeLeaving, setmustReturnBeforeLeaving] = useState<boolean>();
  const [hasBeenRefused, setHasBeenRefused] = useState<boolean>();
  const [optionsPriority, setOptionsPriority] = useState<any[]>();
  const [selectedOptionsPriority, setSelectedOptionsPriority] = useState<any>();

  const [estimatedTripCount, setEstimatedTripCount] = useState<number>(1);
  const [currentTripCount, setCurrentTripCount] = useState<number>(1);
  const [isOpenTripCountWarning, setIsOpenTripCountWarning] = useState<boolean>(false);

  // Carrier Adresse
  const [carrierPostalCode, setCarrierPostalCode] = useState('');
  const [carrierLocality, setCarrierLocality] = useState('');
  const [carrierStreetAddress, setCarrierStreetAddress] = useState('');
  const [selectedOptionCarrierCountry, setSelectedOptionCarrierCountry] = useState<any>(undefined);
  const [optionsCountry, setOptionsCountry] = useState<any>();
  const [hiddenCollapseDetailCarrier, setHiddenCollapseDetailCarrier] = useState(true);
  const [hiddenCollapseDetailSiteMovement, setHiddenCollapseDetailSiteMovement] = useState(true);

  const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);

  // TRACKING
  const [enteredAt, setenteredAt] = useState<Date | null>(null);
  const [exitedAt, setexitedAt] = useState<Date | null>(null);

  // CARRIER
  const [optionsCarrier, setOptionsCarrier] = useState<any[]>([]);
  const [selectedOptionsCarrier, setSelectedOptionsCarrier] = useState<any>();
  const [tvaNumber, settvaNumber] = useState('');
  const [queryCarrier, setQueryCarrier] = useState('');

  // VEHICLE
  const [optionsVehicle, setOptionsVehicle] = useState<any[]>([]);
  const [selectedOptionsVehicle, setSelectedOptionsVehicle] = useState<any>();
  const [queryVehicle, setQueryVehicle] = useState('');
  const [trailerPlate, settrailerPlate] = useState('');

  const [isOpenAddVehiclePopup, setIsOpenAddVehiclePopup] = useState(false);

  const [vehicleOption, setVehicleOption] = useState<SelectVehicleOption | null>(null);

  // VEHICLE-INFO
  const [ptacMax, setptacMax] = useState<number>();
  const [tareWeight, settareWeight] = useState<number>();

  // ZONES
  const [optionsZoneA, setOptionsZoneA] = useState<any[]>([]);
  const [selectedOptionsZoneA, setSelectedOptionsZoneA] = useState<any>();
  const [currentZone, setcurrentZone] = useState('');
  const [optionsZoneD, setOptionsZoneD] = useState<any[]>([]);
  const [selectedOptionsZoneD, setSelectedOptionsZoneD] = useState<any>();

  // TERMINAL
  const [terminalIn, setterminalIn] = useState('');
  const [terminalOut, setterminalOut] = useState('');

  // CLIENT
  const [optionsCustomer, setOptionsCustomer] = useState<any[]>([]);
  const [queryCustomer, setQueryCustomer] = useState('');
  const [selectedOptionsCustomer, setSelectedOptionsCustomer] = useState<any>();

  // BUILDING SITE
  const [optionsChantier, setOptionsChantier] = useState<any[]>([]);
  const [queryChantier, setQueryChantier] = useState('');
  const [selectedOptionsChantier, setSelectedOptionsChantier] = useState<any>();

  // FILE
  const inputFile = useRef<HTMLInputElement>(null);

  const [queryOrder, setQueryOrder] = useState('');

  const additionalDocTypes = useSelector(
    (state: { staticData: StaticDataState }) => state.staticData.additionalDocTypes,
  );

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const goBack = () => {
    history(-1);
  };

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  const SearchCompleteVehicleInfo = (vehicle: any) => {
    setSelectedOptionsVehicle(vehicle);

    setErrorMessage('');

    if (vehicle !== '') {
      settareWeight(vehicle?.tareWeight ?? undefined);
      settrailerPlate((trailerPlate === '' ? undefined : trailerPlate) ?? vehicle?.trailerPlate ?? undefined);

      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const url = orgid + '/vehicle-info/get-by-registration-plate/' + vehicle.registrationPlate;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (res) => {
          if (res.data.content.items !== null) {
            setptacMax(res.data.content.items.ptacMax);
          } else {
            setptacMax(0);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code == 'ERR4010001') {
              history('/');
            }
          }

          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  };

  const GetCurrentTripCount = (orderId: string, estimatedCount = 0) => {
    if (sendAlertIfOrderTripCountOverEstimation && estimatedCount > 0) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const url = orgid + '/order/current-trip-count/' + orderId;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (res) => {
          setCurrentTripCount(res.data.content.currentTripCount);
          setIsOpenTripCountWarning(estimatedCount <= res.data.content.currentTripCount);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code == 'ERR4010001') {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  };

  const SearchCompleteComandeInfo = (commande: any) => {
    if (commande !== '') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const url = orgid + '/order/detail/' + commande.value;
      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (res) => {
          if (res.data.content.estimatedTripCount !== null) {
            setEstimatedTripCount(res.data.content.estimatedTripCount);
          } else {
            setEstimatedTripCount(0);
          }

          if (res.data.content !== null) {
            const tableProducts: any = [];
            const tableTripProducts: TripTripProduct[] = [];
            res.data.content.products?.map((product: ProductWithOrderProduct) => {
              tableProducts.push(product.id);
              const oTP = product.OrderProduct;
              if (res.data.content.estimatedTripCount > 0) {
                oTP.orderedQuantity = oTP.orderedQuantity / res.data.content.estimatedTripCount;
              } else oTP.orderedQuantity = 0;
              oTP.deliveredQuantity = 0;

              tableTripProducts.push({
                id: product.id,
                label: product.label,
                unit: product.unit,
                weightByUnit: product.weightByUnit,
                taricCode: product.taricCode,
                externalReference: product.externalReference,
                multiProductPriority: product.multiProductPriority,
                productTypeId: product.productTypeId,
                TripProduct: oTP,
              });
            });
            setProducts(tableProducts);
            setProductItemRows(tableTripProducts);

            // set trip type if not set
            const indexMainProduct = tableTripProducts.findIndex((x) => x.TripProduct.mainProduct === true);
            if (indexMainProduct !== -1) {
              const types = JSON.parse(localStorage.getItem('productTypes') ?? '[]') as ProductType[];
              const productType: ProductType | undefined = types.find(
                (x) => x.id === tableTripProducts[indexMainProduct].productTypeId,
              );

              if (productType !== undefined) {
                const tripTypeIdToSet =
                  productType.loadingTripTypeId && !productType.unloadingTripTypeId
                    ? productType.loadingTripTypeId
                    : !productType.loadingTripTypeId && productType.unloadingTripTypeId
                    ? productType.unloadingTripTypeId
                    : undefined;
                if (tripTypeIdToSet !== undefined) {
                  const tripType = optionsTripType.find((x) => x.value === tripTypeIdToSet);
                  if (tripType !== undefined) {
                    updateTripType(tripType);
                  }
                }
              }

              setCustomerOrderNumber(res.data.content.customerOrderNumber);
              setTripComment(res.data.content.orderComment);

              updateCustomer({
                value: res.data.content.customer?.id,
                label: res.data.content.customer?.label,
              });

              updateChantier({
                value: res.data.content.buildingSite?.id,
                label: res.data.content.buildingSite?.label,
              });

              setCarriersOptions(res.data?.content?.carriers ?? []);

              if ((res.data?.content?.carriers ?? null) !== null && res.data?.content?.carriers.length === 1) {
                updateCarrier({
                  value: res.data?.content?.carriers[0].id,
                  label: res.data?.content?.carriers[0].label,
                  tva: res.data?.content?.carriers[0].tvaNumber,
                  streetAddress: res.data?.content?.carriers[0].address?.streetAddress ?? '',
                  postalCode: res.data?.content?.carriers[0].address?.postalCode ?? '',
                  locality: res.data?.content?.carriers[0].address?.locality ?? '',
                  country: res.data?.content?.carriers[0].address?.country ?? undefined,
                });
              }

              setOptionsVehicle(
                (res.data?.content?.vehicles ?? []).map((vehicle: any) => {
                  return {
                    value: vehicle.id,
                    label: vehicle.label,
                    tare: vehicle.tareWeight,
                    trailerPlate: vehicle.trailerPlate,
                  };
                }),
              );
              if (res.data.content.vehicles && res.data.content.vehicles.length === 1) {
                setSelectedOptionsVehicle({
                  value: res.data.content.vehicles[0].id,
                  label: res.data.content.vehicles[0].label,
                  tare: res.data.content.vehicles[0].tareWeight,
                  trailerPlate: res.data.content.vehicles[0].trailerPlate,
                });
              }
            }

            GetCurrentTripCount(commande.value, res.data?.content?.estimatedTripCount ?? 0);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code == 'ERR4010001') {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  };

  // ******************** TRIP **********************

  const updateexternalReference = (externalReference: string) => {
    setexternalReference(externalReference);
    setErrorMessage('');
  };

  const updatetripDate = (tripDate: Date) => {
    setValue('tripDate', tripDate);
    settripDate(tripDate);
    setErrorMessage('');
  };

  const updateTripType = (item: any) => {
    setValue('tripType', item);
    setSelectedOptionTripType(item);
    setErrorMessage('');
  };

  const updateCommande = (selectedOption: any) => {
    setSelectedOptionsOrder(selectedOption);
    SearchCompleteComandeInfo(selectedOption);
  };

  const updateCommandeSearch = (selectedOption: any) => {
    setSelectedOptionsOrder(selectedOption);
    setErrorMessage('');
  };

  const deleteSelectedOptionOrder = () => {
    setSelectedOptionsOrder(null);
  };

  const updateStatus = (status: any) => {
    setValue('status', status);
    setSelectedOptionTripStatus({
      id: status.id,
      value: status.value,
      label: status.label,
    });
    setErrorMessage('');
  };

  const updatecustomerOrderNumber = (customerOrderNumber: string) => {
    setCustomerOrderNumber(customerOrderNumber);
    setErrorMessage('');
  };

  const updatetripComment = (tripComment: string) => {
    setTripComment(tripComment);
    setErrorMessage('');
  };

  const updatetripDeliveryComment = (tripDeliveryComment: string) => {
    settripDeliveryComment(tripDeliveryComment);
    setErrorMessage('');
  };

  const updatemaxWeightOut = (maxWeightOut: number | undefined) => {
    setValue('maxWeightOut', maxWeightOut ?? null);
    setmaxWeightOut(maxWeightOut);
    setErrorMessage('');
  };

  const updateWeightIn = (newWeightIn: number | undefined) => {
    if (weightIn !== newWeightIn) {
      setValue('weightIn', newWeightIn ?? null);
      setweightIn(newWeightIn);
      setDsdIn(undefined);
      setErrorMessage('');
    }
  };

  const updateWeightOut = (newWeightOut: number | undefined) => {
    if (weightOut !== newWeightOut) {
      setValue('weightOut', newWeightOut ?? null);
      setweightOut(newWeightOut);
      setDsdOut(undefined);
      setErrorMessage('');
    }
  };

  const updatePriority = (priority: any) => {
    const selectedOption = {
      id: priority.id,
      value: priority.value,
      label: priority.label,
    };

    setValue('priority', priority);
    setSelectedOptionsPriority(selectedOption);
    setErrorMessage('');
  };

  // ******************* CARRIER **********************

  const updateCarrier = (newCarrier: any) => {
    setSelectedOptionsCarrier(newCarrier);
    updatetvaNumber(newCarrier.tva ?? '');
    if ((newCarrier.country ?? null) !== null) {
      updateCarrierCountry({ value: newCarrier.country, label: countryList[newCarrier.country] });
    } else {
      updateCarrierCountry({ value: undefined, label: undefined });
    }

    updateCarrierLocality(newCarrier.locality ?? '');
    updateCarrierPostalCode(newCarrier.postalCode ?? '');
    updateCarrierStreetAddress(newCarrier.streetAddress ?? '');
    setErrorMessage('');
  };

  const deleteSelectedOptionCarrier = () => {
    setSelectedOptionsCarrier(null);
  };

  const updateCarrierStreetAddress = (streetAddress: string) => {
    setCarrierStreetAddress(streetAddress);
    setErrorMessage('');
  };

  const updateCarrierPostalCode = (postalCode: string) => {
    setCarrierPostalCode(postalCode);
    setErrorMessage('');
  };

  const updateCarrierCountry = (country: any) => {
    setSelectedOptionCarrierCountry(country);
    setErrorMessage('');
  };

  const updateCarrierLocality = (locality: string) => {
    setCarrierLocality(locality);
    setErrorMessage('');
  };

  const updatetvaNumber = (tvanumber: string) => {
    settvaNumber(tvanumber);
    setErrorMessage('');
  };

  const toggleCollapseDetailCarrier = () => {
    setHiddenCollapseDetailCarrier(!hiddenCollapseDetailCarrier);
  };

  const toggleCollapseDetailSiteMovement = () => {
    setHiddenCollapseDetailSiteMovement(!hiddenCollapseDetailSiteMovement);
  };

  // *********************** VEHICLE **********************
  const updateVehicle = (newCarrier: any) => {
    setSelectedOptionsVehicle(newCarrier);
    setErrorMessage('');
  };

  const deleteSelectedOptionVehicle = () => {
    setSelectedOptionsVehicle(null);
  };

  const updatetrailerPlate = (trailerPlate: string) => {
    settrailerPlate(trailerPlate);
    setErrorMessage('');
  };

  const onSuccessAddVehicle = (item: ItemAddVehicle) => {
    // JMZ unused : see SearchCompleteVehicleInfo
    setIsOpenAddVehiclePopup(false);
    if ((item.vehicle?.registrationPlate ?? null) !== null && (item.vehicle?.id ?? null) !== null) {
      setOptionsVehicle([
        {
          label: item.vehicle?.registrationPlate as string,
          value: item.vehicle?.id as string,
          tare: undefined,
          trailerPlate: item.vehicle?.trailerPlate as string,
        },
      ]);
      setSelectedOptionsVehicle({
        label: item.vehicle?.registrationPlate as string,
        value: item.vehicle?.id as string,
        tare: undefined,
        trailerPlate: item.vehicle?.trailerPlate as string,
      });
      settrailerPlate((item.vehicle?.trailerPlate as string) ?? undefined);
    }
  };

  const CallbackChildDataVehicle = () => {
    setIsOpenAddVehiclePopup(false);
  };

  // ******************* VEHICLE-INFO ***********************
  const updatetareWeight = (tareWeight: number) => {
    settareWeight(tareWeight);
    setErrorMessage('');
  };

  // ******************** ZONE *******************************

  const updateZoneA = (newZone: any) => {
    setSelectedOptionsZoneA(newZone);
    setErrorMessage('');
  };

  const deleteSelectedOptionZone = () => {
    setSelectedOptionsZoneA(null);
  };

  const updatecurrentZone = (currentZone: string) => {
    setcurrentZone(currentZone);
    setErrorMessage('');
  };

  const updateZoneD = (newZone: any) => {
    setSelectedOptionsZoneD(newZone);
    setErrorMessage('');
  };

  const deleteSelectedOptionZoneD = () => {
    setSelectedOptionsZoneD(null);
  };

  // ******************** TERMINAL *******************
  const updateterminalIn = (terminalIn: string) => {
    setterminalIn(terminalIn);
    setErrorMessage('');
  };

  const updateterminalOut = (terminalOut: string) => {
    setterminalOut(terminalOut);
    setErrorMessage('');
  };

  // ******************** CUSTOMER **************************
  const updateCustomer = (customer: any) => {
    setValue('client', customer);
    setSelectedOptionsCustomer(customer);
    setErrorMessage('');
  };

  const deleteSelectedOptionCustomer = () => {
    setSelectedOptionsCustomer(null);
    setValue('client', { value: null, label: null });
  };

  // ******************** BUILDING SITE **************************
  const updateChantier = (chantier: any) => {
    setSelectedOptionsChantier(chantier);
    setErrorMessage('');
  };

  const deleteSelectedOptionChantier = () => {
    setSelectedOptionsChantier(null);
  };

  // ********************* CONTENEUR ***********************

  const switchDataCustom = (checked: boolean, event: any, id: string) => {
    updateCustomData(id, checked);
  };

  const updateCustomData = (key: string, value: any) => {
    if (key) {
      const newCustomData = { ...tripDataCutom };
      newCustomData[key] = value;
      setTripDataCutom(newCustomData);
    }
  };

  const onSubmit = () => {
    setIsAddingLoading(true);
    setDisabledSaveBtn(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/trip/add';
    if (mode == 'update') baseUrl = orgid + '/trip/edit/' + id;

    // construct Objet product from tripProducts to send
    const sentProducts: any = [];
    const productItems = store.getState().productItems;

    productItems?.products?.map((product: ProductItemRow) => {
      sentProducts.push({
        productId: product.productId,
        orderedQuantity: product.orderedQuantity ?? 0,
        deliveredQuantity: product.deliveredQuantity ?? 0,
        tripProductExternalReference: (product.externalReference ?? '') === '' ? undefined : product.externalReference,
        tripProductComment: (product.comment ?? '') === '' ? undefined : product.comment,
        mainProduct: product.mainProduct ?? false,
        unitPrice: product.itemUnitPrice,
        vatRateId: product.vatRateId,
        repValue: product.repValue,
        tgapValue: product.tgapValue,
        invoiceId: product.invoiceId,
      });
    });

    const dataToSend: EditTrip = {
      tripDate: tripDate,
      tripTypeId: (selectedOptionTripType ?? null) === null ? undefined : selectedOptionTripType.value,
      sendToSxd: false,
      products: sentProducts,
      vehicleId: (selectedOptionsVehicle ?? null) === null ? null : selectedOptionsVehicle.id,
      customerId: (selectedOptionsCustomer ?? null) === null ? null : selectedOptionsCustomer.value,
      buildingSiteId: (selectedOptionsChantier ?? null) === null ? null : selectedOptionsChantier.value,
      externalReference: externalReference === '' ? undefined : externalReference,
      trailerPlate: trailerPlate === '' ? undefined : trailerPlate,
      tripComment: tripComment === '' ? undefined : tripComment,
      tripDeliveryComment: tripDeliveryComment === '' ? undefined : tripDeliveryComment,
      customerOrderNumber: customerOrderNumber === '' ? undefined : customerOrderNumber,
      orderId: (selectedOptionsOrder ?? null) === null ? undefined : selectedOptionsOrder.value,
      carrierId: (selectedOptionsCarrier ?? null) === null ? null : selectedOptionsCarrier.value,
      maxWeightOut: maxWeightOut,
      weightIn: weightIn,
      dsdIn: dsdIn,
      weightOut: weightOut,
      dsdOut: dsdOut,
      destinationZoneId: (selectedOptionsZoneD ?? null) === null ? null : selectedOptionsZoneD.value,
      processingZoneId: (selectedOptionsZoneA ?? null) === null ? null : selectedOptionsZoneA.value,
      status: (selectedOptiontripStatus ?? null) === null ? undefined : selectedOptiontripStatus.value,
      dataCustom: (tripDataCutom ?? null) !== null ? tripDataCutom : undefined,
      mustReturnBeforeLeaving: mustReturnBeforeLeaving,
      tripPriority: (selectedOptionsPriority ?? null) === null ? undefined : selectedOptionsPriority.value,
      carrierTvaNumber: (tvaNumber ?? null) === null || tvaNumber === '' ? undefined : tvaNumber,
      carrierStreetAddress:
        (carrierStreetAddress ?? null) === null || carrierStreetAddress === '' ? undefined : carrierStreetAddress,
      carrierPostalCode:
        (carrierPostalCode ?? null) === null || carrierPostalCode === '' ? undefined : carrierPostalCode,
      carrierLocality: (carrierLocality ?? null) === null || carrierLocality === '' ? undefined : carrierLocality,
      carrierCountry: selectedOptionCarrierCountry?.value === '' ? undefined : selectedOptionCarrierCountry?.value,
      hasBeenRefused: selectedOptiontripStatus.value === TripStatus.REFUSED ? true : hasBeenRefused ?? false,
    };

    axios
      .post(Config.getApiExtranetUrl(baseUrl), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsAddingLoading(false);
        if (mode == 'update') {
          addToast(t('common.tripUpdated'), ToastTypes.success);
          history('/trips');
        } else {
          addToast(t('common.tripCreated'), ToastTypes.success);
          history('/trips');
        }
        setDisabledSaveBtn(false);
      })
      .catch((error) => {
        setIsAddingLoading(false);
        setDisabledSaveBtn(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const fetchZoneA = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = orgid + '/zone/get?page=' + 1 + '&limit=' + 100;

    const zones: any[] = [];

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { isActive: true, zoneType: ZoneType.PROCESS },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        if (res.data.content.items && res.data.content.items.length > 0) {
          for (const item of res.data.content.items) {
            zones.push({ label: item.label, value: item.id });
          }
          setOptionsZoneA(zones);
        } else setOptionsZoneA([]);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          'Erreur lors de la récuperation des zones. code : ' +
            (error?.response?.data?.code ?? error?.response?.status),
          ToastTypes.error,
        );
      });
  };

  const fetchZoneD = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = orgid + '/zone/get?page=' + 1 + '&limit=' + 100;

    const zones: any[] = [];

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { isActive: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        if ((res.data.content.items ?? null) !== null && res.data.content.items.length > 0) {
          for (const item of res.data.content.items) {
            zones.push({ label: item.label, value: item.id });
          }
          setOptionsZoneD(zones);
        } else setOptionsZoneD([]);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          'Erreur lors de la récupération des zones. code : ' +
            (error?.response?.data?.code ?? error?.response?.status),
          ToastTypes.error,
        );
      });
  };

  const fetchOrders = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoadingOrders(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/order/get-active';
      const url = orgid + `${baseUrl}?page=${1}&limit=${200}&search=${filterText}`;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoadingOrders(false);
          setOrdersOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoadingOrders(false);
          setOrdersOptions([]);
        });
    } else {
      setOrdersOptions([]);
    }
  };

  const fetchChantier = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoadingChantiers(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/building-site/get';
      const url = orgid + `${baseUrl}?onlyActive=true&page=${1}&limit=${200}&search=${filterText}`;

      const dataToSend: SearchBS = {
        customerId: (selectedOptionsCustomer ?? null) === null ? undefined : selectedOptionsCustomer.value,
      };

      axios
        .post(Config.getApiExtranetUrl(url), dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoadingChantiers(false);
          setChantiersOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoadingChantiers(false);
          setChantiersOptions([]);
        });
    } else {
      setChantiersOptions([]);
    }
  };

  const setChantiersOptions = (chantiers: BuildingSite[]) => {
    const options: any[] = [];

    if (chantiers != null) {
      chantiers.forEach((s) => {
        const option = {
          value: s.id,
          label: s.label,
        };

        options.push(option);
      });

      setOptionsChantier(options);
    }
  };

  const fetchCustomer = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoadingCustomers(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/customer/get';
      const url = orgid + `${baseUrl}?onlyActive=true&page=${1}&limit=${200}&search=${filterText}`;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoadingCustomers(false);
          setCustomersOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoadingCustomers(false);
          setCustomersOptions([]);
        });
    } else {
      setCustomersOptions([]);
    }
  };

  const setCustomersOptions = (customers: Customer[]) => {
    const options: any[] = [];

    if (customers != null) {
      customers.forEach((s) => {
        const option = {
          value: s.id,
          label: s.label,
        };

        options.push(option);
      });

      setOptionsCustomer(options);
    }
  };

  const fetchCarriers = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoadingCarriers(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/carrier/get';
      const url = orgid + `${baseUrl}?onlyActive=true&page=${1}&limit=${200}&search=${filterText}`;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoadingCarriers(false);
          setCarriersOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoadingCarriers(false);
          setCarriersOptions([]);
        });
    } else {
      setCarriersOptions([]);
    }
  };

  const setCarriersOptions = (carriers: Carrier[]) => {
    const options: any[] = [];

    if (carriers != null) {
      carriers.forEach((s) => {
        const option = {
          value: s.id,
          label: s.label,
          tva: s.tvaNumber,
          streetAddress: s?.address?.streetAddress ?? '',
          postalCode: s?.address?.postalCode ?? '',
          locality: s?.address?.locality ?? '',
          country: s?.address?.country ?? undefined,
        };
        options.push(option);
      });

      setOptionsCarrier(options);
    }
  };

  const setOrdersOptions = (orders: Order[]) => {
    const options: any[] = [];

    if ((orders ?? null) !== null && orders.length > 0) {
      orders.forEach((s) => {
        const option = {
          value: s.id,
          label: `${s.externalReference ?? ''}${s.externalReference && s.customerOrderNumber ? ' - ' : ''}${
            s.customerOrderNumber ?? ''
          }`,
          custord: s.customerOrderNumber,
          bsid: s.buildingSite?.id,
          bslabel: s.buildingSite?.label,
          custid: s.customer?.id,
          custlabel: s.customer?.label,
        };
        options.push(option);
      });

      setOptionsOrder(options);
    }
  };

  const getCurrentTrip = () => {
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/trip/detail/';
    const url = Config.getApiExtranetUrl(baseUrl + id);

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        if ((res.data.content ?? null) !== null) {
          setVy(res.data.content);

          if ((res.data.content.order?.id ?? null) !== null) {
            updateCommandeSearch({
              value: res.data.content.order?.id,
              label: `${res.data.content.order?.externalReference ?? ''}${
                res.data.content.order?.externalReference && res.data.content.order?.customerOrderNumber ? ' - ' : ''
              }${res.data.content.order?.customerOrderNumber ?? ''}`,
            });
          }
          if ((res.data.content.tripType?.id ?? null) !== null) {
            updateTripType({
              value: res.data.content.tripType?.id,
              label: res.data.content.tripType?.label,
            });
          }

          setexternalReference(vy?.externalReference ?? '');
          setTripNumber(vy?.tripNumber ?? '');
          settripDate(res.data.content.tripDate ?? new Date());
          setenteredAt(res.data.content.tracking?.enteredAt ?? null);
          setexitedAt(res.data.content.tracking?.exitedAt ?? null);
          setCustomerOrderNumber(vy?.customerOrderNumber ?? '');
          setTripComment(vy?.tripComment ?? '');
          settripDeliveryComment(vy?.tripDeliveryComment ?? '');
          settrailerPlate(vy?.trailerPlate ?? '');

          if ((res.data.content.vehicle?.id ?? null) !== null) {
            updateVehicle({
              value: vy?.vehicle?.id,
              label: vy?.vehicle?.registrationPlate,
            });
            if (vy && vy.vehicle)
              setVehicleOption({ value: vy.vehicle.id, label: vy.vehicle.registrationPlate, data: vy.vehicle });
          }

          if ((res.data.content.carrier?.id ?? null) !== null) {
            updateCarrier({
              value: vy?.carrier?.id,
              label: vy?.carrier?.label,
              tva: vy?.carrier?.tvaNumber,
              streetAddress: vy?.carrier?.address?.streetAddress ?? '',
              postalCode: vy?.carrier?.address?.postalCode ?? '',
              locality: vy?.carrier?.address?.locality ?? '',
              country: vy?.carrier?.address?.country ?? undefined,
            });
          }

          setptacMax(res.data.content.vehicleInfo?.ptacMax ?? undefined);
          settareWeight(vy?.vehicle?.tareWeight ?? undefined);
          setmaxWeightOut(vy?.maxWeightOut ?? undefined);
          setweightIn(vy?.weightIn ?? undefined);
          setDsdIn(vy?.dsdIn ?? undefined);
          setweightOut(vy?.weightOut ?? undefined);
          setDsdOut(vy?.dsdOut ?? undefined);
          settvaNumber(vy?.carrier?.tvaNumber ?? '');

          setmustReturnBeforeLeaving(vy?.mustReturnBeforeLeaving ?? false);
          setHasBeenRefused(vy?.hasBeenRefused ?? false);

          if ((res.data.content.customer?.id ?? null) !== null) {
            updateCustomer({
              value: res.data.content.customer?.id,
              label: res.data.content.customer?.label,
            });
          }

          if ((res.data.content.buildingSite?.id ?? null) !== null) {
            updateChantier({
              value: res.data.content.buildingSite?.id,
              label: res.data.content.buildingSite?.label,
            });
          }

          if ((res.data.content.processingZone?.id ?? null) !== null) {
            updateZoneA({
              value: res.data.content.processingZone?.id,
              label: res.data.content.processingZone?.label,
            });
          }

          if ((res.data.content.tracking?.destinationZone?.id ?? null) !== null) {
            updateZoneD({
              value: res.data.content.tracking?.destinationZone?.id,
              label: res.data.content.tracking?.destinationZone?.label,
            });
          }

          setterminalIn(res.data.content.tracking?.terminalIn?.label ?? '');
          setterminalOut(res.data.content.tracking?.terminalOut?.label ?? '');
          setcurrentZone(res.data.content.tracking?.currentZone?.label ?? '');

          // Init tableProducts avec contenu retourné de la BD
          const tableProducts: any = [];
          const tableTripProducts: any = [];
          vy?.products?.map((product: any) => {
            tableProducts.push(product.id);
            tableTripProducts.push({
              id: product.id,
              label: product.label,
              unit: product.unit,
              weightByUnit: product.weightByUnit,
              taricCode: product.taricCode,
              externalReference: product.externalReference,
              multiProductPriority: product.multiProductPriority,
              TripProduct: product.TripProduct,
            });
          });
          setProducts(tableProducts);
          setProductItemRows(tableTripProducts);
          // fin Init tableProducts

          // set DataCustom
          setTripDataCutom(res.data.content.dataCustom);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        if ((id ?? null) !== null) {
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        }
      });
  };

  const onChangeSwitch = (checked: boolean) => {
    setmustReturnBeforeLeaving(checked);
  };

  // Constructor DataCustom
  // ******* big Problemme : passe 2 fois par la.. !!! ******
  const buildDataCustom = (objDataCustom: any) => {
    const containers: HTMLDivElement[] = [];
    let containerRows: HTMLDivElement[] = [];

    if ((objDataCustom ?? null) === null || objDataCustom === 'null') {
      return;
    }

    objDataCustom = JSON.parse(objDataCustom as string);
    if ((objDataCustom ?? null) !== null && objDataCustom !== 'null') {
      for (let index = 0; index < objDataCustom.length; index++) {
        const tripTypeDataCustom = objDataCustom[index];
        containers.push(
          (
            <div className='section-title'>
              <h3 className='title1SemiBold'>{tripTypeDataCustom.label[currentLanguage]}</h3>
            </div>
          ) as unknown as HTMLDivElement,
        );

        for (let indexFields = 0; indexFields < tripTypeDataCustom.fields.length; indexFields++) {
          const tripFieldsDataCustom = tripTypeDataCustom.fields[indexFields];

          switch (tripFieldsDataCustom.type) {
            case 'text':
              containerRows.push(
                (
                  <div className='detail-items-row' title={tripFieldsDataCustom.description[currentLanguage]}>
                    <Input
                      {...register(tripFieldsDataCustom.code)}
                      error={''}
                      label={tripFieldsDataCustom.label[currentLanguage]}
                      hoverDescription={tripFieldsDataCustom.description[currentLanguage]}
                      type='text'
                      value={
                        tripFieldsDataCustom.code && tripDataCutom
                          ? tripDataCutom[tripFieldsDataCustom.code] ?? undefined
                          : undefined
                      }
                      placeholder=''
                      disabled={false}
                      onChange={(e) => updateCustomData(tripFieldsDataCustom.code, e as string)}
                    />
                  </div>
                ) as unknown as HTMLDivElement,
              );

              break;
            case 'number':
              containerRows.push(
                (
                  <div className='detail-items-row' title={tripFieldsDataCustom.description[currentLanguage]}>
                    <Input
                      {...register(tripFieldsDataCustom.code)}
                      error={''}
                      label={tripFieldsDataCustom.label[currentLanguage]}
                      hoverDescription={tripFieldsDataCustom.description[currentLanguage]}
                      type='number'
                      value={
                        tripFieldsDataCustom.code && tripDataCutom
                          ? tripDataCutom[tripFieldsDataCustom.code] ?? undefined
                          : undefined
                      }
                      placeholder=''
                      disabled={false}
                      onChange={(e) => updateCustomData(tripFieldsDataCustom.code, parseFloat(e as string))}
                      prefix={'T'}
                      number={{ min: 0, max: 1000000, step: 1 }}
                    />
                  </div>
                ) as unknown as HTMLDivElement,
              );
              break;
            case 'checkbox':
              containerRows.push(
                (
                  <div className='detail-items-row' title={tripFieldsDataCustom.description[currentLanguage]}>
                    <div className='panelInput'>
                      <Switch
                        id={tripFieldsDataCustom.code ?? 'id'}
                        className='base2'
                        type='text'
                        checked={
                          tripFieldsDataCustom.code && tripDataCutom
                            ? tripDataCutom[tripFieldsDataCustom.code] ?? false
                            : false
                        }
                        onChange={switchDataCustom}
                        onColor={'#2a85ff'}
                      />

                      <h3 className='base2' style={{ margin: '0px 10px' }}>
                        {tripFieldsDataCustom.label[currentLanguage]}
                      </h3>
                    </div>
                  </div>
                ) as unknown as HTMLDivElement,
              );
              break;

            default:
              break;
          }

          if ((indexFields + 1) % 3 === 0) {
            containers.push((<div className='detail-row'>{containerRows}</div>) as unknown as HTMLDivElement);
            containerRows = [];
          }
        }
        containers.push((<div className='detail-row'>{containerRows}</div>) as unknown as HTMLDivElement);
        containerRows = [];
        containers.push((<hr className='divider' />) as unknown as HTMLDivElement);
      }
    }

    return containers;
  };

  const setOptions = () => {
    const optionsCountry: any[] = [];

    if (countryList != null) {
      for (const [key, value] of Object.entries(countryList)) {
        const option = {
          value: key,
          label: value,
        };

        optionsCountry.push(option);
      }
      setOptionsCountry(optionsCountry);
    }
  };

  const handleFileUpload = async (e: { target: { files: any } }) => {
    const { files } = e.target;
    if (files && files.length) {
      await onAddFileOk(files[0]); // object fichier
    }
  };

  const disabledSaveBtnEvent = () => {
    setDisabledSaveBtn(true);
  };

  const toBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file); // you could also read images and other binaries
      reader.onload = () => resolve((reader.result as string).split(',')[1]); // prendre le Base64
      reader.onerror = (error) => reject(error);
    });

  const onAddFileOk = async (doc: any) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const dataToSend: AddFile = {
      fileName: doc.name,
      additionalDocTypeId: '1baf5bec-064f-4d79-987a-ae4f3e7f40f4', // autre document - mettre id type document ** Il faudra faire par type **
      autoLinkToTrip: false,
      autoLinkAtTripStart: false,
      autoLinkAtTripEnd: false,
      displayAtTripStart: false,
      displayAtTripEnd: false,
      additionalDocEntity: AdditionalDocEntity.TRIP,
      entityId: vy?.id,
      origin: 'extranet',
      fileBody: await toBase64(doc),
    };

    const url = orgid + '/additional-doc/add';
    axios
      .post(Config.getApiExtranetUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async () => {
        getCurrentTrip();
        addToast('Fichier ajouté', ToastTypes.success);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  useEffect(() => {
    // ****************  Recuperer le DataCustom et le re-transformer en object pour les traiter
    setparamTripDataCustom(localStorage.getItem('paramTripDataCustom') as string); // get them back.
    setGlobalParameters(localStorage.getItem('globalParameters') as string);
    const globalParametersSite: any[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSendAlertIfOrderTripCountOverEstimation(
      globalParametersSite.find((x) => x.label === 'sendAlertIfOrderTripCountOverEstimation')?.value === 'true',
    );
    // *****************
    setOptions();
    fetchTripType();
    getCurrentTrip();
    fetchZoneA();
    fetchZoneD();
    setTripStatus(vy?.id ?? '');
    setTripPriority(vy?.id ?? '');
  }, [vy?.id]);

  useEffect(() => {
    const handleTripUpdateWithUuid = (data: any) => {
      if ((data?.detail?.message ?? null) !== null) {
        const splitedMessage = data.detail.message.split('¤');
        if (splitedMessage.length >= 2 && uuidValidator(splitedMessage[1]) && splitedMessage[1] === id) {
          addFixedToast(t('trip.updatedToastMessage'), ToastTypes.success);
          disabledSaveBtnEvent();
        }
      }
    };

    eventBus.on('update_trip_with_uuid', handleTripUpdateWithUuid);

    return () => {
      // cleanup events on unmount
      eventBus.remove('update_trip_with_uuid', handleTripUpdateWithUuid);
    };
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => (queryCarrier.length > 0 ? fetchCarriers(queryCarrier) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [queryCarrier]);

  useEffect(() => {
    const timeOutId = setTimeout(() => (queryCustomer.length > 0 ? fetchCustomer(queryCustomer) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [queryCustomer]);

  useEffect(() => {
    const timeOutId = setTimeout(() => (queryChantier.length > 0 ? fetchChantier(queryChantier) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [queryChantier]);

  useEffect(() => {
    const timeOutId = setTimeout(() => (queryVehicle.length > 0 ? fetchVehicles(queryVehicle) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [queryVehicle]);

  useEffect(() => {
    const timeOutId = setTimeout(() => (queryOrder.length > 0 ? fetchOrders(queryOrder) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [queryOrder]);

  const setProductItemRows = (triptripProducts: TripTripProduct[]) => {
    const productItemRows: ProductItemRow[] = [];
    triptripProducts?.map((x: TripTripProduct) => {
      productItemRows.push({
        id: x.TripProduct.id,
        productId: x.id ?? '',
        label: x.label ?? '',
        desc:
          (x.externalReference ?? '') +
          (x.TripProduct?.externalReference ? ' - ref. : ' + x.TripProduct?.externalReference : ''),
        comment: x.TripProduct.comment,
        quantityIsSum: false,
        externalReference: x.TripProduct.externalReference,
        unit: x.unit ?? 'ton',
        weightByUnit: x.weightByUnit,
        orderedQuantity: x.TripProduct.orderedQuantity,
        deliveredQuantity: x.TripProduct.deliveredQuantity,
        itemUnitPrice: x.TripProduct.unitPrice,
        vatRate: x.TripProduct.vatRate?.vatRate,
        vatRateId: x.TripProduct.vatRate?.id,
        repValue: x.TripProduct.repValue,
        tgapValue: x.TripProduct.tgapValue,
        mainProduct: x.TripProduct.mainProduct ?? false,
        enableTransportPrice: false,
        showTransportDetails: false,
        invoiceId: x.TripProduct.invoiceId,
        invoice: x.TripProduct.invoice,
      });
    });

    dispatch(productItemsActions.setProducts(productItemRows));
  };

  const setTripStatus = (idTrip: string) => {
    const optionsStatus: any[] = [];

    // Pour set la valeur
    for (const [key, value] of Object.entries(TripStatusTranslate)) {
      if (vy?.status === undefined) {
        if (key === 'Started') {
          setValue('status', { value: key, label: value });
          setSelectedOptionTripStatus({ value: key, label: value, id: idTrip });
        }
      } else {
        if (key === vy?.status) {
          setValue('status', { value: key, label: value });
          setSelectedOptionTripStatus({ value: key, label: value, id: idTrip });
        }
      }
    }

    // Pour remplir les options de la combo status
    for (const [key, value] of Object.entries(TripStatusTranslateView)) {
      optionsStatus.push({
        id: idTrip,
        value: key,
        label: value,
      });
    }
    setOptionsTripStatus(optionsStatus);
  };

  const setTripPriority = (idTrip: string) => {
    const optionsPriority: any[] = [];

    // Pour set la valeur
    for (const [key, value] of Object.entries(TripPriorityTranslate)) {
      if (vy?.tripPriority === undefined) {
        if (key === 'Medium') {
          setValue('priority', { value: key, label: value });
          setSelectedOptionsPriority({ value: key, label: value, id: idTrip });
        }
      } else {
        if (key === vy?.tripPriority) {
          setValue('priority', { value: key, label: value });
          setSelectedOptionsPriority({ value: key, label: value, id: idTrip });
        }
      }
    }

    // Pour remplir les options de la combo priority
    for (const [key, value] of Object.entries(TripPriorityTranslate)) {
      optionsPriority.push({
        id: idTrip,
        value: key,
        label: value,
      });
    }
    setOptionsPriority(optionsPriority);
  };

  const setTripOptions = (tripTypes: TripType[]) => {
    const optionsTripType: any[] = [];

    for (const tripType of tripTypes) {
      optionsTripType.push({
        value: tripType.id,
        label: tripType.label,
      });
    }

    setOptionsTripType(optionsTripType);
    if (tripTypes.length === 1) {
      setSelectedOptionTripType({
        value: tripTypes[0].id,
        label: tripTypes[0].label,
      });

      setValue('tripType', {
        value: tripTypes[0].id,
        label: tripTypes[0].label,
      });
    }
  };

  const fetchTripType = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = orgid + '/trip-type/get/';
    axios
      .post(
        Config.getApiExtranetUrl(url),
        { genericTripType: [GenericTripType.LOAD, GenericTripType.UNLOAD] },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        if ((res.data.content.items ?? null) !== null && res.data.content.items.length > 0) {
          setTripOptions(res.data.content.items);
        }
      })
      .catch(() => {
        setTripOptions([]);
      });
  };

  const fetchVehicles = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoadingVehicles(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/vehicle/get';
      const url = orgid + `${baseUrl}?page=${1}&limit=${200}&search=${filterText}`;

      const dataToSend: SearchVehicule = {
        isActive: true,
      };

      axios
        .post(Config.getApiExtranetUrl(url), dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoadingVehicles(false);
          if ((res.data.content.items ?? null) !== null && res.data.content.items.length > 0) {
            setVehiclesOptions(res.data.content.items);
          }

          if (res.data.content.items.length === 1) {
            setSelectedOptionsVehicle({
              label: res.data.content.items[0].registrationPlate,
              value: res.data.content.items[0].id,
              tare: res.data.content.items[0].tareWeight,
              trailerPlate: res.data.content.items[0].trailerPlate,
            });
          }
        })
        .catch(() => {
          setIsLoadingVehicles(false);
          setVehiclesOptions([]);
        });
    } else {
      setVehiclesOptions([]);
    }
  };

  const setVehiclesOptions = (vehicles: Vehicle[]) => {
    const options: any[] = [];

    if (vehicles != null) {
      vehicles.forEach((s) => {
        const option = {
          value: s.id,
          label: s.registrationPlate,
          tare: s.tareWeight,
          trailerPlate: s.trailerPlate,
        };

        options.push(option);
      });

      setOptionsVehicle(options);
    }
  };

  // FIN OPTIONS PRODUCT

  const filterConfig = {
    stringify: (option: any) => `${option.label}`,
  };

  // OPTIONS FILE
  const OpenAddFile = () => {
    inputFile?.current?.click();
    getCurrentTrip();
  };

  // Delete File
  const onClickDeleteDoc = (doc: any) => {
    setIsOpenConfirmDeleteFile(true);
    setCurrentFileToDelete(doc);
  };

  const onDeleteFileOk = (doc: any) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const dataToSend: DeleteFile = {
      entityId: vy?.id,
      additionalDocEntity: AdditionalDocEntity.TRIP,
    };

    const url = orgid + '/additional-doc' + `/${doc.id}/remove`;
    axios
      .post(Config.getApiExtranetUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        getCurrentTrip();
        addToast('Fichier supprimé', ToastTypes.success);
        setIsOpenConfirmDeleteFile(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const onClickDownloadDoc = (doc: any) => {
    downloadDoc(doc);
  };

  const downloadDoc = async (doc: any) => {
    const data = await dlOne(doc);
    fileDownload(data.data, doc.label);
    return data;
  };

  const dlOne = async (doc: any) => {
    const data = await axios({
      url: doc?.url, // your url
      method: 'GET',
      responseType: 'blob', // important
    });

    return data;
  };
  // FIN OPTIONS FILE

  return (
    <div className='detailVoyage'>
      <Menu selectedPage='trips'></Menu>
      <div className='right detail-container'>
        <div className='detail-header'>
          <div className='buttonContainer'>
            <div className='returnBtn' onClick={() => goBack()}>
              <ArrowLeft className='svg'></ArrowLeft>
            </div>
            <Header
              selectedPage={`${t('common.trip')} ${vy?.tripNumber ?? ''} ${vy?.vehicle?.registrationPlate ?? ''}`}
            ></Header>

            <div className='row right-btn'></div>
          </div>
        </div>

        {isLoading ? (
          <div style={{ marginTop: 30 }}>{t('common.loading')}</div>
        ) : (
          <div>
            <div className='detail-content'>
              <div className='left-tabs'>
                <div className='tabBtn'>
                  <Link
                    className='link base2'
                    to='general-tab'
                    activeClass='active'
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    {t('common.generalInformations')}
                  </Link>
                </div>
                <div className='tabBtn'>
                  <Link className='link base2' to='transporter-tab' spy={true} smooth={true} offset={10} duration={500}>
                    {t('common.shipment')}
                  </Link>
                </div>
                <div className='tabBtn'>
                  <Link className='link base2' to='products-tab' spy={true} smooth={true} offset={-70} duration={500}>
                    {t('menu.products')}
                  </Link>
                </div>
                <div className='tabBtn'>
                  <Link className='link base2' to='site-tab' spy={true} smooth={true} offset={-70} duration={500}>
                    {t('common.divers')}
                  </Link>
                </div>
                <div className='tabBtn'>
                  <Link className='link base2' to='file-tab' spy={true} smooth={true} offset={-70} duration={500}>
                    {t('common.documents')}
                  </Link>
                </div>
              </div>
              <div className='detail-form'>
                <form id='form' onSubmit={handleSubmit(onSubmit)}>
                  <div id='general-tab' className='detail-general'>
                    <div className='section-title'>
                      <div>
                        <h3 className='title1SemiBold'>{t('common.generalInformations')}</h3>
                      </div>
                    </div>
                    <div className='detail-row'>
                      {vy?.hasBeenRefused && (
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.hasBeenRefused')}</h3>
                          </div>
                          <div className='panelInput'>
                            <Switch
                              className='base2'
                              type='text'
                              checked={hasBeenRefused ?? false}
                              onChange={(e) => setHasBeenRefused(e)}
                              onColor={'#E8C9C9'}
                            />
                          </div>
                        </div>
                      )}
                      <div className='detail-items-row'>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.date')}</h3>
                        </div>
                        <div className='panelInput'>
                          <input
                            className='base2'
                            type='date'
                            {...register('tripDate')}
                            value={moment(tripDate)?.toISOString()?.substring(0, 10)}
                            placeholder='DD/MM/YY HH:mm'
                            onChange={(e) => updatetripDate(new Date(e?.target.value))}
                          />
                          <p className='validator-error'>{errors.tripDate?.message}</p>
                        </div>
                      </div>
                      <div className='detail-items-row'>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.order')}</h3>
                          <TooltipIcon icon={'question'} message={t('common.orderDesc')} />
                        </div>
                        <div className='panelInput'>
                          <Select
                            {...register('Comande')}
                            className='select-input base2'
                            isMulti={false}
                            styles={customStylesSelectReactPopup('#F4F4F4')}
                            isLoading={isLoadingOrders}
                            options={optionsOrder}
                            loadingMessage={() => 'Chargement en cours'}
                            noOptionsMessage={() => {
                              if (filterText.length < 1) {
                                return 'Entrez 1 caractère pour lancer une recherche';
                              } else {
                                return 'Aucun résultat';
                              }
                            }}
                            placeholder={t('common.orderChoose')}
                            onInputChange={(e) => setQueryOrder(e)}
                            isSearchable={true}
                            onChange={(input) => {
                              if (input != null && input.value != null) {
                                updateCommande(input);
                              } else {
                                deleteSelectedOptionOrder();
                              }
                            }}
                            value={selectedOptionsOrder ?? null}
                            filterOption={createFilter(filterConfig)}
                            isClearable={true}
                          />
                        </div>
                      </div>
                      <div className='detail-items-row'>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.type')}</h3>
                        </div>
                        <div className='panelInput'>
                          <Select
                            {...register('tripType')}
                            name='tripType'
                            className='select-input base2'
                            isMulti={false}
                            styles={customStylesSelectReactPopup('#F4F4F4')}
                            options={optionsTripType}
                            placeholder={t('common.tripType')}
                            onChange={(input) => {
                              input != null && input.value != null ? updateTripType(input) : null;
                            }}
                            value={selectedOptionTripType ?? null}
                            isDisabled={isDisabled}
                            filterOption={createFilter(filterConfig)}
                          />

                          <p className='validator-error'>
                            {errors.tripType?.label?.message ?? errors.tripType?.label?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='detail-row'>
                      <div className='detail-items-row'>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.status')}</h3>
                        </div>
                        <div className='panelInput'>
                          <Select
                            {...register('status')}
                            name='status'
                            className='select-input base2'
                            isMulti={false}
                            styles={customStylesSelectReactPopup('#F4F4F4')}
                            options={optionstripStatus}
                            placeholder={t('common.statusChoose')}
                            onChange={(input) => {
                              input != null && input.value != null ? updateStatus(input) : null;
                            }}
                            value={selectedOptiontripStatus ?? null}
                            isDisabled={isDisabled}
                            filterOption={createFilter(filterConfig)}
                          />

                          <p className='validator-error'>
                            {errors.status?.label?.message ?? errors.status?.label?.message}
                          </p>
                        </div>
                      </div>
                      <div className='detail-items-row' title={t('common.tripExternalReferenceDesc')}>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.tripExternalReference')}</h3>
                        </div>
                        <div className='panelInput'>
                          <input
                            className='base2'
                            type='string'
                            {...register('externalReference')}
                            value={externalReference ?? undefined}
                            onChange={(e) => updateexternalReference(e?.target.value)}
                          />
                          <p className='validator-error'>{errors.externalReference?.message}</p>
                        </div>
                      </div>
                      <div className='detail-items-row' title={t('common.returnToReceptionDesc')}>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.comeBackOnReceptionBeforeToExit')}</h3>
                        </div>
                        <div className='panelInput'>
                          <Switch
                            className='base2'
                            type='text'
                            checked={mustReturnBeforeLeaving ?? false}
                            onChange={onChangeSwitch}
                            onColor={'#2a85ff'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='detail-row'>
                      <div className='detail-items-row'>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.customer')}</h3>
                        </div>
                        <div className='panelInput'>
                          <Select
                            {...register('client', {})}
                            className='select-input base2'
                            isMulti={false}
                            styles={customStylesSelectReactPopup('#F4F4F4')}
                            isLoading={isLoadingCustomers}
                            options={optionsCustomer}
                            loadingMessage={() => 'Chargement en cours'}
                            noOptionsMessage={() =>
                              filterText.length < 1 ? 'Entrez 1 caractère pour lancer une recherche' : 'Pas de résultat'
                            }
                            placeholder={t('common.customerChoose')}
                            onInputChange={(e) => setQueryCustomer(e)}
                            isSearchable={true}
                            onChange={(input) => {
                              if (input != null && input.value != null) {
                                updateCustomer(input);
                              } else {
                                deleteSelectedOptionCustomer();
                              }
                            }}
                            value={selectedOptionsCustomer ?? null}
                            filterOption={createFilter(filterConfig)}
                            isClearable={true}
                          />
                          <p className='validator-error'>
                            {errors.client?.value?.message ?? errors.client?.label?.message}
                          </p>
                        </div>
                      </div>
                      <div className='detail-items-row'>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.buildingSite')}</h3>
                        </div>
                        <div className='panelInput'>
                          <SelectBuildingSite
                            titleSize='none'
                            register={register}
                            registerName='chantier'
                            setValue={setValue}
                            error={errors.chantier?.value?.message ?? errors.chantier?.label?.message ?? ''}
                            isSelectable={true}
                            selectedOptionChanged={(input) => {
                              if (input != null && input.value != null) {
                                updateChantier(input);
                              } else {
                                deleteSelectedOptionChantier();
                              }
                            }}
                            searchParent={selectedOptionsCustomer?.value ?? undefined}
                            forceSelectedOption={selectedOptionsChantier ?? undefined}
                          />
                        </div>
                      </div>
                      <div className='detail-items-row' title={t('common.customerOrderReferenceDesc')}>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.customerOrderReference')}</h3>
                        </div>
                        <div className='panelInput'>
                          <input
                            className='base2'
                            type='string'
                            {...register('customerOrderNumber')}
                            value={customerOrderNumber ?? undefined}
                            onChange={(e) => updatecustomerOrderNumber(e?.target.value)}
                          />
                          <p className='validator-error'>{errors.customerOrderNumber?.message}</p>
                        </div>
                      </div>
                    </div>
                    <div className='detail-row'>
                      <div className='detail-items-row' title={t('common.internalCommentDesc')}>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.internalComment')}</h3>
                        </div>
                        <div className='panelInputTextArea'>
                          <textarea
                            className='base2'
                            {...register('tripComment')}
                            value={tripComment ?? undefined}
                            onChange={(e) => updatetripComment(e?.target.value)}
                          />
                          <p className='validator-error'>{errors.tripComment?.message}</p>
                        </div>
                      </div>
                      <div className='detail-items-row' title={t('common.externalCommentDesc')}>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.externalComment')}</h3>
                        </div>
                        <div className='panelInputTextArea'>
                          <textarea
                            className='base2'
                            {...register('tripDeliveryComment')}
                            value={tripDeliveryComment ?? undefined}
                            onChange={(e) => updatetripDeliveryComment(e?.target.value)}
                          />
                          <p className='validator-error'>{errors.tripDeliveryComment?.message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className='divider' />
                  <div id='transporter-tab' className='detail-transporter'>
                    <div className='section-title'>
                      <div>
                        <h3 className='title1SemiBold'>{t('common.shipment')}</h3>
                      </div>
                    </div>
                    <div className='detail-row'>
                      <div className='detail-items-row'>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.carrier')}</h3>
                        </div>
                        <div className='panelInput'>
                          <Select
                            {...register('Carrier')}
                            className='select-input base2'
                            isMulti={false}
                            styles={customStylesSelectReactPopup('#F4F4F4')}
                            isLoading={isLoadingCarriers}
                            options={optionsCarrier}
                            loadingMessage={() => 'Chargement en cours'}
                            noOptionsMessage={() =>
                              filterText.length < 1 ? 'Entrez 1 caractère pour lancer une recherche' : 'Pas de résultat'
                            }
                            placeholder={t('common.carrierChoose')}
                            onInputChange={(e) => setQueryCarrier(e)}
                            isSearchable={true}
                            onChange={(input) => {
                              if (input != null && input.value != null) {
                                updateCarrier(input);
                              } else {
                                deleteSelectedOptionCarrier();
                              }
                            }}
                            value={selectedOptionsCarrier ?? null}
                            filterOption={createFilter(filterConfig)}
                            isClearable={true}
                          />
                          <p className='validator-error'>
                            {errors.Carrier?.value?.message ?? errors.Carrier?.label?.message}
                          </p>
                        </div>
                      </div>
                      <div className='detail-items-row'>
                        <div className='panelTitleText'>
                          <h3 className='base2'>{t('common.siretAndTvaNumber')}</h3>
                        </div>
                        <div className='panelInput'>
                          <input
                            className='base2'
                            type='text'
                            {...register('tvaNumber', {})}
                            value={tvaNumber ?? undefined}
                            placeholder='FR 40 123456824'
                            onChange={(e) => updatetvaNumber(e?.target.value.toString())}
                          />
                          <p className='validator-error'>{errors.tvaNumber?.message}</p>
                        </div>
                      </div>
                    </div>
                    <div id='collaspeDetailCarrier' hidden={hiddenCollapseDetailCarrier}>
                      <div className='detail-row'>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.address')}</h3>
                          </div>
                          <div className='panelInput'>
                            <input
                              className='base2'
                              type='text'
                              {...register('carrierStreetAddress', {})}
                              value={carrierStreetAddress}
                              placeholder=''
                              required
                              onChange={(e) => updateCarrierStreetAddress(e?.target.value.toString())}
                            />
                          </div>
                          <div>
                            <p className='validator-error'>{errors.carrierStreetAddress?.message}</p>
                          </div>
                        </div>
                      </div>
                      <div className='detail-row'>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.locality')}</h3>
                          </div>
                          <div className='panelInput'>
                            <input
                              className='base2'
                              type='text'
                              {...register('carrierLocality', {})}
                              value={carrierLocality}
                              placeholder=''
                              required
                              onChange={(e) => updateCarrierLocality(e?.target.value.toString())}
                            />
                          </div>
                          <div>
                            <p className='validator-error'>{errors.carrierLocality?.message}</p>
                          </div>
                        </div>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.postalCode')}</h3>
                          </div>
                          <div className='panelInput'>
                            <input
                              className='base2'
                              type='text'
                              {...register('carrierPostalCode', {})}
                              value={carrierPostalCode}
                              placeholder='00000'
                              required
                              onChange={(e) => updateCarrierPostalCode(e?.target.value.toString())}
                            />
                          </div>
                          <div>
                            <p className='validator-error'>{errors.carrierPostalCode?.message}</p>
                          </div>
                        </div>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.country')}</h3>
                          </div>
                          <div className='panelInput'>
                            <Select
                              name='carrierCountry'
                              className='select-input base2'
                              isMulti={false}
                              styles={customStylesSelectReactPopup('#F4F4F4')}
                              options={optionsCountry}
                              placeholder={'Choisir un pays'}
                              onChange={(input) => {
                                input != null && input.value != null ? updateCarrierCountry(input) : null;
                              }}
                              value={selectedOptionCarrierCountry}
                              isDisabled={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='detail-row'>
                      <div className='detail-items-row'>
                        <div className='panelTitleText divClickable' onClick={toggleCollapseDetailCarrier}>
                          <h3 className='base2'>
                            {t('common.see') + ' '}
                            {hiddenCollapseDetailCarrier ? t('common.more') : t('common.less')}
                            {' ... '}
                          </h3>
                          <div className='svgBox primary2'>
                            {!hiddenCollapseDetailCarrier ? (
                              <SvgCollapse className='svg' onClick={toggleCollapseDetailCarrier}></SvgCollapse>
                            ) : (
                              <SvgOpen className='svg' onClick={toggleCollapseDetailCarrier}></SvgOpen>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <FormRow>
                      <SelectVehicle
                        titleSize='normal'
                        titleOverride={t('common.registrationPlate')}
                        noResultTextOverride={t('common.new') + ' ' + t('common.vehicle')}
                        register={register}
                        registerName='Vehicle'
                        setValue={setValue}
                        error={errors.Vehicle?.value?.message ?? errors.Vehicle?.label?.message ?? ''}
                        isSelectable={true}
                        selectedOptionChanged={(e: SelectVehicleOption) => {
                          if (e && e.value) {
                            SearchCompleteVehicleInfo(e.data);
                          } else {
                            deleteSelectedOptionVehicle();
                          }
                        }}
                        forceSelectedOption={vehicleOption}
                      />
                      <Input
                        {...register('trailerPlate', {})}
                        error={errors.trailerPlate?.message ?? ''}
                        label={t('common.trailerPlate')}
                        hoverDescription={t('common.trailerPlateDesc')}
                        type='text'
                        value={trailerPlate ?? undefined}
                        placeholder=''
                        disabled={false}
                        onChange={(e) => updatetrailerPlate(e as string)}
                      />
                    </FormRow>
                    <div className='detail-row'>
                      <div className='detail-items-row'>
                        <Input
                          {...register('tareWeight', {})}
                          error={errors.tareWeight?.message ?? ''}
                          label={t('common.lastTare')}
                          hoverDescription={t('common.lastTareDesc')}
                          type='number'
                          value={tareWeight ? parseFloat(tareWeight as any) : undefined}
                          placeholder=''
                          disabled={true}
                          onChange={(e) => updatetareWeight(parseFloat(e as string))}
                          prefix={'T'}
                        />
                      </div>
                      <div className='detail-items-row'>
                        <Input
                          {...register('maxWeightOut', {})}
                          error={errors.maxWeightOut?.message ?? ''}
                          label={
                            t('common.maxWeightOut') +
                            (ptacMax ? ' (PTAC/PTRA ' + Number(parseFloat(ptacMax as any).toFixed(2)) + ' T)' : '')
                          }
                          hoverDescription={t('common.maxWeightOutDesc')}
                          type='number'
                          value={maxWeightOut ? parseFloat(maxWeightOut as any) : undefined}
                          placeholder=''
                          disabled={false}
                          onChange={(e) => updatemaxWeightOut(e === '' ? undefined : (e as number))}
                          prefix={'T'}
                          number={{ min: 0, max: 1000000, step: 1 }}
                        />
                      </div>
                      <div className='detail-items-row'>
                        <Input
                          {...register('weightIn', {})}
                          error={errors.weightIn?.message ?? ''}
                          label={t('common.weightIn')}
                          hoverDescription={t('common.weightInDesc')}
                          type='number'
                          value={weightIn ? parseFloat(weightIn as any) : undefined}
                          placeholder=''
                          disabled={false}
                          onChange={(e) => updateWeightIn(e === '' ? undefined : (e as number))}
                          prefix={'T'}
                          number={{ min: 0, max: 1000000, step: 1 }}
                        />
                      </div>
                      <div className='detail-items-row'>
                        <Input
                          {...register('weightOut', {})}
                          error={errors.weightOut?.message ?? ''}
                          label={t('common.weightOut')}
                          hoverDescription={t('common.weightOutDesc')}
                          type='number'
                          value={weightOut ? weightOut : undefined}
                          placeholder=''
                          disabled={false}
                          onChange={(e) => updateWeightOut(e === '' ? undefined : (e as number))}
                          prefix={'T'}
                          number={{ min: 0, max: 1000000, step: 1 }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className='divider' />
                  <FormRow>
                    <Title label={t('common.products')} type='title1' />
                  </FormRow>
                  <div id='products-tab'>
                    <ProductItems
                      label={t('common.products')}
                      canAdd={vy?.status === 'Exited' ? false : true}
                      canRemove={vy?.status === 'Exited' ? false : true}
                      isLoading={false}
                      showTransportMode={false}
                      canEditTransportMode={false}
                      showOrderedQuantity={true}
                      showDeliveredQuantity={true}
                      canEditOrderedQuantity={vy?.status === 'Exited' ? false : true}
                      canEditDeliveredQuantity={vy?.status === 'Exited' ? false : true}
                      showUnitPrice={false}
                      showDiscountValue={false}
                      canEditDiscountValue={false}
                      showRowExVatPrice={false}
                      showRowInclVatPrice={false}
                      showTotalDetails={false}
                      showTotal={mode == 'update'}
                      showProductPrice={mode == 'update'}
                      showProductComment={true}
                      showInvoicing={mode == 'update'}
                    />
                  </div>
                  <hr className='divider' />
                  <div id='site-tab' className='detail-site' title={t('common.siteMovementDesc')}>
                    <div className='section-title'>
                      <div>
                        <h3 className='title1SemiBold'>{t('common.onSiteTravel')}</h3>
                      </div>
                    </div>
                    <div id='collaspeDetailSiteMovement' hidden={hiddenCollapseDetailSiteMovement}>
                      <div className='detail-row'>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.assignedZone')}</h3>
                          </div>
                          <div className='panelInput'>
                            <div className='panelInput'>
                              <Select
                                {...register('zoneA', {})}
                                className='select-input base2'
                                isMulti={false}
                                styles={customStylesSelectReactPopup('#F4F4F4')}
                                isLoading={isLoadingZonesA}
                                options={optionsZoneA}
                                loadingMessage={() => 'Chargement en cours'}
                                noOptionsMessage={() =>
                                  filterText.length < 1
                                    ? 'Entrez 1 caractère pour lancer une recherche'
                                    : 'Pas de résultat'
                                }
                                placeholder={t('common.zoneChoose')}
                                isSearchable={true}
                                onChange={(input) => {
                                  if (input != null && input.value != null) {
                                    updateZoneA(input);
                                  } else {
                                    deleteSelectedOptionZone();
                                  }
                                }}
                                value={selectedOptionsZoneA ?? null}
                                filterOption={createFilter(filterConfig)}
                                isClearable={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.priority')}</h3>
                          </div>
                          <div className='panelInput'>
                            <Select
                              {...register('priority')}
                              name='priority'
                              className='select-input base2'
                              isMulti={false}
                              styles={customStylesSelectReactPopup('#F4F4F4')}
                              options={optionsPriority}
                              placeholder={t('common.priorityChoose')}
                              onChange={(input) => {
                                input != null && input.value != null ? updatePriority(input) : null;
                              }}
                              value={selectedOptionsPriority ?? null}
                              isDisabled={isDisabled}
                              filterOption={createFilter(filterConfig)}
                            />

                            <p className='validator-error'>
                              {errors.priority?.label?.message ?? errors.priority?.label?.message}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='detail-row'>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.currentZone')}</h3>
                          </div>
                          <div className='panelInput'>
                            <input
                              className='base2'
                              type='text'
                              {...register('currentZone', {})}
                              value={currentZone ?? undefined}
                              placeholder=''
                              onChange={(e) => updatecurrentZone(e?.target.value.toString())}
                              disabled={true}
                            />
                            <p className='validator-error'>{errors.currentZone?.message}</p>
                          </div>
                        </div>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.destinationZone')}</h3>
                          </div>
                          <div className='panelInput'>
                            <Select
                              {...register('zoneD', {})}
                              className='select-input base2'
                              isMulti={false}
                              styles={customStylesSelectReactPopup('#F4F4F4')}
                              isLoading={isLoadingZonesD}
                              options={optionsZoneD}
                              loadingMessage={() => 'Chargement en cours'}
                              noOptionsMessage={() =>
                                filterText.length < 1
                                  ? 'Entrez 1 caractère pour lancer une recherche'
                                  : 'Pas de résultat'
                              }
                              placeholder={t('common.zoneChoose')}
                              isSearchable={true}
                              onChange={(input) => {
                                if (input != null && input.value != null) {
                                  updateZoneD(input);
                                } else {
                                  deleteSelectedOptionZoneD();
                                }
                              }}
                              value={selectedOptionsZoneD ?? null}
                              filterOption={createFilter(filterConfig)}
                              isClearable={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='detail-row'>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.arrival')}</h3>
                          </div>
                          <div className='panelInput'>
                            <input
                              className='base2'
                              type='string'
                              {...register('enteredAt')}
                              value={
                                moment(enteredAt).isValid()
                                  ? moment(enteredAt).tz(siteTimeZone).format('HH:mm')
                                  : undefined
                              }
                              disabled={true}
                            />
                            <p className='validator-error'>{errors.enteredAt?.message}</p>
                          </div>
                        </div>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.entryTerminal')}</h3>
                          </div>
                          <div className='panelInput'>
                            <input
                              className='base2'
                              type='text'
                              {...register('terminalIn', {})}
                              value={terminalIn ?? undefined}
                              placeholder=''
                              onChange={(e) => updateterminalIn(e?.target.value.toString())}
                              disabled={true}
                            />
                            <p className='validator-error'>{errors.terminalIn?.message}</p>
                          </div>
                        </div>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.departure')}</h3>
                          </div>
                          <div className='panelInput'>
                            <input
                              className='base2'
                              type='string'
                              {...register('exitedAt')}
                              value={
                                moment(exitedAt).isValid()
                                  ? moment(exitedAt).tz(siteTimeZone).format('HH:mm')
                                  : undefined
                              }
                              disabled={true}
                            />
                            <p className='validator-error'>{errors.exitedAt?.message}</p>
                          </div>
                        </div>
                        <div className='detail-items-row'>
                          <div className='panelTitleText'>
                            <h3 className='base2'>{t('common.exitTerminal')}</h3>
                          </div>
                          <div className='panelInput'>
                            <input
                              className='base2'
                              type='text'
                              {...register('terminalOut', {})}
                              value={terminalOut ?? undefined}
                              placeholder=''
                              onChange={(e) => updateterminalOut(e?.target.value.toString())}
                              disabled={true}
                            />
                            <p className='validator-error'>{errors.terminalOut?.message}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='detail-row'>
                      <div className='detail-items-row'>
                        <div className='panelTitleText divClickable' onClick={toggleCollapseDetailSiteMovement}>
                          <h3 className='base2'>
                            {t('common.see') + ' '}
                            {hiddenCollapseDetailSiteMovement ? t('common.more') : t('common.less')}
                            {' ... '}
                          </h3>
                          <div className='svgBox primary2'>
                            {!hiddenCollapseDetailSiteMovement ? (
                              <SvgCollapse className='svg' onClick={toggleCollapseDetailSiteMovement}></SvgCollapse>
                            ) : (
                              <SvgOpen className='svg' onClick={toggleCollapseDetailSiteMovement}></SvgOpen>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className='divider' />
                  <div id='content-tab' className='det-content'>
                    {buildDataCustom(paramTripDataCustom)}
                  </div>
                  <div id='file-tab' className='file-content'>
                    <div className='section-title'>
                      <div>
                        <h3 className='title1SemiBold'>{t('common.documents')}</h3>
                      </div>
                      <div className='section_title_button'>
                        <input style={{ display: 'none' }} ref={inputFile} onChange={handleFileUpload} type='file' />
                        {mode === 'update' ? (
                          <div className='addBtn' onClick={() => OpenAddFile()}>
                            <SvgPlus className='svgPlus' />
                            <div className='base2'>{t('common.addDoc')}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='fileList'>
                      <div className='filelist-Header'>
                        <div className='filelist-HeaderItem arrow'></div>
                        <div className='filelist-HeaderItem label'>
                          <h1 className='caption2medium'>{t('common.label')}</h1>
                        </div>
                        <div className='filelist-HeaderItem download'></div>
                        <div className='filelist-HeaderItem delete'></div>
                      </div>
                      {vy?.additionalDocs != undefined && vy?.additionalDocs.length > 0 ? (
                        <>
                          {vy?.additionalDocs?.map((doc: AdditionalDoc) => {
                            return (
                              <div className='filelist-Row' key={doc.id}>
                                <div className='filelist-Item arrow'>
                                  <SvgCDown className='svg' id={doc.id} />
                                </div>
                                <div className='filelist-Item label'>
                                  <h1 className='caption1'>{doc.label}</h1>
                                </div>
                                <div className='filelist-Item download'>
                                  <SvgDownload className='svg' onClick={() => onClickDownloadDoc(doc)} id={doc.id} />
                                </div>
                                <div className='filelist-Item delete'>
                                  {(additionalDocTypes.findIndex(
                                    (x) => x.id === doc.additionalDocTypeId && x.label === DocTypes.INVOICE,
                                  ) ?? -1) === -1 && (
                                    <SvgDelete className='svg' onClick={() => onClickDeleteDoc(doc)} id={doc.id} />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className='filelist-Row base2'>
                          <strong>{t('common.noDocumentOnTrip')}</strong>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <BottomBar
              disabledSxdBtn={true}
              disabledSaveBtn={disabledSaveBtn}
              connectedSxD={false}
              saveBottomSuccess={false}
              page={'trips'}
              ClickSave={handleSubmit(onSubmit)}
            />
          </div>
        )}
      </div>

      {isOpenConfirmDeleteFile && (
        <PopupContainer
          message={`${t('common.confirmDelete')} '${currentFileToDelete?.label}' ?`}
          onCancel={function (): void {
            setIsOpenConfirmDeleteFile(false);
          }}
          onConfirm={function (): void {
            setIsOpenConfirmDeleteFile(false);
            if (currentFileToDelete) onDeleteFileOk(currentFileToDelete);
          }}
          confirmLabel={t('common.yes')}
          confirmIcon='delete'
          cancelLabel={t('common.no')}
        />
      )}

      <Popup
        open={isOpenAddVehiclePopup}
        closeOnDocumentClick={false}
        closeOnEscape={true}
        onClose={() => setIsOpenAddVehiclePopup(false)}
        className='popup'
        position={'top center'}
        contentStyle={popupContentStyle}
        overlayStyle={popupOverlayStyle}
      >
        <AddVehicle from='detailVoyage' onsuccess={onSuccessAddVehicle} closePopup={CallbackChildDataVehicle} />
      </Popup>

      <Popup
        open={isOpenAddVehiclePopup}
        closeOnDocumentClick={true}
        closeOnEscape={true}
        className='popup'
        position={'top center'}
        contentStyle={popupContentStyle}
        overlayStyle={popupOverlayStyle}
      >
        <AddVehicle from='detailVoyage' onsuccess={onSuccessAddVehicle} closePopup={CallbackChildDataVehicle} />
      </Popup>

      <Popup
        open={isOpenTripCountWarning}
        closeOnDocumentClick={false}
        closeOnEscape={true}
        onClose={() => setIsOpenTripCountWarning(false)}
        className='popup'
        position={'top center'}
        contentStyle={popupContentStyle}
        overlayStyle={popupOverlayStyle}
      >
        <WarningPopup
          message={t('trip.warningEstimatedTrip')
            .replace('XXX', currentTripCount.toString())
            .replace('YYY', estimatedTripCount.toString())}
          close={() => setIsOpenTripCountWarning(false)}
        />
      </Popup>
    </div>
  );
});

class DeleteFile {
  entityId: string | undefined;
  additionalDocEntity: string | undefined;
}

class SearchBS {
  customerId: string | undefined;
}

class SearchVehicule {
  carrierId?: string | undefined;
  isActive: boolean | undefined;
}

class AddFile {
  fileName: string | undefined;
  additionalDocTypeId: string | undefined;
  autoLinkToTrip: boolean | undefined;
  autoLinkAtTripStart: boolean | undefined;
  autoLinkAtTripEnd: boolean | undefined;
  displayAtTripStart: boolean | undefined;
  displayAtTripEnd: boolean | undefined;
  additionalDocEntity: string | undefined;
  entityId: string | undefined;
  origin: string | undefined;
  fileBody: string | undefined;
}

class EditTrip {
  tripDate: Date | undefined; // "2022-04-25T11:09:46.622Z"
  tripTypeId: string | undefined; // "03a422f7-86cd-47b0-a659-e6a17af17904"
  sendToSxd: boolean | undefined; // false
  products: TripTripProduct[] | undefined;
  vehicleId: string | undefined; // "03a422f7-86cd-47b0-a659-e6a17af17904"
  customerId: string | undefined; // "03a422f7-86cd-47b0-a659-e6a17af17904"
  buildingSiteId: string | undefined; // "03a422f7-86cd-47b0-a659-e6a17af17904"
  externalReference: string | undefined; // "SDFGXC001"
  trailerPlate: string | undefined; // "ER123RE"
  tripComment: string | undefined; // "This is a comment"
  tripDeliveryComment: string | undefined; // "This is a comment"
  customerOrderNumber: string | undefined; // "12345"
  orderId: string | undefined; // "03a422f7-86cd-47b0-a659-e6a17af17904"
  carrierId: string | undefined; // "03a422f7-86cd-47b0-a659-e6a17af17904"
  maxWeightOut: number | undefined; // 12
  weightIn: number | undefined; // 12
  dsdIn?: string | undefined;
  weightOut: number | undefined; // 12
  dsdOut?: string | undefined;
  destinationZoneId: string | undefined; // "03a422f7-86cd-47b0-a659-e6a17af17904"
  processingZoneId: string | undefined; // "03a422f7-86cd-47b0-a659-e6a17af17904"
  status: string | undefined; // "Created"
  dataCustom: TripDataCustom | undefined;
  mustReturnBeforeLeaving: boolean | undefined;
  tripPriority: string | undefined; // "Medium"
  carrierTvaNumber: string | undefined; // "123456789"
  carrierStreetAddress: string | undefined; // "123 rue de la paix"
  carrierPostalCode: string | undefined; // "75000"
  carrierLocality: string | undefined; // "Paris"
  carrierCountry: string | undefined; // "FR"
  hasBeenRefused: boolean | undefined;
}

class TripDataCustom {
  [key: string]: any;
}

DetailVoyages.displayName = 'DetailVoyages';
export default DetailVoyages;
