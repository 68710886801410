import useToast from './use-toast';
import { ToastTypes } from '../models/ToastTypes';
import { t } from 'i18next';

const useAuthGuard = () => {
  const { addToast } = useToast();

  const token = localStorage.getItem('token');
  const orgid = localStorage.getItem('orgid');

  if (!token) {
    const toastMessage = `ERR4010001: ${t('errors.ERR4010001')}`;
    addToast(toastMessage, ToastTypes.error);
  } else if (!orgid) {
    const toastMessage = `ERR4031001: ${t('errors.ERR4031001')}`;
    addToast(toastMessage, ToastTypes.error);
  }

  const isAuthenticated = Boolean(token && orgid);

  return { isAuthenticated, token, orgid };
};

export default useAuthGuard;
