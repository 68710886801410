import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import ListProducts from '../../lists/Products/ListProducts';

const Products = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div className='main-container'>
      <Menu selectedPage='products' />
      <div className='right detail-container'>
        <Head title={t('common.products')} back={false} />
        <ListProducts canAddNew={true} />
      </div>
    </div>
  );
});

Products.displayName = 'Products';
export default Products;
