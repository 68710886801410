import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Button } from '../../../stories/dune/atoms/Button';
import '../../forms/SubMenu.scss';

interface SubMenuProductsProps {
  tab: string;
  updateTab: any;
}

const SubMenuProduct = React.memo((props: SubMenuProductsProps) => {
  const { t } = useTranslation();

  return (
    <div className='submenu'>
      <FormRow>
        <Button
          label={t('common.generalInformations')}
          size='wide'
          style={props.tab === 'general' ? 'primary' : 'white'}
          onClick={() => props.updateTab('general')}
        />

        <Button
          label={t('common.documents')}
          size='wide'
          style={props.tab === 'documents' ? 'primary' : 'white'}
          onClick={() => props.updateTab('documents')}
        />
      </FormRow>
    </div>
  );
});

SubMenuProduct.displayName = 'SubMenuProduct';
export default SubMenuProduct;
