import React, { useState, useReducer, useEffect } from 'react';
import styles from './filterHeader.module.scss';
import stylesFilter from './filterMenu.module.scss';
import { Button } from '../../stories/dune/atoms/Button';
import { useTranslation } from 'react-i18next';
import { Title } from '../../stories/dune/atoms/Title';
import { SearchBox } from '../../stories/dune/atoms/SearchBox';
import FilterMenu from './FilterMenu';
import { SelectCustomer } from '../forms/SelectCustomer';
import { Input } from '../../stories/dune/atoms/Input';
import { SelectBuildingSite } from '../forms/SelectBuildingSite';
import { SelectProduct } from '../forms/SelectProduct';
import { DateRange, DayPicker } from 'react-day-picker';
import { Language } from '../../i18n';
import { enUS, fr } from 'date-fns/locale';
import moment from 'moment';
import { SelectOrder } from '../forms/SelectOrder';
import { SelectZone } from '../forms/SelectZone';
import { SelectTripType } from '../forms/SelectTripType';
import { SelectProductType } from '../forms/SelectProductType';
import { SelectCarrier } from '../forms/SelectCarrier';
import { SelectAdditionalDocType } from '../forms/SelectAdditionalDocType';
import { SelectInvoiceType } from '../forms/SelectInvoiceType';
import { SelectInvoice } from '../forms/SelectInvoice';
import { IconType } from '../../stories/dune/atoms/Icon';

export type FilterType =
  | 'search'
  | 'number'
  | 'carrier'
  | 'labelAndReference'
  | 'labelAndReferenceAndCustomer'
  | 'externalReference'
  | 'registrationPlate'
  | 'label'
  | 'customer'
  | 'buildingSite'
  | 'order'
  | 'invoice'
  | 'product'
  | 'customerRef'
  | 'dateStart'
  | 'dateEnd'
  | 'processingZone'
  | 'currentZone'
  | 'tripType'
  | 'productType'
  | 'firstname'
  | 'lastname'
  | 'email'
  | 'telephone'
  | 'additionalDocType'
  | 'invoiceType'
  | 'parentReference';

export interface AdvancedFilter {
  filterType: FilterType;
  filterData: any;
}

type filtersReducerType = {
  id: string;
  selectedFilters: AdvancedFilter[];
  forcedFilters: AdvancedFilter[];
  validatedFilters: AdvancedFilter[];
};
type filtersReducerActions =
  | {
      type: 'set';
      filterType: FilterType;
      filterValue: any;
    }
  | {
      type: 'delete';
      filterType: FilterType;
    }
  | {
      type: 'setValidated';
    }
  | {
      type: 'reset';
    };

function filtersReducer(state: filtersReducerType, action: filtersReducerActions) {
  switch (action.type) {
    case 'set': {
      const newValue = state.selectedFilters.slice();
      const filterIndex = newValue.findIndex((x) => x.filterType === action.filterType);
      if (filterIndex === -1) {
        const newFilter: AdvancedFilter = { filterType: action.filterType, filterData: action.filterValue };
        newValue.push(newFilter);
      } else if (action.filterValue === null || !action.filterValue || action.filterValue.length === 0) {
        newValue.splice(filterIndex, 1);
      } else {
        newValue[filterIndex].filterData = action.filterValue;
      }
      return { ...state, selectedFilters: newValue };
    }
    case 'delete': {
      if (state.forcedFilters.findIndex((x) => x.filterType === action.filterType) === -1) {
        const newState = state.selectedFilters.filter((x) => x.filterType !== action.filterType);
        localStorage.setItem(state.id, JSON.stringify(newState));
        return { ...state, selectedFilters: newState };
      } else return state;
    }
    case 'setValidated': {
      localStorage.setItem(state.id, JSON.stringify(state.selectedFilters));
      return { ...state, validatedFilters: state.selectedFilters };
    }
    case 'reset': {
      localStorage.setItem(state.id, JSON.stringify(state.forcedFilters));
      return { ...state, selectedFilters: state.forcedFilters, validatedFilters: state.forcedFilters };
    }
  }
}

interface FilterHeaderProps {
  title: string;
  allowQuickSearch: boolean;
  quickSearchFilterTypes: FilterType[];
  allowAdvancedSearch: boolean;
  advancedSearchOptions: FilterType[];
  advancedSearchFilterChanged?: (newFilters: AdvancedFilter[]) => void;
  forcedFilters?: AdvancedFilter[];
  showAddNew: boolean;
  addNew?: () => void;
  addNewText?: string;
  addNewDesc?: string;
  addNewIcon?: IconType | undefined;
  dropdown?: boolean;
  listDropdown?: any[];
}

const FilterHeader = React.memo((props: FilterHeaderProps) => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem('language') ?? Language.FR;
  const [initialised, setInitialised] = useState(false);

  const filtersId = 'filterHeader' + window.location.href;

  const [advancedFilters, dispatchAdvancedFilters] = useReducer(filtersReducer, {
    id: filtersId,
    selectedFilters:
      (localStorage.getItem(filtersId) ?? '') !== '' && (localStorage.getItem(filtersId) ?? '') !== '[]'
        ? (JSON.parse(localStorage.getItem(filtersId) ?? '') as AdvancedFilter[])
        : props.forcedFilters ?? [],
    forcedFilters: props.forcedFilters ?? [],
    validatedFilters: props.forcedFilters ?? [],
  });
  const [quickFilterValue, setQuickFilterValue] = useState<any>(
    (localStorage.getItem(filtersId) ?? '') !== ''
      ? (JSON.parse(localStorage.getItem(filtersId) ?? '') as AdvancedFilter[]).find(
          (x) => x.filterType === props.quickSearchFilterTypes[0],
        )?.filterData
      : undefined,
  );

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [localPicker, setlocalPicker] = useState(fr);
  const [filteredDateRange, setFilteredDateRange] = useState<DateRange | undefined>(
    getAdvancedFilterValue('dateStart') && getAdvancedFilterValue('dateEnd')
      ? { from: getAdvancedFilterValue('dateStart'), to: getAdvancedFilterValue('dateEnd') }
      : undefined,
  );

  function openCloseFilterSelect() {
    setShowAdvancedFilters(!showAdvancedFilters);
  }

  function handleSelectDateRange(dateRange: DateRange | undefined) {
    if (!dateRange) {
      setAdvancedFilterValue('dateStart', undefined);
      setAdvancedFilterValue('dateEnd', undefined);
    } else {
      if (dateRange.to) dateRange.to.setHours(23, 59, 59, 999);
      setAdvancedFilterValue('dateStart', dateRange.from);
      setAdvancedFilterValue('dateEnd', dateRange.to);
    }
    setFilteredDateRange(dateRange);
  }

  useEffect(() => {
    switch (currentLanguage) {
      case Language.FR:
        setlocalPicker(fr);
        break;
      case Language.EN:
        setlocalPicker(enUS);
        break;
      default:
        setlocalPicker(fr);
        break;
    }

    validateFilters();
    setInitialised(true);
  }, []);

  function isTextFilter(filterType: FilterType) {
    return (
      filterType === 'search' ||
      filterType === 'number' ||
      filterType === 'labelAndReference' ||
      filterType === 'registrationPlate' ||
      filterType === 'label' ||
      filterType === 'externalReference' ||
      filterType === 'customerRef' ||
      filterType === 'firstname' ||
      filterType === 'lastname' ||
      filterType === 'email' ||
      filterType === 'telephone' ||
      filterType === 'parentReference'
    );
  }

  function getFilterElement(filterType: FilterType, isQuickSearch = false) {
    let ret = <></>;
    const titleSize = isQuickSearch ? 'none' : 'normal';
    if (isTextFilter(filterType)) {
      ret = (
        <Input
          label={t('common.' + filterType)}
          type='text'
          data-testid={filterType}
          placeholder={t('common.search' + filterType.charAt(0).toUpperCase() + filterType.slice(1))}
          onChange={(e) => setAdvancedFilterValue(filterType, e)}
          value={getAdvancedFilterValue(filterType)}
          error={''}
          disabled={
            !(!props.forcedFilters || props.forcedFilters?.findIndex((x) => x.filterType === filterType) === -1)
          }
        />
      );
    } else if (
      // if both dateStart & dateEnd, interval selector
      (filterType === 'dateStart' && props.advancedSearchOptions.findIndex((x) => x === 'dateEnd') !== -1) ||
      (filterType === 'dateEnd' && props.advancedSearchOptions.findIndex((x) => x === 'dateStart') !== -1)
    ) {
      if (filterType === 'dateStart') {
        ret = (
          <DayPicker
            locale={localPicker}
            mode='range'
            defaultMonth={moment().toDate()}
            selected={filteredDateRange}
            onSelect={handleSelectDateRange}
            disabled={
              !(!props.forcedFilters || props.forcedFilters?.findIndex((x) => x.filterType === filterType) === -1)
            }
          />
        );
      }
    } else if (filterType === 'dateStart' || filterType === 'dateEnd') {
      ret = (
        <Input
          label={t('common.' + filterType)}
          type='date'
          placeholder={t('common.search' + filterType.charAt(0).toUpperCase() + filterType.slice(1))}
          onChange={(e) => setAdvancedFilterValue(filterType, e)}
          value={getAdvancedFilterValue(filterType)}
          error={''}
          disabled={
            !(!props.forcedFilters || props.forcedFilters?.findIndex((x) => x.filterType === filterType) === -1)
          }
        />
      );
    } else if (filterType === 'customer') {
      ret = (
        <SelectCustomer
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
        />
      );
    } else if (filterType === 'buildingSite') {
      ret = (
        <SelectBuildingSite
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
          searchParent={getAdvancedFilterValue('customer')?.data?.id}
          titleOverride={t('common.jobSite')}
        />
      );
    } else if (filterType === 'carrier') {
      ret = (
        <SelectCarrier
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
        />
      );
    } else if (filterType === 'order') {
      ret = (
        <SelectOrder
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
        />
      );
    } else if (filterType === 'invoice') {
      ret = (
        <SelectInvoice
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
        />
      );
    } else if (filterType === 'product') {
      ret = (
        <SelectProduct
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
        />
      );
    } else if (filterType === 'processingZone' || filterType === 'currentZone') {
      ret = (
        <SelectZone
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
          searchParent={filterType === 'processingZone' ? 'process' : undefined}
          titleOverride={t('common.' + filterType)}
        />
      );
    } else if (filterType === 'tripType') {
      ret = (
        <SelectTripType
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
          searchParent={['load', 'unload']}
        />
      );
    } else if (filterType === 'productType') {
      ret = (
        <SelectProductType
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
        />
      );
    } else if (filterType === 'additionalDocType') {
      ret = (
        <SelectAdditionalDocType
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
        />
      );
    } else if (filterType === 'invoiceType') {
      ret = (
        <SelectInvoiceType
          registerName={''}
          error={''}
          isSelectable={
            !(props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == filterType) !== -1)
          }
          selectedOptionChanged={(props) => {
            if (isQuickSearch) handleSetQuickSearch(props);
            else setAdvancedFilterValue(filterType, props);
          }}
          initialOption={getAdvancedFilterValue(filterType)}
          titleSize={titleSize}
          dependsOnSearchParent={false}
        />
      );
    }

    return ret;
  }

  function setAdvancedFilterValue(filterType: FilterType, filterValue: any) {
    dispatchAdvancedFilters({
      type: 'set',
      filterType: filterType,
      filterValue: formatFilterValue(filterType, filterValue),
    });
  }

  function getAdvancedFilterValue(filterType: FilterType) {
    return formatFilterValue(
      filterType,
      advancedFilters.selectedFilters.find((x) => x.filterType === filterType)?.filterData,
    );
  }

  function formatFilterValue(filterType: FilterType, filterValue: any) {
    if (filterType.startsWith('date') || filterType.endsWith('Date'))
      return filterValue ? new Date(filterValue) : undefined;
    else return filterValue;
  }

  function handleSetQuickSearch(newValue: any) {
    setQuickFilterValue(newValue);
    props.quickSearchFilterTypes.map((x) => {
      // chaque filtre de quickSearchFilterTypes qui n'est pas forcé est mis à jour
      if (advancedFilters.forcedFilters.findIndex((y) => y.filterType === x) === -1)
        setAdvancedFilterValue(x, newValue);
    });
  }

  function validateFilters() {
    dispatchAdvancedFilters({ type: 'setValidated' });
    if (props.advancedSearchFilterChanged) props.advancedSearchFilterChanged(advancedFilters.selectedFilters);
  }

  function resetFilters() {
    handleSetQuickSearch(undefined);
    dispatchAdvancedFilters({ type: 'reset' });
    setFilteredDateRange(undefined);
    if (props.advancedSearchFilterChanged) props.advancedSearchFilterChanged(advancedFilters.forcedFilters);
  }

  function removeFilters(filterTypes: FilterType[]) {
    filterTypes.forEach((x) => {
      dispatchAdvancedFilters({ type: 'delete', filterType: x });
    });
    if (props.advancedSearchFilterChanged) {
      const newFilters = advancedFilters.selectedFilters.filter((x) => {
        return filterTypes.findIndex((y) => y === x.filterType) === -1;
      });

      if (newFilters.length < advancedFilters.validatedFilters.length) props.advancedSearchFilterChanged(newFilters);
    }
  }

  useEffect(() => {
    if (initialised && props.quickSearchFilterTypes) {
      if (props.quickSearchFilterTypes.length > 0 && isTextFilter(props.quickSearchFilterTypes[0])) {
        const isQuickFilterValue = quickFilterValue && quickFilterValue.length > 0;
        const timeOutId = setTimeout(
          () => (isQuickFilterValue ? validateFilters() : removeFilters(props.quickSearchFilterTypes)),
          500,
        );
        return () => clearTimeout(timeOutId);
      } else if (quickFilterValue === null) {
        removeFilters(props.quickSearchFilterTypes);
      } else {
        validateFilters();
      }
    }
  }, [quickFilterValue]);

  const quickSearchFilterElement = isTextFilter(props.quickSearchFilterTypes[0]) ? (
    <SearchBox
      value={quickFilterValue}
      setValue={handleSetQuickSearch}
      placeholder={
        props.quickSearchFilterTypes.length > 0
          ? t(
              'common.search' +
                props.quickSearchFilterTypes[0].charAt(0).toUpperCase() +
                props.quickSearchFilterTypes[0].slice(1),
            )
          : ''
      }
    />
  ) : (
    props.quickSearchFilterTypes.map((x) => (
      <div key={x} className={styles.left}>
        {getFilterElement(x)}
      </div>
    )) ?? <></>
  );

  return (
    <>
      <div className={styles.recherche}>
        <div className={styles.property1designActuelle}>
          {props.title && props.title.length > 0 && <Title label={props.title} type='title2' align='left' />}
          <div className={styles.searchBoxParent}>
            {props.allowQuickSearch && props.quickSearchFilterTypes.length > 0 && quickSearchFilterElement}
            {props.allowAdvancedSearch && (
              <Button
                label={t('common.filters')}
                size='medium'
                style={showAdvancedFilters || advancedFilters.validatedFilters.length !== 0 ? 'primary' : 'secondary'} // TODO DESIGN : revoir la couleur bouton si filtres validés
                iconLeft='filter'
                onClick={() => openCloseFilterSelect()}
              />
            )}
          </div>
          {props.showAddNew && (
            <div className={styles.buttonWrapper} title={props.addNewDesc}>
              <Button
                label={props.addNewText ?? t('common.add')}
                size='medium'
                style={'primary'}
                iconLeft={props.addNewIcon ?? 'add'}
                dropdown={props.dropdown}
                listDropdown={props.listDropdown}
                onClick={props.addNew}
              />
            </div>
          )}
        </div>
      </div>
      {showAdvancedFilters ? (
        <FilterMenu
          validateClicked={function (): void {
            validateFilters();
            openCloseFilterSelect();
          }}
          resetClicked={function (): void {
            resetFilters();
            openCloseFilterSelect();
          }}
        >
          {props.advancedSearchOptions.map((x: FilterType) => {
            // TODO DESIGN revoir l'affichage des composants filtre dans FilterMenu
            return (
              <div className={stylesFilter.filter} key={x}>
                {getFilterElement(x)}
              </div>
            );
          })}
        </FilterMenu>
      ) : null}
    </>
  );
});

FilterHeader.displayName = 'FilterHeader';
export default FilterHeader;
