import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormRow } from '../../stories/dune/atoms/FormRow';
import { Button } from '../../stories/dune/atoms/Button';
import './SubMenu.scss';

interface SubMenuProps {
  tab: string;
  updateTab: any;
  tabs: { key: string; label: string }[];
  inForm?: boolean;
}

const SubMenu = React.memo((props: SubMenuProps) => {
  const { t } = useTranslation();

  const content = (
    <>
      <FormRow>
        {props.tabs.map((val) => (
          <Button
            label={t(val.label)}
            size='wide'
            style={props.tab === val.key ? 'primary' : 'white'}
            onClick={() => props.updateTab(val.key)}
            key={val.key}
          />
        ))}
      </FormRow>
    </>
  );

  return props.inForm ? content : <div className='submenu'>{content}</div>;
});

SubMenu.displayName = 'SubMenu';
export default SubMenu;
