import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import ListVehicles from '../../lists/Vehicles/ListVehicles';

const Vehicles = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div className='main-container'>
      <Menu selectedPage='vehicles' />
      <div className='right detail-container'>
        <Head title={t('common.vehicles')} back={false} />
        <ListVehicles canAddNew={true} />
      </div>
    </div>
  );
});

Vehicles.displayName = 'Vehicles';
export default Vehicles;
