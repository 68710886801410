import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Button } from '../../../stories/dune/atoms/Button';
import '../../forms/SubMenu.scss';

interface SubMenuQuotationProps {
  tab: string;
  currentTab: any;
}

const SubMenuQuotation = React.memo((props: SubMenuQuotationProps) => {
  const { t } = useTranslation();

  return (
    <div className='submenu'>
      <FormRow>
        <Button
          label={t('common.generalInformations')}
          size='wide'
          style={props.tab === 'general' ? 'primary' : 'white'}
          onClick={() => props.currentTab('general')}
        />
        <Button
          label={t('common.history')}
          size='wide'
          style={props.tab === 'history' ? 'primary' : 'white'}
          onClick={() => props.currentTab('history')}
        />
        <Button
          label={t('common.documents')}
          size='wide'
          style={props.tab === 'documents' ? 'primary' : 'white'}
          onClick={() => props.currentTab('documents')}
        />
      </FormRow>
    </div>
  );
});

SubMenuQuotation.displayName = 'SubMenuQuotation';
export default SubMenuQuotation;
