import React, { ReactNode, useEffect, useState } from 'react';
import './head.scss';
import { Button } from '../atoms/Button';
import { Title } from '../atoms/Title';
import Barrier from '../../../components/Barrier';
import Notification from '../../../components/Notification';
import eventBus from '../../../utils/EventBus';
import { useNavigate } from 'react-router-dom';

interface HeadProps {
  title: string | ReactNode;
  titleType?: 'h1' | 'h3' | 'h4' | 'h5' | 'title1' | 'title2' | 'label';
  back: boolean;
  Back?: () => void;
  breadcrumbs1?: ReactNode;
  breadcrumbs2?: ReactNode;
  breadcrumbs3?: ReactNode;
}

export const Head = ({ title = 'Title', back = true, Back, ...props }: HeadProps) => {
  const navigate = useNavigate();

  const [isOpenBarrierPopup, setIsOpenBarrierPopup] = useState(true);
  const [isOpenNotificationPopup, setIsOpenNotificationPopup] = useState(true);
  const [newAlert, setNewAlert] = useState(false);

  const openPopupBarrier = () => {
    setIsOpenBarrierPopup(false);
  };

  const openPopupNotif = () => {
    setNewAlert(false);
    setIsOpenNotificationPopup(false);
  };

  const closePopupBarrier = (e: any) => {
    e ? setIsOpenBarrierPopup(e) : setIsOpenBarrierPopup(false);
  };

  const closeNotificationPopup = (e: any) => {
    e ? setIsOpenNotificationPopup(e) : setIsOpenNotificationPopup(false);
  };

  useEffect(() => {
    eventBus.on('new_alert', () => {
      setNewAlert(true);
    });
  }, []);

  return (
    <div className='dune-head'>
      <div className='dune-head-left dune-head-main'>
        {back ? <Button size='large' iconLeft='back' style='white' onClick={() => navigate(-1)} /> : ''}
        <Title
          label={title}
          align='left'
          type={props.titleType}
          breadcrumbs1={props.breadcrumbs1}
          breadcrumbs2={props.breadcrumbs2}
          breadcrumbs3={props.breadcrumbs3}
        />
      </div>
      <div className='dune-head-right'>
        <Button size='medium' iconLeft='key' style='white' onClick={openPopupBarrier} />
        <Button size='medium' iconLeft='notification' style='white' onClick={openPopupNotif} />

        <div className='Sidebar' hidden={isOpenBarrierPopup}>
          <Barrier from='Header' parentCallback={closePopupBarrier} />
        </div>
        <div className='Sidebar' hidden={isOpenNotificationPopup}>
          <Notification from='Header' parentCallback={closeNotificationPopup} refresh={isOpenNotificationPopup} />
        </div>
      </div>
    </div>
  );
};
