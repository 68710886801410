import { useEffect, useState } from 'react';
import './Login.scss';
import '../../index.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import Lang from '../Lang';
import { ReactComponent as Logo } from '../../assets/svg/dune-logo.svg';
import { ToastTypes } from '../../models/ToastTypes';
import { resetPassword } from '../../services/auth';
import axios from 'axios';
import useToast from '../../hooks/use-toast';

const resetPasswordSchema = yup.object().shape({
  username: yup.string().email('Adresse email non valide').required('Le mail est obligatoire'),
  password: yup
    .string()
    .required('Le mot de passe est obligatoire')
    .oneOf([yup.ref('confirmPassword')], 'Les mots de passe ne correspondent pas'),
  confirmPassword: yup
    .string()
    .required('Le mot de passe est obligatoire')
    .oneOf([yup.ref('password')], 'Les mots de passe ne correspondent pas'),
});

type Credentials = yup.InferType<typeof resetPasswordSchema>;

function ResetPassword() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [errorMessage, setErrorMessage] = useState('');
  const [currentToken, setCurrentToken] = useState<string | null>('');

  const history = useNavigate();

  useEffect(() => {
    localStorage.clear();
    const token = new URLSearchParams(window.location.search).get('token');
    setCurrentToken(token);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Credentials>({
    resolver: yupResolver(resetPasswordSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      username: '',
      password: '',
      confirmPassword: '',
    },
  });

  const onSubmit = async (credentials: Credentials) => {
    if (!currentToken) {
      setErrorMessage(t('login.resetPassword.results.expired'));
      return;
    }

    const credentialsBase64 = {
      username: credentials.username,
      password: Buffer.from(credentials.password).toString('base64'),
      resetToken: currentToken,
    };

    try {
      await resetPassword(credentialsBase64);
      addToast(t('login.resetPassword.results.success'), ToastTypes.success);
      setTimeout(() => history('/'), 2000);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status } = error.response;
        const errorMessages: Record<number, string> = {
          401: t('login.IncorrectCredentials'),
          400: t('login.resetPassword.results.expired'),
        };
        setErrorMessage(errorMessages[status] || t('login.ErrorMessage'));
      } else {
        setErrorMessage(t('login.ErrorMessage'));
      }
    }
  };

  const onKeyPressEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await handleSubmit(onSubmit)();
    }
  };

  return (
    <div className='login'>
      <div className='leftImage'></div>
      <div className='right'>
        <div style={{ textAlign: 'center' }}>
          <Logo />
        </div>

        <h1 className='title-center'>{t('login.resetPassword.title')}</h1>
        <div className='content'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h5>{t('login.email')}</h5>

            <input type='text' {...register('username', {})} name='username' placeholder='Dune.manager@gmail.com' />
            <p className='validator-error'>{errors.username?.message}</p>

            <h5>{t('login.password')}</h5>

            <input
              type='password'
              {...register('password', {})}
              name='password'
              placeholder='***********'
              onKeyPress={onKeyPressEvent}
            />

            <p className='validator-error'>{errors.password?.message}</p>

            <h5>{t('login.resetPassword.confirmPassword')}</h5>

            <input
              type='password'
              {...register('confirmPassword', {})}
              name='confirmPassword'
              placeholder='***********'
            />

            <p className='validator-error'>{errors.confirmPassword?.message}</p>

            <p className='validator-error'>{errorMessage}</p>

            <div className='addBtn' onClick={handleSubmit(onSubmit)}>
              <div className='base2'>{t('login.resetPassword.reset')}</div>
            </div>
          </form>
        </div>
      </div>

      <div className='login langSelector'>
        <Lang></Lang>
      </div>
    </div>
  );
}

export default ResetPassword;
