import React, { useEffect } from 'react';
import './App.css';
import Login from './components/auth/Login';
import Vehicles from './components/pages/Vehicles/Vehicles';
import Carriers from './components/pages/Carriers/Carriers';
import Alertes from './components/Alertes';
import AuthorizedVehicles from './components/pages/AuthorizedVehicules/AuthorizedVehicles';
import Zones from './components/pages/Zones/Zones';
import Voyages from './components/Voyages';
import TruckZone from './components/TruckZone';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import DetailZone from './components/pages/Zones/DetailZone';
import DetailCarrier from './components/pages/Carriers/DetailCarrier';
import DetailCustomer from './components/pages/Customers/DetailCustomer';
import DetailBuildingSite from './components/pages/BuildingSites/DetailBuildingSite';
import DetailVehicle from './components/pages/Vehicles/DetailVehicle';
import DetailVoyage from './components/DetailVoyages';
import EventListener from './components/EventListener';
import Account from './components/auth/Account';
import Changelog from './components/Changelog';
import ManageEquipments from './components/ManageEquipments';
import Quotations from './components/pages/Quotations/Quotations';
import DetailQuotation from './components/pages/Quotations/DetailQuotation';
import Contracts from './components/pages/Contracts/Contracts';
import DetailContract from './components/pages/Contracts/DetailContract';
import Orders from './components/pages/Orders/Orders';
import DetailOrder from './components/pages/Orders/DetailOrder';
import WasteRequests from './components/pages/WasteRequests/WasteRequests';
import Customers from './components/pages/Customers/Customers';
import Products from './components/pages/Products/Products';
import BuildingSite from './components/pages/BuildingSites/BuildingSites';
import DetailProduct from './components/pages/Products/DetailProduct';
import ResetPassword from './components/auth/ResetPassword';
import ToastsDisplay from './components/ToastsDisplay';
import DetailAuthorizedVehicle from './components/pages/AuthorizedVehicules/DetailAuthorizedVehicle';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './store';
import { loadStaticData } from './store/staticData-slice';
import Invoices from './components/pages/Invoices/Invoices';
import DetailInvoice from './components/pages/Invoices/DetailInvoice';
import Invoicing from './components/pages/Invoices/Invoicing';
import PrivateRoutes from './components/auth/PrivateRoutes';

function App() {
  const dispatch = useDispatch<AppDispatch>(); // The `useDispatch` hook with AppDispatch type to call async thunk actions

  useEffect(() => {
    dispatch(loadStaticData()); // Load static data at refresh if authenticated (called at authentication at well)
  }, [dispatch]);

  return (
    <div className='App'>
      <EventListener />
      <ToastsDisplay />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/forgot-password' element={<ResetPassword />} />
          <Route element={<PrivateRoutes />}>
            <Route path='/account' element={<Account />} />
            <Route path='quotations' element={<Quotations />} />
          </Route>
          <Route path='products' element={<Products />} />
          <Route path='products/add/' element={<DetailProduct mode='add' />} />
          <Route path='products/:id/:mode' element={<DetailProduct />} />
          <Route path='zones' element={<Zones />} />
          <Route path='zones/:id/:mode' element={<DetailZone />} />
          <Route path='zones/add/' element={<DetailZone mode='add' />} />
          <Route path='carriers' element={<Carriers />} />
          <Route path='carriers/add/' element={<DetailCarrier mode='add' />} />
          <Route path='carriers/:id/:mode' element={<DetailCarrier />} />
          <Route path='vehicles' element={<Vehicles />} />
          <Route path='vehicles/add/' element={<DetailVehicle mode='add' />} />
          <Route path='vehicles/:id/:mode' element={<DetailVehicle mode='update' />} />
          <Route path='alertes' element={<Alertes />} />
          <Route path='alertes/:id/:mode' element={<DetailZone />} />
          <Route path='authorizedvehicles' element={<AuthorizedVehicles />} />
          <Route path='authorizedvehicles/add' element={<DetailAuthorizedVehicle mode='add' />} />
          <Route path='authorizedvehicles/:id/:mode' element={<DetailAuthorizedVehicle mode='update' />} />
          <Route path='trips' element={<Voyages />} />
          <Route path='trips/:id/:mode' element={<DetailVoyage />} />
          <Route path='trips/add' element={<DetailVoyage />} />
          <Route path='orders' element={<Orders />} />
          <Route path='orders/add/:orderMode/:parentId' element={<DetailOrder mode='add' />} />
          <Route path='orders/add/' element={<DetailOrder mode='add' />} />
          <Route path='orders/:id/:mode' element={<DetailOrder />} />
          <Route path='quotations/add/:quotationMode' element={<DetailQuotation mode='add' />} />
          <Route path='quotations/:id/:mode' element={<DetailQuotation mode='update' />} />
          <Route path='contracts' element={<Contracts />} />
          <Route path='contracts/add/:quotationMode/:parentId' element={<DetailContract mode='add' />} />
          <Route path='contracts/:id/:mode' element={<DetailContract mode='update' />} />
          <Route path='wasteRequests' element={<WasteRequests />} />
          <Route path='invoicing' element={<Invoicing />} />
          <Route path='invoicing/:tab' element={<Invoicing />} />
          <Route path='invoicing/:tab/:filter' element={<Invoicing />} />
          <Route path='invoices' element={<Invoices />} />
          <Route path='invoices/:id/:mode' element={<DetailInvoice mode='update' />} />
          <Route path='truckZone/:id/:orgid/:mode' element={<TruckZone />} />
          <Route path='/changelog' element={<Changelog />} />
          <Route path='customers' element={<Customers />} />
          <Route path='buildingSites' element={<BuildingSite />} />
          <Route path='customers/:id/:mode' element={<DetailCustomer />} />
          <Route path='customers/:id/:mode/:tab' element={<DetailCustomer />} />
          <Route path='customers/add' element={<DetailCustomer mode='add' />} />
          <Route path='buildingSites/:id/:mode' element={<DetailBuildingSite />} />
          <Route path='buildingSites/:id/:mode/:tab' element={<DetailBuildingSite />} />
          <Route path='buildingSites/add' element={<DetailBuildingSite mode='add' />} />
          <Route path='manageequipments/' element={<ManageEquipments />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
