import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductItemsState } from '../../../store/productItems-slice';
import { ProductItems } from '../../forms/ProductItems';
import SubMenu from '../../forms/SubMenu';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Title } from '../../../stories/dune/atoms/Title';
import { GetInvoice, InvoiceType } from '../../../models/Invoice';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from '../../../store';

interface GeneralInvoiceProps {
  invoiceData: GetInvoice | undefined;
}

const ProductsInvoice = React.memo((props: GeneralInvoiceProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const [currentProductTab, setCurrentProductTab] = useState('buildingSites');

  const products = useSelector((state: { productItems: ProductItemsState }) => {
    return state.productItems.products;
  });

  function countTrips(): number {
    let count = 0;
    const aTrips: string[] = [];
    products.forEach((product) => {
      if (product.tripId && !aTrips.includes(product.tripId)) {
        count++;
        aTrips.push(product.tripId);
      }
    });
    return count;
  }

  function countBuildingSites(): number {
    let count = 0;
    const aBusi: string[] = [];
    products.forEach((product) => {
      if (product.trip?.buildingSiteId && !aBusi.includes(product.trip?.buildingSiteId)) {
        count++;
        aBusi.push(product.trip?.buildingSiteId);
      }
    });
    return count;
  }

  return (
    <>
      <FormRow>
        <Title
          label={
            props.invoiceData?.type === InvoiceType.PROFORMA
              ? t('common.productsToInvoice')
              : t('common.productsInvoiced')
          }
          type='h3'
        />
      </FormRow>
      <SubMenu
        tab={currentProductTab}
        updateTab={(mode: string) => setCurrentProductTab(mode)}
        tabs={[
          { key: 'buildingSites', label: t('common.jobSites') + ' (' + countBuildingSites() + ')' },
          { key: 'trips', label: t('common.trips') + ' (' + countTrips() + ')' },
        ]}
        inForm
      />
      <ProductItems
        label={''}
        canAdd={false}
        canRemove={false}
        isLoading={false}
        showTransportMode={false}
        canEditTransportMode={false}
        showOrderedQuantity={false}
        showDeliveredQuantity={true}
        canEditOrderedQuantity={false}
        canEditDeliveredQuantity={props.invoiceData?.type === InvoiceType.PROFORMA}
        canEditUnitPrice={props.invoiceData?.type === InvoiceType.PROFORMA}
        canEditVatRate={props.invoiceData?.type === InvoiceType.PROFORMA}
        canEditRowProduct={props.invoiceData?.type === InvoiceType.PROFORMA}
        showUnitPrice={true}
        showDiscountValue={true}
        canEditDiscountValue={false}
        showRowExVatPrice={true}
        showVatRate={true}
        showRowInclVatPrice={true}
        showTotal={true}
        showTotalDetails={true}
        displayMode={currentProductTab === 'buildingSites' ? 'buildingSites' : 'trips'}
        parentPriceLabel={
          props.invoiceData?.type === InvoiceType.PROFORMA ? t('common.toInvoice') : t('common.invoiced')
        }
        rowsHaveEditMode={props.invoiceData?.type === InvoiceType.PROFORMA}
      />
    </>
  );
});

ProductsInvoice.displayName = 'GeneralInvoice';
export default ProductsInvoice;
