import { t } from 'i18next';
import { RowParent } from '../../../store/productItems-slice';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PriceUtils from '../../../utils/priceUtils';
import StringUtils from '../../../utils/stringUtils';
import { Icon } from '../../../stories/dune/atoms/Icon';

export const setParentProductColumns = (
  displayMode: 'trips' | 'buildingSites',
  priceLabel: string,
  siteTimeZone: string,
  priceUtils: PriceUtils,
  switchRowEditMode?: (row: RowParent, newValue: boolean) => void,
  expandedRowId?: string,
  enableRowEdit?: boolean,
  editingExpandedRow?: boolean,
): any[] => [
  {
    name: <div className='heading6'>{t('common.trip')}</div>,
    selector: (row: RowParent) => row.tripId,
    cell: (row: RowParent) => (
      <>
        {enableRowEdit && !editingExpandedRow && row.id === expandedRowId && (
          <div
            className='table-link'
            style={{ paddingRight: '24px' }}
            title={t('common.clickToEdit')}
            onClick={() => {
              switchRowEditMode?.(row, true);
            }}
          >
            <Icon icon={'edit'} />
          </div>
        )}
        <div className='column-align-left'>
          {row.trip && (
            <Link className='table-link base2' to={`/trips/${row.tripId}/update`}>
              <a>
                {row.trip.tripNumber +
                  (row.trip.externalReference ? '-' + row.trip.externalReference : '') +
                  (' - ' + moment(row.trip.tripDate).tz(siteTimeZone).format('DD/MM/YYYY HH:mm:ss'))}
              </a>
            </Link>
          )}
        </div>
      </>
    ),
    sortable: false,
    button: false,
    right: false,
    id: 'trip',
    omit: !(displayMode === 'trips'),
    grow: 2,
  },
  {
    name: <div className='heading6'>{t('common.jobSite')}</div>,
    selector: (row: RowParent) => row.buildingSiteId,
    cell: (row: RowParent) => (
      <>
        {enableRowEdit && !editingExpandedRow && row.id === expandedRowId && !(displayMode === 'trips') && (
          <div
            className='table-link'
            style={{ paddingRight: '24px' }}
            title={t('common.clickToEdit')}
            onClick={(e) => {
              switchRowEditMode?.(row, true);
            }}
          >
            <Icon icon={'edit'} />
          </div>
        )}
        <div className='column-align-left'>
          {row.trip && (
            <Link className='base2 table-link' to={`/buildingSites/${row.buildingSiteId}/update`}>
              <a>{row.buildingSite?.label}</a>
            </Link>
          )}
        </div>
      </>
    ),
    sortable: false,
    button: false,
    right: false,
    left: !(displayMode === 'trips'),
    center: displayMode === 'trips',
    id: 'buildingSite',
    omit: false,
    grow: 2,
  },
  {
    name: <div className='heading6'>{priceLabel}</div>,
    selector: (row: RowParent) => priceUtils.getTotalPrice(row.rowProducts ?? []),
    cell: (row: RowParent) => (
      <div className='column-align-left'>
        <strong className='base2 parent-table-row'>
          {StringUtils.currencyFormat(priceUtils.getTotalPrice(row.rowProducts ?? []), 'EUR') ?? '-'}
        </strong>
      </div>
    ),
    sortable: false,
    right: true,
    id: 'parentRowPrice',
    omit: false,
    grow: 1,
  },
];
