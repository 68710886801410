import AdditionalDoc, { GetAdditionalDoc } from './AdditionalDoc';
import Customer from './Customer';
import Trip from './Trip';
import DefaultModel from './DefaultModel';
import Order from './Order';
import Product, { ProductUnit } from './Product';
import Payment from './Payment';

export enum InvoiceStatus {
  CREATED = 'Created',
  PAID = 'paid',
  ISSUED = 'issued',
  CANCELED = 'canceled',

  UPCOMING = 'upcoming',
  ARCHIVED = 'archived',
}

export enum InvoiceType {
  PROFORMA = 'proforma',
  INVOICE = 'invoice',
  CREDIT_NOTE = 'credit-note',
}

export class Invoice extends DefaultModel {
  externalReference?: string;
  status?: InvoiceStatus;
  type?: InvoiceType;
  invoiceComment?: string;
  invoicingDate?: Date;
  invoiceDueDate?: Date;
  invoiceNumber?: string;
  lastImportAt?: Date;
  dataCustom?: string;
  orderId?: string;
  order?: Order;
  customerId?: string;
  customer?: Customer;
  additionalDocs?: AdditionalDoc[];
  payments?: Payment[];
  invoiceRows?: InvoiceRow[];
  trips?: Trip[];
  parentInvoiceNumber?: string;
  siteId?: string;
  affiliateId?: string;
}

export class InvoiceRow {
  id?: string;
  invoiceId?: string;
  tripId?: string;
  trip?: Trip;
  invoiceRowProducts?: InvoiceRowProduct[];
}

export class InvoiceRowProduct {
  id?: string;
  invoiceRowId?: string;
  productId?: string;
  product?: Product;
  quotedPrice?: number;
  discount?: number;
  genericPrice?: number;
  repValue?: number;
  tgapValue?: number;
  transportPrice?: number;
  quantity?: number;
  unit?: ProductUnit;
  vatRate?: number;
  label?: string;
}

export interface GetInvoiceParameters {
  invoiceStatus?: string;
  invoiceType?: string;
  orderId?: string;
  customerId?: string;
  externalReference?: string;
  invoiceNumber?: string;
  parentInvoiceNumber?: string;
  dueDateBefore?: Date;
}

export interface GetInvoice {
  id: string;
  externalReference?: string;
  status: InvoiceStatus;
  type: InvoiceType;
  invoiceComment?: string;
  invoicingDate?: Date;
  invoiceDueDate?: Date;
  invoiceNumber: string;
  lastImportAt?: Date;
  dataCustom?: string;
  orderId: string;
  order: Order;
  customerId: string;
  customer: Customer;
  parentInvoiceNumber?: string;
  payments: Payment[];
  invoiceRows: InvoiceRow[];
  createdAt: Date;
  createdBy: string;
  lastModificationAt: Date;
  lastModificationBy: string;
  isActive: boolean;
  additionalDocs: GetAdditionalDoc[];
}

export interface InvoiceSave {
  externalReference?: string;
  invoiceComment?: string;
  invoicingDate?: string;
  invoiceDueDate?: string;
  invoiceRows: InvoiceRowSave[];
}
export class InvoiceRowSave {
  tripId?: string;
  invoiceRowProducts?: InvoiceRowProductSave[];
}

export class InvoiceRowProductSave {
  invoiceRowProductId?: string;
  productId?: string;
  label?: string;
  quantity?: number;
  vatRate?: number;
  quotedPrice?: number;
  repValue?: number;
  discount?: number;
  transportPrice?: number;
  tgapValue?: number;
  comment?: string;
}

export interface GetTripProductsToInvoiceParameters {
  productId?: string;
  customerId?: string;
  buildingSiteId?: string;
  tripTypeId?: string;
  startDate?: string;
  endDate?: string;
}

export interface GetTripProductsToInvoice {
  customerId: string;
  customer: Customer;
  tripProducts: TripProductToInvoice[];
}

export interface TripProductToInvoice {
  id: string;
  tripId: string;
  productId: string;
  product: Product;
  orderedQuantity: number;
  deliveredQuantity: number;
  deliveryDate: Date;
  vatRate: number;
  repValue: number;
  tgapValue: number;
  unitPrice: number;
  externalReference: string;
  comment: string;
  customerId: string;
  buildingSiteId: string;
}

export interface GenerateInvoicesParameters {
  invoiceType: InvoiceType;
  tripProducts: string[];
}

export interface ValidateProformaParameters {
  proformas: string[];
}

export interface CancelProformaParameters {
  proformas: string[];
}
