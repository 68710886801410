import { Credentials } from '../components/auth/Login';
import { createInstance } from '../Config';
import { Account, User } from '../models/User';

const instance = createInstance({ isExtranet: false });

export const signin = async (credentials: Credentials): Promise<{ accessToken: string }> => {
  const response = await instance.post('/auth/login', credentials);
  return response.data.content;
};

export const fetchUserData = async (accessToken: string): Promise<User> => {
  const date = new Date().toISOString();
  const response = await instance.get(`me?d=${date}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data.content;
};

export const sendPasswordResetRequest = async (username: string): Promise<void> => {
  await instance.post('core/auth/forgot-password', { username });
};

export const fetchAccount = async (accessToken: string): Promise<Account> => {
  const response = await instance.get('auth/account', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.content;
};

export const resetPassword = async ({
  username,
  password,
  resetToken,
}: {
  username: string;
  password: string;
  resetToken: string;
}): Promise<void> => {
  await instance.post('core/auth/reset-password', { username, password, resetToken });
};

export const postNewPassword = async ({
  accessToken,
  newPassword,
  passwordConfirmation,
}: {
  accessToken: string;
  newPassword: string;
  passwordConfirmation: string;
}): Promise<void> => {
  await instance.post(
    'auth/change-password',
    {
      newPassword,
      passwordConfirmation,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};
