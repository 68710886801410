export default class StringUtils {
  static Capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  static formatFieldsForPost(obj: any): any {
    const formattedObj: any = {};

    for (const key in obj) {
      const value = obj[key];

      if (typeof value === 'string') {
        formattedObj[key] = value === '' ? null : value;
      } else {
        formattedObj[key] = value;
      }
    }

    return formattedObj;
  }

  static currencyFormat(amount: number | undefined, currentCurrency: string) {
    if (amount === undefined) return undefined;
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currentCurrency }).format(amount);
  }

  static currencySymbol(currency: string, locale?: string) {
    const formatter = new Intl.NumberFormat(locale ?? 'fr-FR', {
      style: 'currency',
      currency,
    });

    let symbol;
    formatter.formatToParts(0).forEach(({ type, value }) => {
      if (type === 'currency') {
        symbol = value;
      }
    });

    return symbol;
  }
}
