import axios, { AxiosInstance } from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('REACT_APP_API_URL is not defined');
}

const buildURL = (isExtranet: boolean) => {
  return isExtranet ? process.env.REACT_APP_API_URL + 'extranet/' : process.env.REACT_APP_API_URL;
};

export const createInstance = ({ isExtranet }: { isExtranet: boolean }): AxiosInstance => {
  return axios.create({
    baseURL: buildURL(isExtranet),
    withCredentials: false,
  });
};

export default class Config {
  static getApiExtranetUrl(path = '') {
    return process.env.REACT_APP_API_URL + 'extranet/' + path;
  }

  static getApiUrl() {
    return process.env.REACT_APP_API_URL;
  }

  static getUrl(path: string) {
    if (path.includes('?')) {
      return process.env.REACT_APP_API_URL + 'core/' + path;
    } else {
      return process.env.REACT_APP_API_URL + 'core' + '/' + path;
    }
  }
}
