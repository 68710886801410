import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralAuthorizedVehicle from './GeneralAuthorizedVehicle';
import { GetAuthorizedVehicle } from '../../../models/AuthorizedVehicle';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';

interface DetailAuthorizedVehicleProps {
  id?: string;

  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailAuthorizedVehicle = React.memo((props: DetailAuthorizedVehicleProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
  };

  const { id, mode } = useParams<Params>();

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getAuthorizedVehicle, setGetAuthorizedVehicle] = useState<GetAuthorizedVehicle>();

  function getCurrentAuthorizedVehicle(id: string | undefined) {
    if (id && getMode() === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/authorized-vehicle/detail/';
      const url = Config.getApiExtranetUrl(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre GetAuthorizedVehicle
            setGetAuthorizedVehicle(res.data.content);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else setIsLoading(false);
  }

  useEffect(() => {
    getCurrentAuthorizedVehicle(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralAuthorizedVehicle
              mode={getMode()}
              authorizedVehicleData={getAuthorizedVehicle}
              onCancel={onCanceled}
              onConfirm={onConfirmed}
            />
          )}

          {getMode() !== 'add' && (
            <>
              {currentTab === 'general' && (
                <GeneralAuthorizedVehicle
                  id={id}
                  mode={getMode()}
                  authorizedVehicleData={getAuthorizedVehicle}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/authorizedVehicles');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/authorizedVehicles');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={
            getMode() === 'add'
              ? t('common.newAuthorizedVehicle')
              : t('common.authorizedVehicle') +
                ' ' +
                (!getAuthorizedVehicle
                  ? ''
                  : getAuthorizedVehicle.vehicle?.registrationPlate +
                    (getAuthorizedVehicle.tripType ? '-' + getAuthorizedVehicle.tripType.label : ''))
          }
        >
          {content}
        </PopupContainer>
      ) : (
        <div className='main-container'>
          <Menu selectedPage='authorizedVehicles'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
          <div className='right detail-container'>
            <Head
              title={
                getMode() === 'add'
                  ? t('common.newAuthorizedVehicle')
                  : t('common.authorizedVehicle') +
                    ' ' +
                    (!getAuthorizedVehicle
                      ? ''
                      : getAuthorizedVehicle.vehicle?.registrationPlate +
                        (getAuthorizedVehicle.tripType ? '-' + getAuthorizedVehicle.tripType.label : ''))
              }
              back={true}
              Back={function (): void {
                history('/authorizedVehicles');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

DetailAuthorizedVehicle.displayName = 'DetailAuthorizedVehicle';
export default DetailAuthorizedVehicle;
