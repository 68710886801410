import { createInstance } from '../Config';
import { CustomerTypeProductPrice } from '../models/CustomerType';

const instance = createInstance({ isExtranet: true });

export const fetchCustomerTypeProductPrices = async ({
  accessToken,
  orgid,
  customerTypeId,
}: {
  accessToken: string;
  orgid: string;
  customerTypeId: string;
}): Promise<CustomerTypeProductPrice[]> => {
  const response = await instance.post(
    `${orgid}/customer-type-product-price/get`,
    {
      customerTypeId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  return response.data.content.items;
};
