import { createInstance } from '../Config';
import { GetQuotation, GetQuotationHistory, GetQuotationParameters, QuotationStatus } from '../models/Quotation';

const instance = createInstance({ isExtranet: true });

export const fetchQuotations = async ({
  accessToken,
  orgid,
  page,
  limit,
  sortBy,
  orderBy,
  filters,
  quotationType,
}: {
  accessToken: string;
  orgid: string;
  page: number;
  limit: number;
  sortBy: string;
  orderBy: string;
  filters: GetQuotationParameters;
  quotationType: 'Quotation' | 'Contract';
}): Promise<{ items: GetQuotation[]; total: number }> => {
  const response = await instance.post(
    `${orgid}/quotation/get`,
    {
      ...filters,
      quotationType,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        page,
        limit,
        sortBy,
        orderBy,
      },
    },
  );
  return { items: response.data.content.items, total: response.data.content.total };
};

export const fetchQuotation = async ({
  accessToken,
  orgid,
  quotationId,
}: {
  accessToken: string;
  orgid: string;
  quotationId: string;
}): Promise<GetQuotation> => {
  const response = await instance.get(`${orgid}/quotation/detail/${quotationId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.content;
};

export const fetchQuotationHistories = async ({
  accessToken,
  orgid,
  quotationId,
}: {
  accessToken: string;
  orgid: string;
  quotationId: string;
}): Promise<GetQuotationHistory[]> => {
  const response = await instance.get(`${orgid}/quotation/${quotationId}/quotation-histories/get`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.content.items;
};

export const convertQuotationToOrder = async ({
  accessToken,
  orgid,
  quotationId,
  customerOrderNumber,
}: {
  accessToken: string;
  orgid: string;
  quotationId: string;
  customerOrderNumber: string;
}): Promise<any> => {
  const response = await instance.post(
    `${orgid}/quotation/convert-to-order/${quotationId}`,
    {
      customerOrderNumber: customerOrderNumber || null,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};

export const changeQuotationStatus = async ({
  accessToken,
  orgid,
  quotationId,
  status,
}: {
  accessToken: string;
  orgid: string;
  quotationId: string;
  status: QuotationStatus;
}): Promise<any> => {
  const response = await instance.post(
    `${orgid}/quotation/${quotationId}/change-status`,
    {
      status,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};

export const generateQuotationPdf = async ({
  accessToken,
  orgid,
  quotationId,
}: {
  accessToken: string;
  orgid: string;
  quotationId: string;
}): Promise<void> => {
  await instance.post(
    `${orgid}/quotation/${quotationId}/generate-pdf`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};
