import React from 'react';
import './inputLabel.scss';
import { useTranslation } from 'react-i18next';
import { Input } from './Input';

interface InputLabelProps {
  type?: 'text' | 'email' | 'number' | 'password' | 'date' | 'time' | 'datetime-local' | 'month' | 'week';
  size?: 'wide' | 'fit';
  placeholder?: string;
  number?: {
    min: number | null;
    max: number | null;
    step: number | null;
  };
  label?: string;
  hoverDescription?: string;
  toolTipMode?: 'none' | 'info' | 'question' | 'warning';
  prefix?: string;
  suffix?: string;
  onChange?: (newValue: string | number) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error: string;
  value: string | number | undefined;
  disabled?: boolean;
  inRow?: boolean;

  canEditField: boolean;
  parentHasEditMode: boolean;
  editingParent: boolean;
  onClickEdit?: () => void;
  labelValue: string;
}

export const InputLabel = (props: InputLabelProps) => {
  const { t } = useTranslation();
  const [editingField, setEditingField] = React.useState(false);

  return (
    <>
      {!editingField && (
        <div>
          <div
            className={
              'column-align-right' +
              (props.canEditField && (props.editingParent || !props.parentHasEditMode) ? ' clickable' : '') +
              (props.editingParent && props.canEditField ? ' input-like' : '')
            }
            title={props.canEditField ? t('common.clickToEdit') : undefined}
            onClick={() => {
              if (props.canEditField && (props.editingParent || !props.parentHasEditMode)) {
                setEditingField(true);
                if (props.onClickEdit) props.onClickEdit();
              }
            }}
          >
            <div>
              <strong className='body1'>{props.labelValue}</strong>
            </div>
          </div>
        </div>
      )}
      {editingField && (props.editingParent || !props.parentHasEditMode) && (
        <div className='column-align-right'>
          <Input
            {...props}
            onChange={function (newValue: string | number): void {
              if (props.canEditField && props.onChange) {
                props.onChange(newValue);
              }
            }}
            error={''}
            type='number'
            number={{ min: 0, max: 99999999, step: 1 }}
            value={props.value}
            suffix={props.suffix}
            disabled={!props.canEditField}
            inRow={props.inRow}
            autoFocus={true}
            onBlur={(event) => {
              setEditingField(false);
              if (props.onBlur) props.onBlur(event);
            }}
          />
        </div>
      )}
    </>
  );
};
