import { Navigate, Outlet } from 'react-router-dom';
import useAuthGuard from '../../hooks/useAuthGuard';

const PrivateRoutes = () => {
  const { isAuthenticated } = useAuthGuard();

  return isAuthenticated ? <Outlet /> : <Navigate to='/' />;
};

export default PrivateRoutes;
