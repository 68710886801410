import { createInstance } from '../Config';
import { GetOrder } from '../models/Order';

const instance = createInstance({ isExtranet: true });

export const fetchOrdersByQuotationId = async ({
  accessToken,
  orgid,
  quotationId,
}: {
  accessToken: string;
  orgid: string;
  quotationId: string;
}): Promise<GetOrder[]> => {
  const response = await instance.get(`${orgid}/order/get-by-quotation-id/${quotationId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.content.items;
};
