const tableStyles = {
  responsiveWrapper: {
    style: {
      borderRadius: '0 0 8px 8px !important',
    },
  },
  rows: {
    style: {
      minHeight: '72px', // override the row height
      borderBottomWidth: '0px !important',
      // borderBottomColor: '#EFEFEF !important',
    },
    denseStyle: {
      minHeight: '50px',
      borderBottomWidth: '1px !important',
      borderBottomColor: '#E4E4E7 !important',
    },
  },
  headRow: {
    style: {
      minHeight: '72px', // override the row height
      borderBottomWidth: '0px !important',
      // borderBottomColor: '#EFEFEF !important',
    },
    denseStyle: {
      minHeight: '50px',
      borderBottomWidth: '1px !important',
      borderBottomColor: '#E4E4E7 !important',
    },
  },
  headCells: {
    style: {
      color: '#90A0B7',
      backgroundColor: '#FDFDFF',
      paddingLeft: '15px', // override the cell padding for head cells
      paddingRight: '5px',
    },
  },
  cells: {
    style: {
      color: '#90A0B7',
      paddingLeft: '15px', // override the cell padding for data cells
      paddingRight: '5px',
    },
  },
  pagination: {
    style: {
      marginTop: '20px',
      zIndex: '0',
      border: 'none',
      borderRadius: '8px',
    },
  },
};

export const parentTableStyles = {
  table: {
    style: {
      borderTop: '1px solid #EFEFEF',
      borderLeft: '1px solid #EFEFEF',
      borderRight: '1px solid #EFEFEF',
      borderbottom: '0px',
      borderRadius: '6px 6px 0 0 !important',
    },
  },
  rows: {
    style: {
      // minHeight: '72px', // override the row height
      borderTop: '0px',
      gap: '24px',
      height: '56px',
      paddingLeft: '24px',
      paddingRight: '72px',
      '&:not(:last-of-type)': {
        borderBottomWidth: '0px',
      },
    },
  },

  expanderCell: {
    style: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: '0px',
      margin: '0px',
      '&:hover:not(:disabled)': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: 'transparent',
      },
      flex: '0 0 0px',
      minWidth: 'inherit',
    },
  },

  expanderButton: {
    style: {
      backgroundColor: 'transparent',
      borderRadius: '2px',
      transition: '0.25s',
      height: '24px',
      width: '24px',
      padding: '0px',
      margin: '0px',
      '&:hover:not(:disabled)': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: 'transparent',
      },
      svg: {
        height: '24px',
        width: '24px',
      },
    },
  },
  headCells: {
    style: {
      borderBottomWidth: '0px',
    },
  },
  headRow: {
    style: {
      height: '24px',
      minHeight: 'inherit',
      justifyContent: 'space-between',
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingRight: '72px',
      paddingLeft: '72px',
      borderRadius: '6px 6px 0 0 !important',
      borderBottom: '1px solid #EFEFEF',
    },
  },
};

export const childTableStyles = {
  table: {
    style: {
      border: '0px',
    },
  },
  rows: {
    style: {
      minHeight: '72px', // override the row height
      border: '0px',
      height: '32px',
      paddingTop: '8px',
      paddingRight: '72px',
      paddingLeft: '72px',
      paddingBottom: '8px',
      '&:not(:last-of-type)': {
        borderBottomWidth: '0px',
      },
    },
  },
  headCells: {
    style: {
      borderBottomWidth: '0px',
    },
  },
  headRow: {
    style: {
      height: '32px',
      justifyContent: 'space-between',
      paddingTop: '8px',
      paddingRight: '73px',
      paddingLeft: '73px',
      border: '0px',
    },
  },
};

export default tableStyles;
