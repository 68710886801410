import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import { useNavigate, useParams } from 'react-router-dom';
import ListCustomersToInvoice from '../../lists/Invoices/ListCustomersToInvoice';
import SubMenuInvoicing from './SubMenuInvoicing';
import ListInvoices from '../../lists/Invoices/ListInvoices';

const Invoicing = React.memo(() => {
  const { t } = useTranslation();

  const history = useNavigate();

  type Params = {
    tab?: string;
    filter?: string;
  };
  const { tab, filter } = useParams<Params>();

  const [currentTab, setCurrentTab] = useState(tab ?? 'toInvoice');

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
    history(`/invoicing/${mode}`);
  }

  return (
    <div className='main-container'>
      <Menu selectedPage='invoicing' />
      <div className='right detail-container'>
        <Head title={t('common.invoicing')} back={false} />
        <SubMenuInvoicing tab={currentTab} updateTab={updateCurrentTab} />
        <>
          {currentTab === 'toInvoice' && <ListCustomersToInvoice onSuccess={() => setCurrentTab('invoices')} />}
          {currentTab === 'invoices' && <ListInvoices defaultStatus={filter} />}
        </>
      </div>
    </div>
  );
});

Invoicing.displayName = 'Invoicing';
export default Invoicing;
