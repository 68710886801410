import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Button } from '../../../stories/dune/atoms/Button';
import '../../forms/SubMenu.scss';

interface SubMenuInvoicingProps {
  tab: string;
  updateTab: any;
}

const SubMenuInvoicing = React.memo((props: SubMenuInvoicingProps) => {
  const { t } = useTranslation();

  return (
    <div className='submenu'>
      <FormRow>
        <Button
          label={t('common.toInvoice')}
          size='wide'
          style={props.tab === 'toInvoice' ? 'primary' : 'white'}
          onClick={() => props.updateTab('toInvoice')}
        />
        <Button
          label={t('common.invoices')}
          size='wide'
          style={props.tab === 'invoices' ? 'primary' : 'white'}
          onClick={() => props.updateTab('invoices')}
        />
      </FormRow>
    </div>
  );
});

SubMenuInvoicing.displayName = 'SubMenuInvoicing';
export default SubMenuInvoicing;
