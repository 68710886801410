import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import ListInvoices from '../../lists/Invoices/ListInvoices';

const Invoices = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div className='main-container'>
      <Menu selectedPage='invoices' />
      <div className='right detail-container'>
        <Head title={t('common.invoices')} back={false} />
        <ListInvoices />
      </div>
    </div>
  );
});

Invoices.displayName = 'Invoices';
export default Invoices;
